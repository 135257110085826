<template>
  <div class="logs">
    <div class="card-title">
      <h5 class="mb-0 font-weight-bold">MFA (Multi-factor authentication)</h5>
    </div>

    <b-table
       show-empty
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
      :items-provider="mfaSecurity"
    >
       <template #cell(enabled)="items">
          <div class="d-flex align-items-center">
            <div class="d-inline-block">
              <toggle-switch
                :values="items.item.enabled === true"
                @active="activate"
                :id="items.item.id"
                :comment="'MFA'"
              ></toggle-switch>
            </div>
          </div>
        </template>
    </b-table>
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1"> </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
         <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
            class="my-0 ml-auto float-right"
          ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>
import moment from "moment";
import ToggeComp from "../../components/ToggeComp.vue";
export default {
  name: "MFA",
  components: {
    "toggle-switch": ToggeComp,
  },
  data() {
    return {
      checked: true,
      unchecked: false,
      switchchecked: true,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      fields: [
        { key: "id", sortable: true },
        { key: "user_email", sortable: true },
        {
          key: "created_at",
          label: "Created On", sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              return moment(item.created_at).format("YYYY-MM-DD");
            } else {
            }
          },
        },
        { key: "enabled" },
      ],
      items: [],
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      filter: "",
      totalItems: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },

  async mounted() {
    await this.mfaSecurity(this.currentPage, (event = ""));
  },
  methods: {
    async mfaSecurity(currentPage, event = "") {
      this.$axios
        .get(this.base_url + "/mfa-security?page=" + this.currentPage, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          if(this.currentPage==1){
          this.items=[]; 
        }
        this.items = [...this.items,...response.data.results];
        this.current_page = response.data.current_page;
        this.pages = response.data.total_pages;
        this.totalItems = response.data.result_count;
        });
    },
    activate(arg) {
       
      var payload = {
        enabled: arg.check,
      };
      var id= arg.id;
      const config = {
        headers: { Authorization: this.token },
      };
      this.$axios
        .put(
          this.base_url +
            `/mfa-security/`+id+"/",
          payload,
          config
        )
        .then((response) => {
          if (response.status_code == 400) {
            this.Swal.fire("Oops!", error.response.data.message, "error");
          } else {
            this.$swal(
              `${arg.check ? "Activated" : "Inactivated"}`,
              `Employer is now ${
                arg.check ? "activated" : "inactivated"
              } successfully 🙂`,
              "success"
            );
          }
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data.message, "error");
        });
    },
    clearSearch() {
      this.filter = "";
      this.mfaSecurity(this.currentPage, (event = ""));
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.mfaSecurity(value, (event = ""));
      },
    },
  },
};
</script>

<style scoped></style>
