<template>
  <div class="whatsnew">
    <b-card no-body class="custom-card">
      <template #header>
        <div class="d-flex align-items-center">
          <i class="material-icons-outlined"> work_outline </i>
          <span class="ml-2 font-weight-bold">Recent Jobs</span>
        </div>
      </template>
      <div class="jobs-list">
        <JobsList />
      </div>
    </b-card>
  </div>
</template>

<script>
import JobsList from "./JobsList"

export default {
  name: "RecentJobs",
  components: {
    JobsList,
  },
  data() {
    return {}
  },
}
</script>

<style scoped></style>
