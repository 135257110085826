<template>
  <div class="create-user">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <p class="error" v-if="error.message">
      {{ error.message }}
    </p>
    <p class="error" v-if="error.email">
      {{ error.email }}
    </p>
    <FormulateForm class="text-left" @submit="submitHandler" v-model="formData">
      <label class="typo__label">Select Employer</label>
      <multiselect
        v-model="value"
        :options="options"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Select Multiple Employer"
        label="name"
        track-by="name"
        :preselect-first="true"
      >
      </multiselect>
      <b-row class="d-flex justify-content-end px-2 mt-4">
        <b-button variant="danger" @click="clearFormData"> Clear </b-button>
        <FormulateInput
          type="submit"
          class="btn-theme-custom btn-bold text-uppercase"
          label="Submit"
        />
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import axios from "axios"
import Multiselect from "vue-multiselect"

export default {
  name: "CreateUser",
  components: {
    Multiselect,
  },
  // props: ["createdPartnerId"],
  data() {
    return {
      loader: false,
      employers: [],
      // id: this.createdPartnerId,
      input: null,
      id: this.$route.params.id,
      isHidden: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      usertypeoptions: [],
      newUserTypes: [],
      roleoptions: [],
      newRoleOptions: [],
      partners: [],
      formData: {},
      error: {
        message: "",
        email: "",
      },
      limit: 20,
      page: 1,
      totalPage: 3,
      search: "",
      countries: ["dsd", "ds"],
      value: null,
      options: [],
    }
  },

  methods: {
    activate(arg) {
      this.isHidden = arg.isHidden
    },
    clearFormData() {
      this.formData.userData = ""
    },

    getEmployers(page) {
      var employer = this.input

      this.$axios
        .get(this.base_url + "/filter-employers?ats=&partner=" + this.id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.options = response.data.result.map((data) => ({
            language: data.id,
            name: data.name,
          }))
        })
    },
    async submitHandler(data) {
      this.loader = true
      var employer = this.value.map((data) => data.language)
      var payload = {
        employer: employer,
        id: this.id,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      axios
        .put(this.base_url + "/partners/employers/", payload, config)
        .then((response) => {
          this.loader = false
          if (response.data.status == 400) {
            this.error.message = response.data.message
            this.error.email = response.email[0]
          }
          if (response.status == 200) {
            this.$router.push("/partner-users" + this.id)
          }
        })
        .catch((error) => {
          this.loader = false
          this.error.message = error.response.data.message
          this.error.email = error.response.data[0].email
        })
        .finally(() => (this.loading = false))
    },
  },
  created: function () {
    this.getEmployers(this.page)
  },
  computed: {},
}
</script>

<style scoped>
.error {
  color: red;
}
select[multiple] {
  height: 150px;
  overflow: auto;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.delete-btn {
  margin-bottom: -20px;
}
.spinn-loader {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
</style>
