<template>
  <div class="funnlechart-view">
    <!-- This Component for AmChart Library -->

    <AmChartLibrary :type="type" v-if="type == 'Compaign'" />
    <AmChartLibrary v-else />
    <b-row>
      <b-col>
        <div class="funnel-filter mt-3 mr-4">
          <b-form inline class="justify-content-end">
            <b-form-select
              class="ml-2"
              v-model="filterselected"
              :options="filteroptions"
              size="sm"
            ></b-form-select>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="8" offset-xl="2">
        <div id="funnlChart"></div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AmChartLibrary from "../../components/AmChartLibrary.vue"

export default {
  name: "FunnelChart",
  components: {
    AmChartLibrary,
  },
  props: ["type"],
  data() {
    return {
      filterselected: null,
      filteroptions: [
        { value: null, text: "Monthly" },
        { value: "aa", text: "Yearly" },
        { value: "ab", text: "Weekly" },
      ],
    }
  },
}
</script>

<style scoped>
#funnlChart {
  height: 500px;
  width: 100%;
}
</style>
