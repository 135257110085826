<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Edit Screening</h4>
      </div>
    </div>
    <EditScreening />
  </div>
</template>

<script>
import EditScreening from "./EditScreening"
export default {
  name: "EditScreeningQuestion",

  components: {
    EditScreening,
  },
}
</script>

<style scoped></style>
