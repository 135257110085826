<template>
  <div class="create-screening create-question">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-card class="custom-card">
      <template #header>
        <b-button to="/screening-questions" size="sm" variant="secondary">
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> chevron_left </span>
            <span>Back</span>
          </span>
        </b-button>
      </template>
      <FormulateForm class="text-left" @submit="submitHandler" v-model="form">
        <b-row>
          <b-col lg="3" xl="2">
            <label>Employer</label>
            <span class="d-inline-block ml-1 mt-2 text-danger">*</span>
          </b-col>
          <b-col lg="5" xl="4">
            <b-form-group id="qname">
              <!-- <FormulateInput
                id="qname"
                type="text"
                name="qname"
                validation="required"
                validation-name="Name"
              /> -->
              <v-select
                :options="paginated"
                :filterable="false"
                :reduce="(option) => option.value"
                label="label"
                @open="onOpen"
                @close="onClose"
                @search="(query) => (search = query)"
                class="mb-3"
                placeholder="Select Employer"
                id="emp"
                v-model="input"
              >
                <template #list-footer>
                  <li v-show="hasNextPage" ref="load" class="loader"></li>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3" xl="2">
            <label>Description</label>
            <span class="d-inline-block ml-1 mt-2 text-danger">*</span>
          </b-col>
          <b-col lg="9" xl="10">
            <b-form-group id="qdescription">
              <ckeditor
                :height="100"
                :editor="editor"
                v-model="queestionDescription"
              ></ckeditor>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="row">
          <div class="col-12 text-right">
            <FormulateInput
              type="submit"
              class="btn-theme-custom btn-bold text-uppercase"
              label="Ok"
            />
          </div>
        </div>
      </FormulateForm>
      <hr />
      <FormulateForm
        class="text-left"
        @submit="categoryDataFormSubmit"
        v-model="categoryDataForm"
      >
        <b-row class="mb-2">
          <b-col>
            <h5 class="font-weight-bold">Categories</h5>
          </b-col>
          <b-col> </b-col>
        </b-row>
        <FormulateInput
          type="group"
          name="categoryData"
          :repeatable="true"
          add-label="+ Add More"
          validation="required"
        >
          <b-row>
            <b-col lg="4" xl="3">
              <b-form-group id="qCatName">
                <label>Name</label>
                <span class="d-inline-block ml-1 mt-2 text-danger">*</span>
                <FormulateInput
                  id="qc"
                  type="text"
                  name="name"
                  validation="required"
                  validation-name="Category name"
                />
              </b-form-group>
            </b-col>
            <b-col lg="5" xl="7">
              <b-form-group id="qCatDescription">
                <label>Description</label>
                <span class="d-inline-block ml-1 mt-2 text-danger">*</span>
                <!-- <ckeditor
                  id="qcdescription"
                  :height="100"
                  :editor="editor"
                  v-model="qCatDescription"
                ></ckeditor> -->
                <FormulateInput
                  type="textarea"
                  name="description"
                  validation="required|max:50,length"
                  validation-name="Category Description"
                />
              </b-form-group>
            </b-col>
            <b-col lg="3" xl="2">
              <b-form-group id="qCatOrdering">
                <label>Ordering</label>
                <span class="d-inline-block ml-1 mt-2 text-danger">*</span>
                <FormulateInput
                  id="qCatOrdering"
                  type="number"
                  name="order"
                  validation="required"
                  validation-name="Order"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </FormulateInput>
        <div class="row">
          <div class="col-12 text-right">
            <FormulateInput
              type="submit"
              class="btn-theme-custom btn-bold text-uppercase"
              label="Ok"
            />
          </div>
        </div>
      </FormulateForm>
      <hr />
      <FormulateForm
        class="text-left"
        @submit="questionsDataFormSubmit"
        v-model="questionsDataForm"
      >
        <b-row class="mb-2">
          <b-col>
            <h5 class="font-weight-bold">Questions</h5>
          </b-col>

          <b-col> </b-col>
        </b-row>
        <FormulateInput
          type="group"
          name="questionsData"
          :repeatable="true"
          add-label="+ Add More"
          validation="required"
        >
          <QuestionsForm
            :isHidden="showChoice"
            :formData="questionsDataForm"
            :survey="optionsCategory"
            @active="activateshowChoice"
          />
        </FormulateInput>
        <div class="row">
          <div class="col-12 text-right">
            <FormulateInput
              type="submit"
              class="btn-theme-custom btn-bold text-uppercase"
              label="Ok"
            />
          </div>
        </div>
      </FormulateForm>
    </b-card>
  </div>
</template>

<script>
import axios from "axios"
import QuestionsForm from "../../components/QuestionsForm"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
export default {
  name: "CreateScreening",

  components: { QuestionsForm },

  data() {
    return {
      radio: false,
      select: false,
      multiple: false,
      loader: false,
      showChoice: false,
      editor: ClassicEditor,
      phoneNumber: null,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      queestionDescription: "",
      qCatDescription: "",
      form: {},
      optionsCategory: [],
      categoryDataForm: {},
      questionsDataForm: {},
      optionsQuestion: [
        { value: "text", label: "Text" },
        { value: "short-text", label: "Short Text" },
        { value: "radio", label: "Radio" },
        { value: "select", label: "select" },
        { value: "select-multiple", label: "select-multiple" },
        { value: "integer", label: "integer" },
        { value: "float", label: "float" },
        { value: "date", label: "date" },
      ],
      optionsRequired: [
        { value: "true", label: "True" },
        { value: "false", label: "False" },
      ],
      error: {
        message: "",
      },
      input: "",
      surveyId: null,
      categoryId: false,
      employers: [],
      observer: null,
      limit: 20,
      page: 1,
      totalPage: 3,
      search: "",
      countries: ["dsd", "ds"],
      choicedata: "",
    }
  },
  methods: {
    activateshowChoice(arg) {
      this.showChoice = arg.isHidden
      if (arg.type == "radio") {
        this.radio = true
      }
      if (arg.type == "select") {
        this.select = true
      }
      if (arg.type == "multiple") {
        this.multiple = true
      }
    },

    async submitHandler(data) {
      this.form.queestionDescription = this.queestionDescription

      this.loader = true
      var payload = {
        name: this.input,
        need_logged_user: false,
        description: this.form.queestionDescription,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      axios
        .post(this.base_url + "/surveys/", payload, config)
        .then((response) => {
          this.surveyId = response.data.id
          this.loader = false
          if (response.data.status == 400) {
            this.error.message = response.data.message
          }
        })
        .catch((error) => {
          this.loader = false
          this.error.message = error.response.data.message
        })
        .finally(() => (this.loading = false))
    },

    async categoryDataFormSubmit(data) {
      this.loader = true
      if (this.surveyId) {
        var payload = this.categoryDataForm.categoryData.map((catData) => ({
          name: catData.name,
          order: catData.order,
          description: catData.description,
          survey: this.surveyId,
        }))
        const config = {
          headers: { Authorization: this.token },
        }
        axios
          .post(this.base_url + "/categories/", payload, config)
          .then((response) => {
            this.loader = false
            this.categoryId = true
            this.getCategories()
            if (response.data.status == 400) {
              this.error.message = response.data.message
            }
          })
          .catch((error) => {
            this.loader = false
          })
          .finally(() => (this.loading = false))
      } else {
        this.$swal("Oops!", "Please create Survey firstly", "error")
        this.loader = false
      }
    },
    async questionsDataFormSubmit(data) {
      this.loader = true
      if (this.surveyId && this.categoryId == true) {
        var radio = "Yes"
        // var select = "Python, PHP, Java";
        // var select_multiple = "Django, Flask, Laravel, Symphony";
        var payload = this.questionsDataForm.questionsData.map((queData) => ({
          text: queData.text,
          order: queData.order,
          required: queData.required,
          type: queData.type,
          choices:
            queData.type == "radio"
              ? queData.choices
              : queData.type == "select"
              ? queData.choices
              : queData.type == "select-multiple"
              ? queData.choices
              : "",
          survey: this.surveyId,
          category: queData.category,
        }))
        const config = {
          headers: { Authorization: this.token },
        }
        axios
          .post(this.base_url + "/questions/", payload, config)
          .then((response) => {
            this.loader = false
            this.$router.push("/screening-questions")
            if (response.data.status == 400) {
              this.error.message = response.data.message
            }
          })
          .catch((error) => {
            this.loader = false
            this.$swal("Oops!", "Please create Survey firstly", "error")
          })
          .finally(() => (this.loading = false))
      } else {
        if (this.surveyId == null && this.categoryId == true) {
          this.$swal("Oops!", "Please create Survey firstly", "error")
        }
        if (this.categoryId == false && this.surveyId) {
          this.$swal("Oops!", "Please create Category firstly", "error")
        }
        if (this.categoryId == false && this.surveyId == null) {
          this.$swal(
            "Oops!",
            "Please create Survey and Category firstly",
            "error"
          )
        }

        this.loader = false
      }
    },
    getCategories() {
      this.$axios
        .get(this.base_url + "/categories?survey=" + this.surveyId, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.optionsCategory = response.data.results.map((category) => ({
            value: category.id,
            label: category.name,
          }))
        })
    },
    activate(event, keyName) {
      this.form_fields[keyName] = event
    },
    setRequired(event, keyName) {
      this.required_fields[keyName] = event
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    async setPage() {
      this.page = this.page + 1
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 20
        this.page += 1
        await this.getEmployers(this.page)
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },

    getEmployers(page) {
      var employer = this.input

      this.$axios
        .get(this.base_url + "/filter-employers?has_surveys=false", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.employers = response.data.result.map((data) => ({
            value: data.name,
            label: data.name,
          }))
        })
    },
  },
  watch: {
    search() {
      this.page = 1
      this.limit = 20
      this.employers = []
      this.getEmployers()
    },
  },
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  created() {
    // this.getCategories();
    this.getEmployers(this.page)
  },
  computed: {
    filtered() {
      return this.employers
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return true
    },
    formDataLength() {
      return this.formData(this.formData, "1")
    },
  },
}
</script>
<style scoped>
.spinn-loader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
.ats-box,
.manual-box {
  background-color: #f5f5f5;
  padding: 16px 40px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.ats-box.active,
.manual-box.active {
  background-color: #007bff;
  border: 1px solid #007bff;
  color: #fff;
}
.error {
  color: red;
}
select[multiple] {
  height: 150px;
  overflow: auto;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.delete-btn {
  margin-bottom: -20px;
}
</style>
