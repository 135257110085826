<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Users</h4>
        <div class="button-action">
          <b-button
            size="sm"
            class="btn-theme"
            v-b-modal.create-user
            @click="createshow = true"
          >
            <span class="d-flex align-items-center">
              <span class="material-icons-outlined"> add </span>
              <span>Add User</span>
            </span>
          </b-button>
        </div>
      </div>
    </div>

    <UsersList />

    <!-- Create New User Modal -->
    <b-modal
      id="create-user"
      v-model="createshow"
      title="Create New User"
      hide-footer
    >
      <CreateUser />
    </b-modal>
  </div>
</template>

<script>
import CreateUser from "../EmployerUsers/CreateUser.vue"
import UsersList from "./UserList"
export default {
  name: "Users",
  components: {
    CreateUser,
    UsersList,
  },
  data() {
    return {
      createshow: false,
    }
  },
}
</script>
