<template>
  <div class="filter-header">
    <b-row>
      <b-col lg="6" xl="6" class="my-1">
                <b-form-group
          label="Employer:"
          label-for="filter-by"
          label-cols-sm="3"
          label-cols-lg="2"
          class="mb-0"
        >
          <b-form-select
            v-model="filterByEmployer"
            :options="optionsFilterByEmployer"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" xl="6" class="my-1">
        <b-form-group
          label="Job:"
          label-for="filter-by"
          label-cols-sm="3"
          label-cols-lg="2"
          class="mb-0"
        >
          <b-form-select
            v-model="filterByJob"
            :options="optionsFilterByJob"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" xl="6" class="my-1">
        <b-form-group
          label="Source:"
          label-for="filter-by"
          label-cols-sm="3"
          label-cols-lg="2"
          class="mb-0"
        >
          <b-form-select
            v-model="filterBySource"
            :options="optionsFilterBySource"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" xl="6" class="my-1">
        <b-form-group
          label="Applied Date:"
          label-for="filter-date"
          label-cols-sm="2"
          label-cols-lg="2"
          class="mb-0"
        >
          <b-input-group>
            <b-form-datepicker
              placeholder="From"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }"
              v-model="fromDate"
            ></b-form-datepicker>
            <b-form-datepicker
              placeholder="To"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }"
              v-model="toDate"
            ></b-form-datepicker>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
      <br/>
      <b-button type="submit" class="btn-theme" size="sm" @click="onSubmit">Apply Filtering</b-button>
      <b-button class="btn-theme-cancel mr-2" size="sm" @click="clearFormData"> Cancel </b-button>
  </div>
</template>

<script>
export default {
  name: "FilterHeader",
  data() {
    return {
      currentPage: 1,
      optionsFilterBySource: [{ value: "", text: "Select Source..." }],
      optionsFilterByEmployer: [{ value: "", text: "Select Employer..." }],
      optionsFilterByJob: [{ value: "", text: "Select Job..." }],
      filterBySourceList: [],
      filterByEmployerList: [],
      filterByJobList: [],
      formData: {},
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      filterBySource: "",
      filterByEmployer: "",
      filterByJob: "",
      fromDate: "",
      toDate: "",
    }
  },
  created() {
    this.getSource()
    this.getEmployer()
    this.getJob()
  },
  methods: {
    clearFormData() {
      this.filterBySource = ""
      this.filterByEmployer = ""
      this.filterByJob = ""
      this.fromDate = ""
      this.toDate = ""
      this.sendFilterByEmployer()
      this.sendFilterByJob()
      this.sendFilterBySource()
      this.sendFilterByFromDate()
      this.sendFilterByToDate()
    },
    onSubmit(event) {
      console.log(this.sendFilterBySource)
      this.sendFilterByEmployer()
      this.sendFilterByJob()
      this.sendFilterBySource()
      this.sendFilterByFromDate()
      this.sendFilterByToDate()
    },
    sendFilterByToDate() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.toDate,
        searchkey: "date_to",
      })
    },
    sendFilterByFromDate() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.fromDate,
        searchkey: "date_from",
      })
    },
    sendFilterBySource() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.filterBySource,
        searchkey: "source",
      })
    },
    sendFilterByEmployer() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.filterByEmployer,
        searchkey: "employer",
      })
    },
    sendFilterByJob() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.filterByJob,
        searchkey: "job",
      })
    },
    async getSource(currentPage, event = "") {
      this.$axios
        .get(this.base_url + "/filter-sources", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.filterBySourceList = response.data.result.map((data) => ({
            value: data.id,
            text: data.name,
          }))

          const values = this.filterBySourceList.map((result) => result.name)
          const filtered = this.filterBySourceList.filter(
            ({ value }, index) => !values.includes(value, index + 1)
          )
          const aa = filtered.map((result) =>
            this.optionsFilterBySource.push({
              value: result.value,
              text: result.text,
            })
          )
        })
    },
    async getEmployer(currentPage, event = "") {
      this.$axios
        .get(this.base_url + "/employers", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.filterByEmployerList = response.data.results.map((data) => ({
            value: data.id,
            text: data.name,
          }))
          const values = this.filterByEmployerList.map((result) => result.name)
          const filtered = this.filterByEmployerList.filter(
            ({ value }, index) => !values.includes(value, index + 1)
          )
          const aa = filtered.map((result) =>
            this.optionsFilterByEmployer.push({
              value: result.value,
              text: result.text,
            })
          )
        })
    },
    async getJob(currentPage, event = "") {
      console.log(this.filterByEmployer)
      console.log(this.optionsFilterBySource.value)
      this.$axios
        .get(this.base_url + "/filter-jobs?employer_id=" + this.filterByEmployer, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.filterByJobList = response.data.result.map((data) => ({
            value: data.id,
            text: data.name,
          }))
          const values = this.filterByJobList.map((result) => result.name)
          const filtered = this.filterByJobList.filter(
            ({ value }, index) => !values.includes(value, index + 1),
          )
          const aa = filtered.map((result) =>
            this.optionsFilterByJob.push({
              value: result.value,
              text: result.text,
            })
          )
        })
    },
  },
}
</script>
