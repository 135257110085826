<template>
  <div class="statistics-card">
    <b-row v-if="role == 1 && employer == ''">
      <b-col cols="12" md="6" lg="3">
        <b-card no-body no-header class="custom-card">
          <div class="d-flex justify-content-between py-4 px-3">
            <div class="flex-1 overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Total Jobs</p>
              <h4 class="mb-0">{{ statsCard.job }}</h4>
            </div>
            <div class="text-primary ms-auto">
              <span class="material-icons-outlined"> work_outline </span>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card no-body no-header class="custom-card">
          <div class="d-flex justify-content-between py-4 px-3">
            <div class="flex-1 overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Total Candidates</p>
              <h4 class="mb-0">{{ statsCard.candidate }}</h4>
            </div>
            <div class="text-primary ms-auto">
              <span class="material-icons-outlined"> people </span>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card no-body no-header class="custom-card">
          <div class="d-flex justify-content-between py-4 px-3">
            <div class="flex-1 overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Total Employers</p>
              <h4 class="mb-0">{{ statsCard.employer }}</h4>
            </div>
            <div class="text-primary ms-auto">
              <span class="material-icons-outlined"> group </span>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card no-body no-header class="custom-card">
          <div class="d-flex justify-content-between py-4 px-3">
            <div class="flex-1 overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Total Revenues</p>
              <h4 class="mb-0">{{ statsCard.revenue }}</h4>
            </div>
            <div class="text-primary ms-auto">
              <span class="material-icons-outlined">
                assignment_turned_in
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="role != 1 && employer != ''">
      <b-col cols="12" md="6" lg="3">
        <b-card no-body no-header class="custom-card">
          <div class="d-flex justify-content-between py-4 px-3">
            <div class="flex-1 overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Total Jobs</p>
              <h4 class="mb-0">{{ statsCard.jobs }}</h4>
            </div>
            <div class="text-primary ms-auto">
              <span class="material-icons-outlined"> work_outline </span>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card no-body no-header class="custom-card">
          <div class="d-flex justify-content-between py-4 px-3">
            <div class="flex-1 overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Total Applicants</p>
              <h4 class="mb-0">{{ statsCard.applicant }}</h4>
            </div>
            <div class="text-primary ms-auto">
              <span class="material-icons-outlined"> people </span>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card no-body no-header class="custom-card">
          <div class="d-flex justify-content-between py-4 px-3">
            <div class="flex-1 overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Total Applied</p>
              <h4 class="mb-0">{{ statsCard.applied }}</h4>
            </div>
            <div class="text-primary ms-auto">
              <span class="material-icons-outlined">
                assignment_turned_in
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-card no-body no-header class="custom-card">
          <div class="d-flex justify-content-between py-4 px-3">
            <div class="flex-1 overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Total Hired</p>
              <h4 class="mb-0">{{ statsCard.hired }}</h4>
            </div>
            <div class="text-primary ms-auto">
              <span class="material-icons-outlined"> emoji_events </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "StatisticsCard",
  components: {},
  props: ["statsCard"],
  data() {
    return {
      statsItems: [],
      role: null,
      employer: "",
    }
  },
  mounted() {
    this.setRole()
  },
  methods: {
    setRole() {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      var role = null
      if (parseData.hasOwnProperty("role")) {
        role = parseData.role
      }
      if (parseData.hasOwnProperty("role_id")) {
        role = parseData.role_id
      }
      var employer = parseData.employer
      if (role == 1 && employer == null) {
        this.role = role
        this.employer = ""
      } else {
        this.role = role
        this.employer = employer
      }
    },
  },
}
</script>

<style scoped>
.border-top {
  border-top: 1px solid #eff2f7 !important;
}
.font-size-11 {
  font-size: 11px !important;
}
.badge-soft-success {
  color: #1cbb8c;
  background-color: rgba(28, 187, 140, 0.18);
}
.text-muted {
  color: #74788d !important;
}
.text-primary {
  color: #5664d2 !important;
}
</style>
