<template>
  <div class="job-applicants p-4">
    <b-modal
      id="addTemplate"
      title="Choose Email Template"
      v-model="addTemplate"
    >
      <div class="form-group mt-3">
        <label>Email Template</label>
        <b-form-select
          v-model="selectedTemplate"
          :options="optionsTemplate"
          placeholder="Choose Email Template"
        ></b-form-select>
      </div>
      <template #modal-footer>
        <div class="button-action text-center w-100">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button class="btn-theme" v-on:click="successAlert">
            Submit
          </b-button>
          <b-button variant="danger" @click="$bvModal.hide('addTemplate')">
            Discard
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
        <b-form-group
          label-for="per-page-select"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="perpage-select"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
        <b-form-group
          label="Search"
          label-for="filter-input"
          label-cols-sm="3"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="ml-auto search-filter"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
      responsive="lg"
      outlined
      hover
      head-variant="light"
    >
      <template #cell(applicant_name)="items">
        <div>{{ items.item.first_name }} {{ items.item.last_name }}</div>
      </template>
      <template #cell(actions)="items">
        <div class="d-flex align-items-center">
          <b-link
            @click="getCandidateDetail(items.item.id)"
            class="mr-2 mt-2 text-warning"
          >
            <span class="material-icons-outlined"> visibility </span>
          </b-link>
          <b-link class="text-info" @click="template(items.item)">
            <span class="material-icons-outlined"> email </span>
          </b-link>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3">
        <b-form-group
          label-for="per-page-select"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="perpage-select"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
          class="my-0 ml-auto float-right"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "JobApplicants",
  components: {},
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      addTemplate: false,
      checked: false,
      switchchecked: true,
      optionsTemplate: [{ value: null, text: "Please select email template" }],
      items: [],
      fields: [
        { key: "id", label: "ID" },
        {
          key: "created_at",
          label: "Applied Date",
          formatter: (value, key, item) => {
            if (value) {
              return moment(item.created_at).format("YYYY-MM-DD")
            } else {
              return
            }
          },
        },
        { key: "applicant_name", label: "Applicant Name" },
        { key: "source", label: "Source" },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: "",
      filter: null,
      filterOn: [],
      totalItems: null,
    }
  },

  mounted() {
    this.getApplicants()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    successAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to send this email template",
        type: "info",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes Send it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal(
            "Success",
            "You successfully delete this template",
            "success"
          )
        } else {
          this.$swal("Cancelled", "Your template is still intact", "info")
        }
      })
    },
    getApplicants() {
      this.$axios
        .get(this.base_url + "/candidates?job=" + this.$route.params.id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          if (this.filter == "") {
            if (this.currentPage == 1) {
              this.items = []
            }
            this.items = [...this.items, ...response.data.results]
          } else {
            if (this.currentPage == 1) {
              this.items = []
            }
            this.items = [...this.items, ...response.data.results]
          }
          this.current_page = response.data.current_page
          this.pages = response.data.total_pages
          this.totalItems = response.data.result_count
        })
    },
    async getEmailTemplate(id) {
      this.$axios
        .get(this.base_url + "/filter-templates?employer=" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.optionsTemplate = response.data.result.map((data) => ({
            value: data.id,
            text: data.template_key,
          }))
        })
    },
    getCandidateDetail(id) {
      this.$router.push("/candidates/" + id)
    },
    template(candidate) {
      this.addTemplate = true
      this.candidateId = candidate.id
      this.getEmailTemplate(candidate.employer)
    },
  },
}
</script>

<style scoped></style>
