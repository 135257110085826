<template>
  <div class="page-maincontent">
    <UsersList />
  </div>
</template>

<script>
import UsersList from "./EmployerUsersList.vue"
export default {
  name: "EmployerUsers",

  components: {
    UsersList,
  },
}
</script>

<style scoped></style>
