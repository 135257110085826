<template>
   <div class="recent-campaigns">   
      <b-card class="custom-card">
      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-form-group
            label="Search"
            label-for="filter-input"
            label-cols-sm="3"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="ml-auto search-filter"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="text"
                placeholder="Type to Search"
                @keyup="getData(currentPage, $event.target.value)"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="clearSearch(1), (filter = '')"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
         show-empty
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
      :items-provider="getData"
      >
        <template #cell(actions)="items">
          <div class="d-flex align-items-center">
            <b-link
              @click="getCampaignDetail(items.item.id)"
              class="mr-2 mt-2 text-warning"
            >
              <span class="material-icons-outlined"> visibility </span>
            </b-link>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
         <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
            class="my-0 ml-auto float-right"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div> 
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>

export default {

  name: 'RecentCampaigns',

  components:{},
  
  data() {
    return {
      CampaignFilter:null,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      items: [],
      fields: [
        { key: 'id', label: 'ID',sortable: true },
        { key: 'campaign_id', label: 'Campaign Id',sortable: true },
        { key: 'campaign_name', label: 'Campaign Name',sortable: true },
        { key: 'status', label: 'Status',sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
       totalRows: 1,
      pages: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      sortBy: "",
      filter: "",
      totalRecords: null,
      totalItems: null,
    }
  },
  mounted() {
    this.getData(this.currentPage, (event = ""));
    
  },
  methods: {
 async getData(currentPage, event = "") {
      this.$axios
        .get(
          this.base_url +
            "/campaigns?page="+this.currentPage +
              "&keyword_search=" +
              this.filter,
          {headers: { Authorization: this.token }}
        )
        .then((response) => {
         if(this.filter==""){
          if(this.currentPage==1){
               this.items=[]; 
              }
            this.items = [...this.items,...response.data.results];
          }
          else{
           if(this.currentPage==1){
               this.items=[]; 
              }
              this.items = [...this.items,...response.data.results];
          }
          this.currentPage = response.data.current_page;
          this.pages = response.data.total_pages;
          this.totalRecords = response.data.result_count;
          this.totalItems = response.data.result_count;
          const _this = this;
            _this.$emit("totalRecord", { totalRecords: this.totalRecords });
        });
        
    },
    getCampaignDetail(id) {
      this.$router.push("/campaign/" + id);
    },
    activate(arg) {
      var payload = {
        id: arg.id,
      };
      const config = {
        headers: { Authorization: this.token },
      };
      this.$axios
        .post(
          this.base_url +
            `/employers/${arg.check ? "activate" : "inactivate"}/`,
          payload,
          config
        )
        .then((response) => {
          if (response.status_code == 400) {
            this.Swal.fire("Oops!", error.response.data.message, "error");
          } else {
            this.$swal(
              `${arg.check ? "Activated" : "Inactivated"}`,
              `Employer is now ${
                arg.check ? "activated" : "inactivated"
              } successfully 🙂`,
              "success"
            );
          }
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data.message, "error");
          window.location.reload();
        });
    },
    clearSearch() {
      this.filter = "";
      this.getData(this.currentPage, (event = ""));
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getData(value, (event = ""));
      },
    },
  },
}
</script>

<style scoped>
 .available{
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100px;
}
.available{
    background-color: #17a2b8;
}
.notavailable{
    background-color: #ad8e01;
}

</style>
