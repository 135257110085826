<template>
  <div class="update-role">
    <b-alert show dismissible>
      <div class="d-flex">
        <span class="material-icons-outlined d-inline-block mr-3"> info </span>
        <span classs="d-inline-block">
          Please note that reducing a user role rank, that user will lose all
          privilege's that was assigned to the previous role.
        </span>
      </div>
    </b-alert>
    <div class="select-role">
      <b-form>
        <b-form-group label="Select a user role" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="flavour-2a"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpdateRole",
  components: {},
  data() {
    return {
      selected: [], // Must be an array reference!
      options: [
        { text: "Administrator", value: "administrator" },
        { text: "Manager", value: "manager" },
        { text: "User", value: "Uuser" },
      ],
    }
  },
}
</script>

<style scoped>
.custom-control {
  margin: 15px 0;
}
</style>
