<template>
  <div class="page-maincontent">
    <UsersDetails
      v-if="(role == 1 && employer == '') || (role == 1 && partner == null)"
    />
    <PartnerEmployerList
      v-if="(role == 1 && employer == '') || (role == 1 && partner == null)"
    />
    <UsersList />
  </div>
</template>

<script>
import UsersList from "./PartnerUsersList.vue"
import PartnerEmployerList from "./PartnerEmployerList.vue"
import UsersDetails from "./PartnerUsersDetails.vue"
export default {
  name: "PartnerUsers",

  components: {
    UsersList,
    PartnerEmployerList,
    UsersDetails,
  },
  data() {
    return {
      role: null,
      employer: "",
      partner: null,
    }
  },
  mounted() {
    this.setRoute()
  },
  methods: {
    setRoute() {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      var role = null
      if (parseData.hasOwnProperty("role")) {
        role = parseData.role
      }
      if (parseData.hasOwnProperty("role_id")) {
        role = parseData.role_id
      }
      var employer = parseData.employer
      if (role == 1 && employer == null) {
        this.role = role
        this.employer = ""
      } else {
        this.role = role
        this.employer = employer
        this.partner = parseData.partner
      }
    },
  },
}
</script>

<style scoped></style>
