<template>
  <div class="communication">
    <b-table
      :items="items"
      :fields="fields"
      stacked="md"
      :per-page="perPage"
      show-empty
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
    >
      <template #cell(actions)="row">
        <b-link
          to=""
          @click="info(row.item, row.index, $event.target)"
          class="mr-2 text-success font-weight-600"
        >
          Delivered
        </b-link>
      </template>
    </b-table>
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
        <b-form-group
          label-for="per-page-select"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="perpage-select"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
        <b-pagination-nav
          v-model="currentPage"
          :number-of-pages="pages"
          :per-page="perPage"
          base-url="#"
          class="my-0 ml-auto float-right"
        ></b-pagination-nav>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import TextEditor from '../../components/TextEditor.vue'
import moment from "moment"
export default {
  name: "Communication",

  components: {
    // TextEditor
  },

  data() {
    return {
      attachfile: null,
      JobFilter: null,
      id: this.$route.params.id,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      JobListFilter: [
        { value: null, text: "Please select template" },
        { value: "a", text: "Project Manager" },
        { value: "b", text: "Full Stack Developer" },
        { value: "c", text: "UI Designer" },
        { value: "d", text: "iOS Designer", disabled: true },
        { value: "e", text: "Android Developer" },
        { value: "f", text: "Dot Net Developer" },
        { value: "g", text: "Business Analyst" },
      ],
      items: [],
      fields: [
        { key: "id", label: "ID" },
        {
          key: "created_at",
          label: "Date/Time",
          formatter: (value, key, item) => {
            if (value) {
              return moment(item.created_at).format("YYYY-MM-DD hh:mm")
            } else {
            }
          },
        },
        { key: "email_template", label: "Email Template Name" },
        { key: "actions", label: "Actions" },
      ],
      totalRows: null,
      pages: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      sortBy: "",
      filter: "",
      filterOn: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  mounted() {
    // Set the initial number of items

    this.getJobList(this.currentPage, (event = ""))
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering

      this.currentPage = this.$route.query.page
    },

    getJobList(currentPage, event = "") {
      this.$axios
        .get(this.base_url + "/communications?candidate=" + this.id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.items = response.data.results
          this.current_page = response.data.current_page
          this.pages = response.data.total_pages
          this.totalRows = response.data.result_count
          this.TotalPages = response.data.total_pages
        })
    },
    clearSearch() {
      this.filter = ""
      this.getJobList(this.currentPage, (event = ""))
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getJobList(value, (event = ""))
      },
    },
  },
}
</script>

<style scoped>
.bg-light-blue {
  background-color: #f6fbff;
}
.file-btn {
  color: #6e6e6e;
  background-color: #fff;
  padding: 0.375rem 0.75rem;
  border: 1px solid #707070;
  border-radius: 4px;
  font-weight: 500;
}
.file-wrapper {
  border: none;
}
</style>
