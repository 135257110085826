<template>
  <div class="page-maincontent">
    <PageHeader />

    <StatisticsCard :statsCard="dashboardData.statsCard" />
    <Campaign />

    <RecentJobs />
  </div>
</template>

<script>
import PageHeader from "./Header"
import StatisticsCard from "./StatsCard"
import Campaign from "./Campaign"
import RecentJobs from "./RecentJobs"

export default {
  name: "EmployerDashboard",
  components: {
    PageHeader,
    StatisticsCard,
    Campaign,
    RecentJobs,
  },
  data() {
    return {
      dashboardData: {
        statsCard: [],
      },
      token: "Bearer " + localStorage.getItem("jwt"),
      base_url: process.env.VUE_APP_BASE_URL,
    }
  },
  mounted() {
    this.getDashboardData()
  },
  methods: {
    getDashboardData() {
      this.$axios
        .get(this.base_url + "/dashboard", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.dashboardData.statsCard = response.data.result[0].cards
        })
    },
  },
}
</script>

<style scoped></style>
