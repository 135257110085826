<template>
  <div class="auth-body-bg">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-container fluid class="p-lg-0">
      <b-row class="m-lg-0">
        <b-col lg="6" xl="4">
          <div
            class="authentication-page-content p-md-4 d-lg-flex align-items-lg-center min-vh-100"
          >
            <div class="w-100">
              <b-row class="justify-content-center">
                <b-col lg="12" xl="9">
                  <div class="text-left">
                    <div class="text-left mb-3">
                      <router-link to="#" class="">
                        <img
                          src="../../assets/images/logo.png"
                          alt=""
                          height="48"
                          class="auth-logo"
                        />
                      </router-link>
                    </div>

                    <h4 class="font-weight-700 mt-4">Reset Password</h4>
                    <p class="text-muted">Reset your password to hoarde.</p>
                  </div>

                  <FormulateForm
                    class="mt-3 mb-3 mt-lg-5 mb-lg-5 text-left"
                    @submit="submitHandler"
                    v-model="formData"
                  >
                    <p class="error" v-if="error.message">
                      {{ error.message }}
                    </p>
                    <FormulateInput
                      type="password"
                      class="mb-3"
                      name="password"
                      validation="required"
                      placeholder="New Password"
                      label="New Password"
                      :validation-messages="{
                        required: 'New Password field is required',
                      }"
                    />
                    <FormulateInput
                      type="password"
                      class="mb-3"
                      name="password_confirm"
                      validation="required|confirm"
                      validation-name="Confirmation"
                      placeholder="Confirm Password"
                      label="Confirm Password"
                      :validation-messages="{
                        required: 'Confirm Password field is required',
                      }"
                    />

                    <b-row class="d-flex justify-content-end px-2">
                      <b-button variant="danger"> Clear </b-button>
                      <FormulateInput
                        type="submit"
                        class="btn-theme-custom btn-bold text-uppercase"
                        label="Submit"
                      />
                    </b-row>
                  </FormulateForm>

                  <div class="text-left mt-3 font-weight-600">
                    Already have account?
                    <router-link to="/">Login</router-link>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col lg="6" xl="8">
          <div class="authentication-bg">
            <div class="bg-overlay"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  components: {},
  data() {
    return {
      loader: false,
      base_url: process.env.VUE_APP_BASE_URL,
      formData: {},
      id: this.$route.params.id,
      error: {
        message: "",
      },
    }
  },
  methods: {
    removeValidationMessage() {
      this.error.message = ""
    },
    async submitHandler(data) {
      this.loader = true
      var employer = this.input

      var payload = {
        password: data.password,
        repeat_password: data.password_confirm,
      }
      this.$axios
        .put(this.base_url + "/reset-password/" + this.id + "/", payload)
        .then((response) => {
          this.loader = false
          if (response.data.status == 400) {
            this.error.message = response.data.message
          }
          if (response.status == 201) {
            this.$swal({
              icon: "success",
              text: response.data.message + "🙂",
              type: "success",
              //   timer: 2000,
            }).than(this.$router.push("/login"))
          }
        })
        .catch((error) => {
          this.loader = false
          this.error.message = error.response.data.detail
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped>
.authentication-bg {
  background-image: url(../../assets/images/authentication-bg.jpg);
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.authentication-bg .bg-overlay {
  background-color: #292626;
}
.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.4;
  background-color: #000;
}
.min-vh-100 {
  min-height: 100vh;
}
.authentication-page-content {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 767px) {
  .authentication-page-content {
    height: auto;
  }
  .min-vh-100 {
    min-height: auto !important;
  }
  .authentication-bg {
    height: 30vh;
    margin-top: 20px;
  }
}
.spinn-loader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
.text-underline {
  text-decoration: underline !important;
}
</style>
