<template>
  <div class="employer-report-header">
    <b-row>
      <b-col lg="8" xl="5" class="my-1">
        <b-form-group
          label="Filter By"
          label-for="filter-by"
          label-cols-sm="3"
          label-cols-lg="3"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-input-group>
            <b-form-select
              :aria-describedby="ariaDescribedby"
              v-model="filterByStatus"
              :options="optionsFilterByStatus"
            ></b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="4" xl="7" class="my-1">
        <div
          class="button-action d-flex align-items-center justify-content-end"
        ></div>
      </b-col>
    </b-row>
    <hr />
  </div>
</template>

<script>
export default {
  name: "EmpoyerHeader",
  data() {
    return {
      filterByStatus: null,
      filterByLocation: null,

      optionsFilterByStatus: [
        { value: null, text: "Select Status" },
        { value: "a", text: "Active" },
        { value: "b", text: "Inactive" },
      ],

      optionsFilterByLocation: [
        { value: null, text: "Select Location" },
        { value: "a", text: "Edinburgh" },
        { value: "b", text: "Edinburgh" },
        { value: "c", text: "Edinburgh" },
        { value: "d", text: "Edinburgh" },
      ],
    }
  },
}
</script>

<style scoped>
.btn-secondary.active {
  background-color: #6c68a7 !important;
}
</style>
