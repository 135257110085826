<template>
  <div class="create-employer">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Create Employer</h4>
      </div>
    </div>
    <b-card class="custom-card">
      <div class="form-title mb-5">
        <h5>Please fill the employer details below.</h5>
        <hr />
      </div>
      <b-form class="form-horizontal" @submit="onSubmit">
        <b-row>
          <b-col lg="4" xl="4">
            <b-form-group>
              <div class="customlabel">
                <label for="">Employer Name</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="emp-name"
                v-model="form.name"
                type="text"
                v-on:keyup="removeValidationMessage('name')"
              ></b-form-input>
              <p class="error" v-if="error.name">
                {{ error.name }}
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="4">
            <b-form-group>
              <div class="customlabel">
                <label for="">Email Address</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="email-address"
                v-model="form.email"
                type="email"
                v-on:keyup="removeValidationMessage('email')"
              ></b-form-input>
              <p class="error" v-if="error.email">
                {{ error.email }}
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="4">
            <b-form-group>
              <div class="customlabel">
                <label for="">Phone Number</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <VuePhoneNumberInput v-model="phoneNumber" @update="onUpdate" />
              <p class="error" v-if="error.phoneNumber">
                {{ error.phoneNumber }}
              </p>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col lg="12" xl="12">
            <b-form-group id="email-address">
              <div class="customlabel">
                <label for="">Address</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-textarea
                id="textarea"
                v-model="form.address"
                rows="5"
                max-rows="5"
                v-on:keyup="removeValidationMessage('address')"
              ></b-form-textarea>
              <p class="error" v-if="error.address">
                {{ error.address }}
              </p>
            </b-form-group>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col lg="4" xl="4">
            <b-form-group id="adds">
              <div class="customlabel">
                <label for="">Address1</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="textarea"
                v-model="form.address1"
                type="text"
                v-on:keyup="removeValidationMessage('address')"
              ></b-form-input>
              <p class="error" v-if="error.address1">
                {{ error.address1 }}
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="4">
            <b-form-group id="addres">
              <div class="customlabel">
                <label for="">Address2</label>
              </div>
              <b-form-input
                id="address2"
                v-model="form.address2"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="4">
            <b-form-group id="cities">
              <div class="customlabel">
                <label for="">City</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="city"
                v-model="form.city"
                type="text"
                v-on:keyup="removeValidationMessage('city')"
              ></b-form-input>
              <p class="error" v-if="error.city">
                {{ error.city }}
              </p>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" xl="4">
            <b-form-group id="postal_cod">
              <div class="customlabel">
                <label for="">Postal Code</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="zip_code"
                v-model="form.zip_code"
                type="text"
                v-on:keyup="removeValidationMessage('zip_code')"
              ></b-form-input>

              <p class="error" v-if="error.zip_code">
                {{ error.zip_code }}
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="4">
            <b-form-group id="state_or_provinc">
              <div class="customlabel">
                <label for="">State or Province</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="state_or_province"
                v-model="form.state_or_province"
                type="text"
                v-on:keyup="removeValidationMessage('state_or_province')"
              ></b-form-input>
              <p class="error" v-if="error.state_or_province">
                {{ error.state_or_province }}
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="4">
            <b-form-group id="country">
              <div class="customlabel">
                <label for="">Country or Region</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="country_or_region"
                v-model="form.country"
                type="text"
                v-on:keyup="removeValidationMessage('country')"
              ></b-form-input>
              <p class="error" v-if="error.country">
                {{ error.country }}
              </p>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="6" xl="6">
            <b-form-group id="emp-terms" label="Terms and Conditions">
              <ckeditor
                :editor="editor"
                v-model="form.term_and_condition"
              ></ckeditor>
            </b-form-group>
          </b-col>
          <b-col lg="6" xl="6">
            <b-form-group id="emp-privacy" label="Privacy Policy">
              <ckeditor
                :height="500"
                :editor="editor"
                v-model="form.privacy_policy"
              ></ckeditor>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" xl="4">
            <b-form-group>
              <div class="file-wrapper py-2">
                <b-link
                  class="file-btn d-flex align-items-center justify-content-center"
                >
                  <span class="material-icons-outlined"> photo_camera </span>
                  Upload Logo
                </b-link>
                <b-form-file
                  v-model="form.logo"
                  class="plain-file file-single"
                  plain
                  id="logo"
                  accept=".jpg, .png,.jepg"
                  @change="changebaseFormat"
                ></b-form-file>
              </div>
              <!-- <div>{{ form.logo ? form.logo.name : "" }}</div> -->
              <div id="preview" v-if="url">
                <img v-if="url" :src="url" />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <p class="error" v-if="error.logo">
          {{ error.logo }}
        </p>
        <b-button type="submit" class="btn-theme mr-2">Next</b-button>
        <b-button class="btn-theme-cancel mr-2" onclick="history.back()"> Cancel </b-button>
        <b-button class="btn-theme-clear mr-2" @click="clearFormData"> Clear </b-button>
      </b-form>
      <!-- <template #footer>
              <div class="button-action">
                  <b-button to="/employers" class="btn-theme">
                    Submit
                  </b-button>
                 <b-button variant="danger">
                    Cancel
                </b-button>
              </div>
            </template> -->
    </b-card>
    <!-- Create New User Modal -->
    <b-modal
      id="create-user"
      v-model="createshow"
      title="Create New User"
      hide-footer
    >
      <CreateUser
        :CreatedEmployerId="CreatedEmployerId"
        @createUser="createUser"
      />
    </b-modal>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input"
import "vue-phone-number-input/dist/vue-phone-number-input.css"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CreateUser from "../EmployerUsers/CreateUser.vue"
export default {
  name: "CreateEmployerForm",
  components: {
    CreateUser,
    VuePhoneNumberInput,
  },
  data() {
    return {
      loader: false,
      url: "",
      phoneIsValid: false,
      createshow: false,
      editor: ClassicEditor,
      phoneNumber: null,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      logo: "",
      form: {
        logo: null,
        name: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        zip_code: null,
        state_or_province: "",
        country: "",
        term_and_condition: "",
        privacy_policy: "",
      },
      error: {
        email: "",
        name: "",
        logo: "",
        phoneNumber: "",
        address1: "",
        city: "",
        zip_code: "",
        state_or_province: "",
        country: "",
      },
      CreatedEmployerId: null,
    }
  },
  methods: {
    onUpdate(payload) {
      this.phoneIsValid = payload.isValid
      if (this.phoneNumber != null) {
        if (payload.isValid == false) {
          this.error.phoneNumber = "Phone Number is wrong"
        } else {
          this.error.phoneNumber = ""
        }
      }
    },
    changebaseFormat(e) {
      const file = e.target.files[0]
      this.createBase64FormatImage(file)
    },
    createBase64FormatImage(file) {
      const reader = new FileReader()
      let rawImg
      reader.onload = (e) => {
        this.logo = reader.result
      }
      reader.readAsDataURL(file)
      this.url = URL.createObjectURL(file)
    },
    removeValidationMessage(arg) {
      if (arg == "name") {
        this.error.name = ""
        this.error.phoneNumber = null
      }
      if (arg == "email") {
        this.error.email = ""
        this.error.phoneNumber = null
      }
      if (arg == "address") {
        this.error.address1 = ""
        this.error.phoneNumber = null
      }
      if (arg == "city") {
        this.error.city = ""
      }
      if (arg == "zip_code") {
        this.error.zip_code = ""
        this.error.phoneNumber = null
      }
      if (arg == "state_or_province") {
        this.error.state_or_province = ""
        this.error.phoneNumber = null
      }
      if (arg == "country") {
        this.error.country = ""
        this.error.phoneNumber = null
      }
    },
    clearFormData() {
      this.form.name = ""
      this.form.email = ""
      this.form.logo = ""
      this.form.address1 = ""
      this.form.address2 = ""
      this.form.city = ""
      this.form.zip_code = null
      this.form.state_or_province = ""
      this.form.country = ""
      this.phoneNumber = null
      this.form.term_and_condition = ""
      this.form.privacy_policy = ""
    },
    createUser(arg) {
      this.createshow = false
      this.$router.push("/employers")
    },
    onSubmit(event) {
      event.preventDefault()

      var payload = {
        name: this.form.name,
        email: this.form.email,
        address1: this.form.address1,
        address2: this.form.address2,
        city: this.form.city,
        zip_code: this.form.zip_code,
        state_or_province: this.form.state_or_province,
        country: this.form.country,
        phone_number: this.phoneNumber,
        terms_and_conditions: this.form.term_and_condition,
        privacy_policy: this.form.privacy_policy,
        logo: this.logo,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      if (
        this.phoneNumber == null ||
        this.form.address1 == "" ||
        this.form.city == "" ||
        this.form.zip_code == null ||
        this.form.state_or_province == "" ||
        this.form.country == "" ||
        this.form.name == "" ||
        this.form.email == ""
      ) {
        if (this.phoneNumber == null) {
          this.error.phoneNumber = "This field may not be blank."
        }
        if (this.form.address1 == "") {
          this.error.address = "This field may not be blank."
        }
        if (this.form.email == "") {
          this.error.email = "This field may not be blank."
        }
        if (this.form.name == "") {
          this.error.name = "This field may not be blank."
        }
        if (this.form.city == "") {
          this.error.city = "This field may not be blank."
        }
        if (this.form.zip_code == "") {
          this.error.postal_code = "This field may not be blank."
        }
        if (this.form.state_or_province == "") {
          this.error.state_or_province = "This field may not be blank."
        }
        if (this.form.country == "") {
          this.error.country = "This field may not be blank."
        }
      } else {
        if (this.phoneIsValid == true) {
          this.loader = true
          this.$axios
            .post(this.base_url + "/employers/", payload, config)
            .then((response) => {
              this.loader = false
              if (response.status == 201) {
                // event.target.reset();
                // this.form.term_and_condition = "";
                // this.form.privacy_policy = "";
                // this.$swal({
                //   icon: "success",
                //   text: "Employer created successfully 🙂",
                //   type: "success",
                //   timer: 1000,
                // });
                // this.$router.push("/employers", 3000);
                this.CreatedEmployerId = response.data.id
                this.createshow = true
              }
            })
            .catch((error) => {
              this.loader = false
              if (error.response.data.email) {
                this.error.email = error.response.data.email[0]
              }
              if (error.response.data.name) {
                this.error.name = error.response.data.name[0]
              }
              if (error.response.data.logo) {
                this.error.logo = error.response.data.logo[0]
              }
              if (error.response.data.state_or_province) {
                this.error.state_or_province =
                  error.response.data.state_or_province[0]
              }
            })
            .finally(() => (this.loading = false))
        }
      }
    },
  },
  watch: {
    name(val) {
      this.formData.special_character = val.replace(/\W/g, "")
    },
  },
}
</script>

<style scoped>
.dropzone {
  min-height: 100px;
  border: 1px dashed #4c4886;
  background: white;
  padding: 0;
  border-radius: 6px;
}
.vue-dropzone {
  border: none;
}
.vue-dropzone,
.dz-message {
  color: #4c4886;
  font-size: 16px;
  font-weight: 700;
}
#preview {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  margin-top: 15px;
}
#preview img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.spinn-loader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
</style>
