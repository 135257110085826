<template>
  <div class="terms-privacy">
    <b-card class="">
      <div class="card-title mb-3">
        <h4>Edit T&C/Privacy Policy</h4>
      </div>
      <b-form class="form-horizontal" @submit="onSubmit">
        <b-row>
          <b-col lg="12" xl="12">
            <b-form-group id="emp-terms" label="Terms and Conditions">
              <ckeditor
                :editor="editor"
                v-model="form.terms_and_conditions"
              ></ckeditor>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" xl="12">
            <b-form-group id="emp-privacy" label="Privacy Policy">
              <ckeditor
                :height="500"
                :editor="editor"
                v-model="form.privacy_policy"
              ></ckeditor>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="submit" class="btn-theme mr-2">Submit</b-button>
            <b-button class="btn-theme-cancel mr-2" @click="employerDetail(id)">Cancel</b-button>
            <b-button class="btn-theme-clear mr-2" @click="clearFormData">Clear</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
export default {
  name: "TermsPrivacy",

  components: {},
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      createshow: false,
      id: this.$route.params.id,
      editor: ClassicEditor,
      form: {
        name: "",
        terms_and_conditions: "",
        privacy_policy: "",
      },
    }
  },
  mounted() {
    this.employerDetail(this.id)
  },
  methods: {
    clearFormData() {
      this.form.terms_and_conditions = "",
      this.form.privacy_policy = ""
    },
    onSubmit(event) {
      event.preventDefault()
      var payload = {
        name: this.form.name,
        terms_and_conditions: this.form.terms_and_conditions,
        privacy_policy: this.form.privacy_policy,
        address: this.form.address,
        phone_number: this.form.phone_number,
        email: this.form.email,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .put(this.base_url + "/employers/" + this.id + "/", payload, config)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              icon: "success",
              text: "Terms&Conditions And Privacy Policy Updated successfully 🙂",
              type: "success",
              timer: 1500,
            })
          }
        })
        .catch((error) => {})
        .finally(() => (this.loading = false))
    },
    employerDetail(id) {
      this.$axios
        .get(this.base_url + "/employers/" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.form.name = response.data.name
          this.form.terms_and_conditions = response.data.terms_and_conditions
          this.form.privacy_policy = response.data.privacy_policy
          this.form.address = response.data.address
          this.form.phone_number = response.data.phone_number
          this.form.email = response.data.email
        })
    },
  },
}
</script>

<style scoped></style>
