<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Create Screening</h4>
      </div>
    </div>
    <CreateScreening />
  </div>
</template>

<script>
import CreateScreening from "./CreateScreening"
export default {
  name: "CreateScreeningQuestion",

  components: {
    CreateScreening,
  },
}
</script>

<style scoped></style>
