import Vue from "vue"
import vSelect from "vue-select"
import VueRouter from "vue-router"
import VueApexCharts from "vue-apexcharts"
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import VueSweetalert2 from "vue-sweetalert2"
import CKEditor from "@ckeditor/ckeditor5-vue2"
import VueFormulate from "@braid/vue-formulate"
import ToggleButton from "vue-js-toggle-button"
import "vue-select/dist/vue-select.css"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "sweetalert2/dist/sweetalert2.min.css"
import "@braid/vue-formulate/themes/snow/snow.scss"

import App from "./App.vue"
import router from "./router"
import api from "./http-common"

import axios from "axios"
require("./assets/css/style.css")
require("./assets/scss/style.scss")

Vue.config.productionTip = false
Vue.prototype.$http = api
Vue.prototype.$axios = axios

Vue.component("vue-phone-number-input")
Vue.component("apexchart", VueApexCharts)
Vue.component("v-select", vSelect)
Vue.use(ToggleButton)
Vue.use(VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.use(CKEditor)
Vue.use(VueSweetalert2)
Vue.use(VueFormulate)
new Vue({
  router,

  render: (h) => h(App),
}).$mount("#app")
