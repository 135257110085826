<template>
  <div>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam orci diam,
      pellentesque eu congue ac, eleifend porttitor dolor. Vivamus quis nunc
      euismod, tempor libero at, vestibulum massa. Sed nec posuere erat. Cras
      congue sapien id cursus dictum. Fusce aliquet auctor mi, tincidunt
      consectetur massa pharetra in. Phasellus maximus consequat metus, a dictum
      erat rhoncus sed. Etiam fermentum nibh ac nisi condimentum volutpat. Etiam
      sed mauris purus. Pellentesque habitant morbi tristique senectus et netus
      et malesuada fames ac turpis egestas. Pellentesque tempor lorem quis nulla
      finibus, vel consectetur odio egestas. Sed rhoncus finibus tincidunt.
    </p>
    <b-embed
      type="iframe"
      aspect="16by9"
      src="http://gw.hoarde.io/spinstart/None/8/form/"
      allowfullscreen
    ></b-embed>
  </div>
</template>

<script>
export default {
  name: "TestForm",
}
</script>
