<template>
  <div class="question-preview">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-card class="custom-card">
      <template #header>
        <b-button to="/screening-questions" size="sm" variant="secondary">
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> chevron_left </span>
            <span>Back</span>
          </span>
        </b-button>
      </template>
      <b-row>
        <b-col md="12">
          <div class="question-compose">
            <div class="screening-questions">
              <div class="screening-category mb-3">
                <h5 class="font-weight-bold">{{ questions.name }}</h5>
                <p class="text-secondary">
                  {{ questions.description }}
                </p>
              </div>
              <b-card class="mb-4">
                <template #header>
                  <h5 class="m-0">{{ questions.card_title }}</h5>
                </template>
                <FormulateForm
                  class="text-left"
                  @submit="onSubmit"
                  v-model="questionsDataForm"
                  ><div class="rm-btn">
                    <FormulateInput
                      type="group"
                      name="questionsData"
                      :repeatable="true"
                      validation="required"
                      v-model="questions_list"
                      #default="{ index }"
                    >
                      <QuestionsAnswer
                        :index="index"
                        :questionAnswer="questions_list"
                      />
                    </FormulateInput>
                  </div>
                  <FormulateInput
                    type="submit"
                    class="btn-theme-custom btn-bold text-uppercase"
                    label="Submit"
                  />
                </FormulateForm>
              </b-card>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import QuestionsAnswer from "../../components/QuestionsAnswer.vue"
export default {
  components: {
    QuestionsAnswer,
  },
  data() {
    return {
      loader: false,
      questionsDataForm: {},
      id: this.$route.params.id,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      questions: {
        id: null,
        name: "",
        card_title: "Answer this part",
        description: "",
      },

      questions_list: [],
    }
  },
  created() {
    this.getScreeningQuestion()
  },
  methods: {
    getScreeningQuestion() {
      this.$axios
        .get(this.base_url + "/surveys/" + this.id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.questions_list = response.data.questions.map((question) => ({
            category: question.category,
            choices: question.choices.split(","),
            id: question.id,
            order: question.order,
            required: question.required,
            survey: question.survey,
            text: question.text,
            type: question.type,
          }))
          this.questions.id = response.data.id
          this.questions.name = response.data.name
          this.questions.description = response.data.description
        })
    },
    onSubmit() {
      this.loader = true
      var payload = this.questionsDataForm.questionsData.map((quData) => ({
        question: quData.questionId,
        body: Array.isArray(quData.body)
          ? JSON.stringify(quData.body)
          : quData.body,
      }))
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .post(
          this.base_url + "/surveys/" + this.id + "/answers/",
          payload,
          config
        )
        .then((response) => {
          console.log(response)
          this.loader = false
        })
        .catch((error) => {
          this.loader = false
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>
<style scoped>
.spinn-loader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
.formulate-input-grouping
  .formulate-input-group-repeatable
  .formulate-input-group-repeatable-remove {
  display: none !important;
}
</style>
