<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Employers</h4>
          <router-link to="/create-employer">
            <b-button
              size="sm"
              class="btn-theme"
              v-b-modal.uploadCampaign
              @click="createshow == true">
              <span class="d-flex align-items-center">
                <span class="material-icons-outlined"> add </span>
                <span>Create</span>
              </span>
            </b-button>
          </router-link>
      </div>
    </div>
    <EmployerList />
  </div>
</template>


<script>
import EmployerList from "./EmployerList.vue"
export default {
  name: "Employers",

  components: {
    EmployerList,
  },
}
</script>

<style scoped></style>
