<template>
  <div class="header">
    <b-navbar type="light" variant="white">
      <div class="navbar-brand-box">
        <b-navbar-brand to="#">
          <img alt="Vue logo" :src="require('../assets/images/logo.png')" />
        </b-navbar-brand>
      </div>
      <div class="sidebar-toggle-left d-flex align-items-center">
        <b-link
          @click="toggle()"
          class="d-flex align-items-center btn-minimize text-secondary"
        >
          <span class="material-icons-outlined"> menu_open </span>
        </b-link>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      </div>
      <b-collapse
        id="nav-collapse"
        is-nav
        class="align-items-center justify-content-end"
      >
        <!-- Right aligned nav items -->
        <b-navbar-nav class="d-flex align-items-center ml-auto">
          <b-nav-item-dropdown
            class="noti-icon notification position-relative"
            no-caret
            right
          >
            <template #button-content class="notification-right">
              <span class="material-icons-outlined"> notifications </span>
              <span class="noti-dot" v-if="redDot"></span>
            </template>
            <div id="infinite-list">
              <LoadMore />
            </div>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown>
            <!-- Using 'button-content' slot -->
            <template #button-content class="d-flex align-items-center">
              <span class="mx-2 d-none d-md-block">{{ username }} </span>
            </template>
            <b-dropdown-item to="/account-detail">My Account</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <!-- <b-dropdown-item to="/login">Sign Out</b-dropdown-item> -->
            <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import moment from "moment"
import LoadMore from "../../src/components/LoadMore.vue"
export default {
  name: "Header",
  components: {
    LoadMore,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      ellipseToggle: false,
      redDot: false,
      username: "",
      notifications: [],
      currentPage: 1,
    }
  },
  methods: {
    getData() {
      this.currentPage + 1
      this.getNotifiacations()
    },
    toggleEllipse() {
      this.ellipseToggle = !this.ellipseToggle
    },
    toggle() {
      this.$emit("clicked", "someValue")
    },
    logout() {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      var refresh_token = parseData.refresh_token
      var payload = {
        refresh_token: refresh_token,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .post(this.base_url + "/logout/", payload, config)
        .then((response) => {
          if (response.data.status == 400) {
            this.error.message = response.data.message
          }
          if (response.status == 200) {
            this.$swal({
              icon: "success",
              text: response.data.message + "🙂",
              type: "success",
              //   timer: 2000,
            })
            const _this = this
            _this.$emit("changePassword", { createshow: false })
          }
        })
        .catch((error) => {
          this.error.message = error.response.data.message
          this.error.email = error.response.data[0].email[0]
        })
        .finally(() => (this.loading = false))
      localStorage.removeItem("user")
      localStorage.removeItem("jwt")
      this.$router.push("/login")
    },
    createUserName() {
      var hours = 9
      var now = new Date().getTime()
      var setupTime = localStorage.getItem("setupTime")
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear()
        this.$router.push("/login")
      }
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      this.username = parseData.name
    },
    getRedGot() {
      this.$axios
        .get(this.base_url + "/red-dot", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.redDot = response.data.result[0].has_notification
        })
    },
    getNotifiacations() {
      this.$axios
        .get(this.base_url + "/notifications/?page=" + this.currentPage, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.notifications = response.data.results.map((result) => ({
            id: result.id,
            is_read: result.is_read,
            log_entry: result.log_entry,
            log_entry_action_time: moment(result.log_entry_action_time).format(
              "MMMM D, YY"
            ),
            log_entry_id: result.log_entry_id,
            log_entry_message: result.log_entry_message,
          }))
        })
    },
  },
  created: function () {
    this.createUserName()
    this.getRedGot()
    this.getNotifiacations()
  },
  mounted() {
    // Detect when scrolled to bottom.
    const listElm = document.querySelector("#infinite-list")
    listElm.addEventListener("scroll", (e) => {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.getData()
      }
    })
    // Initially load some items.
  },
}
</script>

<style scoped>
.navbar-brand-box {
  display: inherit;
  width: 240px;
  background: #252b3b;
  padding: 0.4rem 1.5rem;
}
.navbar-brand-box .navbar-brand {
  display: block;
}
.header .navbar {
  padding: 0 30px 0px 0px;
  box-shadow: 0 0 21px rgb(0 0 0 / 20%);
}
.header .navbar {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  outline: 0;
}
.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: #f1f5f7;
  padding: 3px;
}
.noti-icon .noti-dot {
  position: absolute;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: #ff3d60;
  border-radius: 100%;
  top: 4px;
  right: 6px;
}
.noti-icon a {
  position: relative;
}
.nav-dropdown .dropdown-menu span,
.nav-dropdown .dropdown-menu i {
  color: #828282;
}
.nav-dropdown .dropdown-menu span {
  font-size: 16px;
  margin-left: 10px;
}
.nav-dropdown .dropdown-menu i {
  font-size: 20px;
}
.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: #f1f5f7;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 30px;
}
.app-search span {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 38px;
  left: 13px;
  top: 0;
  color: #74788d;
}
.sidebar-toggle-left,
.app-search {
  margin-left: 20px;
}
.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-soft-primary {
  background-color: #c9cffa;
  color: #5664d2;
}
</style>
