<template>
  <b-row>
    <b-col sm="12" md="12" lg="8" xl="12" class="my-1">
          <b-row>
            <!-- <b-col sm="12" md="12" lg="4" class="my-1"> -->
              <!-- <b-card style="max-width: 40rem;" class="mb-2" img-height="200" :img-src=employersDetails.logo img-alt="Image" img-top>
              </b-card> -->
            <!-- </b-col> -->
            <b-col sm="12" md="12" lg="2" class="my-1">
              <h6 class="">
                <h6 class="font-weight-bold">ATS Name:</h6>
              </h6>
            </b-col>
            <b-col sm="12" md="12" lg="6" class="my-1">
              <h6 class="">
                <b-card-text>{{ employersDetails.name }}</b-card-text>
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="2" class="my-1">
              <h6 class="font-weight-bold">Website:</h6>
            </b-col>
            <b-col sm="12" md="12" lg="6" class="my-1">
              <h6 class="">
                <b-card-text>{{ employersDetails.website }}</b-card-text>
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="2" class="my-1">
              <h6 class="font-weight-bold">Configuration:</h6>
            </b-col>
            <b-col sm="12" md="12" lg="6" class="my-1">
              <small><i>Use django admin to update ATS configuration.</i></small>
              <pre>
              <code>{{ employersDetails.configuration }}</code>
              </pre>
            </b-col>
        </b-row>
      <div style="width: 100%; height: 20px; border-bottom: 1px solid gray; text-align: left">
        <span style="font-size: 15px; background-color: #FFFF; margin: 8px; float:right">
          Connected Employers
        </span>
      </div>
      <br>
      <b-card-group deck>
        <b-card v-for="aats in employersDetails.employers"
            style="max-width: 40rem;"
            class="mb-2"
            v-bind:key="aats.id" :title=aats.name
            >
          <!-- <template #footer> -->
          <small><b>Date Created: </b></small><b-card-text>{{ aats.created_at }}</b-card-text>
          <small><b>ATS Key: </b></small><code>{{ aats.configuration }}</code>
          <!-- </template> -->
        </b-card>
        <!-- <b-card
            v-else
            img-src="https://fl-1.cdn.flockler.com/embed/no-image.svg"
            style="max-width: 20rem;"
            class="mb-2"
            v-bind:key="aats.id" :title=aats.name
            >
            <template #footer>
            <small><b>Date Created: </b></small><b-card-text>{{ aats.created_at }}</b-card-text>
            <small><b>ATS Key: </b></small><code>{{ aats.configuration }}</code>
            </template>
        </b-card> -->
    </b-card-group>
    </b-col>
  </b-row>
</template>


<script>
import moment from "moment"
import CreateEmployerUser from "../ATS/AddEmpATS.vue";
export default {
  name: "EmployerUsersDetails",
  components: {
    CreateEmployerUser,
  },

  data() {
    return {
      id: this.$route.params.id,
      createshow: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      employersDetails: [],
    }
  },
  mounted() {
    this.employerDetail(this.id)
  },
  methods: {
    employerDetail(id) {
      this.$axios
        .get(this.base_url + "/ats/" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.employersDetails = response.data.result
          this.available_ATS = response.data.result.employers.map((data) => ({
            createdAt: moment(
                  data.created_at
                ).format("MMMM D, YYYY")
          }))
        })
    },
    createShowModelHide(arg) {
      this.createshow = false;
      this.getEmployerUsers(1);
    },
  },
}
</script>

<style>

/* xmp:before {
  content: counter(line);
  counter-increment: line;
} */

pre {
  white-space: nowrap;
  counter-reset: line;
  min-height: 50px;
  background: #777777;
  padding: 15px;
  color: #ffffff;
  overflow: scroll
}

.float {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  margin-right: -20px;
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 40px;
  background-color: #68a77d;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 9999;
}
</style>
