<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Employer Edit</h4>
      </div>
    </div>
    <b-card no-body style="min-height: 500px">
      <b-tabs pills card vertical class="vertical-tabs">
        <b-tab title="Basic Information" active>
          <BasicInfo />
        </b-tab>
        <b-tab title="Logo & Photo"
          ><b-card-text>
            <LogoEdit /> </b-card-text
        ></b-tab>
        <b-tab title="T&C/Privacy Policy">
          <TermsPrivacy />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import BasicInfo from "./BasicInfo.vue"
import LogoEdit from "./Logo.vue"
import TermsPrivacy from "./TermsPrivacy.vue"
export default {
  name: "EditEmployer",

  components: {
    BasicInfo,
    LogoEdit,
    TermsPrivacy,
  },
}
</script>

<style scoped></style>
