<template>
  <div>
    <b-row class="mb-2">
      <b-col lg="7" xl="12">
        <b-row>
          <b-col>
            <b-form-group id="question">
              <label>Question</label>
              <span class="d-inline-block ml-1 mt-2 text-danger">*</span>
              <FormulateInput
                id="question"
                type="text"
                name="text"
                validation="required"
                validation-name="Question"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group>
              <label>Category</label>
              <span class="d-inline-block ml-1 mt-2 text-danger">*</span>
              <FormulateInput
                name="category"
                :options="optionsCategory"
                type="select"
                placeholder="Select an option"
                validation=""
                validation-name="Question Category"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group id="qOrdering">
              <label>Ordering</label>
              <span class="d-inline-block ml-1 mt-2 text-danger">*</span>
              <FormulateInput
                id="qOrdering"
                type="number"
                name="order"
                validation="required|number"
                validation-name="Order"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Question type</label>
              <span class="d-inline-block ml-1 mt-2 text-danger">*</span>
              <FormulateInput
                name="type"
                :options="optionsQuestion"
                type="select"
                placeholder="Select an option"
                validation="required"
                validation-name="Question type"
                @change="showChoices($event)"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="showChoice">
            <b-form-group id="choices" class="radio">
              <label>Choices</label>
              <span class="d-inline-block ml-1 mt-2 text-danger">*</span>
              <FormulateInput
                type="textarea"
                name="choices"
                validation="required|max:50,length"
                validation-name="Category Description"
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-form-group>
              <label>Required</label>
              <span class="d-inline-block ml-1 mt-2 text-danger">*</span>

              <FormulateInput
                name="required"
                type="checkbox"
                validation="required"
                validation-name="Required"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import multiselect from "vue-multiselect"
export default {
  name: "QuestionsForm",
  components: { multiselect },
  props: ["isHidden", "formData", "survey"],
  watch: {
    survey: {
      handler(newValue) {
        this.optionsCategory = newValue
      },
      deep: true,
    },
  },
  data() {
    return {
      multipleSelect: null,

      showChoice: false,
      radio: false,
      select: false,
      multiple: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      optionsCategory: this.survey,
      optionsQuestion: [
        { value: "text", label: "Text" },
        { value: "short-text", label: "Short Text" },
        { value: "radio", label: "Radio" },
        { value: "select", label: "Select" },
        { value: "select-multiple", label: "Select-Multiple" },
        { value: "integer", label: "Integer" },
        { value: "float", label: "Float" },
        { value: "date", label: "Date" },
      ],
      optionsRequired: [
        { value: "true", label: "True" },
        { value: "false", label: "False" },
      ],
    }
  },
  methods: {
    showChoices(event) {
      if (event.target.value == "radio") {
        this.radio = true
        this.multiple = false
        this.select = false
        const _this = this
        _this.$emit("active", {
          isHidden: (this.showChoice = true),
          type: "radio",
        })
      }
      if (event.target.value == "select") {
        this.select = true
        this.multiple = false
        this.radio = false
        const _this = this
        _this.$emit("active", {
          isHidden: (this.showChoice = true),
          type: "select",
        })
      }
      if (event.target.value == "select-multiple") {
        this.multiple = true
        this.select = false
        this.radio = false
        const _this = this
        _this.$emit("active", {
          isHidden: (this.showChoice = true),
          type: "multiple",
        })
      }
      if (
        event.target.value != "select-multiple" ||
        event.target.value != "select" ||
        event.target.value != "radio"
      ) {
        if (
          event.target.value == "select-multiple" ||
          event.target.value == "select" ||
          event.target.value == "radio"
        ) {
        } else {
          const _this = this
          _this.$emit("active", {
            isHidden: (this.showChoice = false),
            type: "",
          })
        }
      }
    },
  },
}
</script>
<style scoped>
.z-index-8 {
  z-index: 8;
}
</style>
