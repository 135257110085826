var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('editor',{attrs:{"apiKey":"45b0b2zxyd5tn5w785wn8bycwxiu7m0bcr5isrtphjhto7u6","init":{
      height: 400,
      menubar: true,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help',
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }