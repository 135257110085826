<template>
  <div class="passwordsettings">
    <FormulateForm
      class="mt-3 mb-3 mt-lg-5 mb-lg-5 text-left"
      v-model="formData"
      @submit="submitHandler"
    >
      <b-row>
        <b-col md="4">
          <label class="font-weight-600 text-secondary">Lower case</label>
        </b-col>
        <b-col md="8">
          <FormulateInput
            type="checkbox"
            name="lower_case"
            v-model="formData.lower_case"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <label class="font-weight-600 text-secondary">Uper case</label>
        </b-col>
        <b-col md="8">
          <div class="d-flex align-items-center justify-content-between">
            <FormulateInput
              type="checkbox"
              name="upper_case"
              v-model="formData.upper_case"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <label class="font-weight-600 text-secondary">Numeric</label>
        </b-col>
        <b-col md="8">
          <div class="d-flex align-items-center justify-content-between">
            <FormulateInput
              type="checkbox"
              name="numeric"
              v-model="formData.numeric"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <label class="font-weight-600 text-secondary"
            >Special Character</label
          >
        </b-col>
        <b-col md="8">
          <div class="d-flex align-items-center justify-content-between">
            <FormulateInput
              type="text"
              class="mb-3"
              name="special_character"
              v-model="formData.special_character"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <label class="font-weight-600 text-secondary">Length</label>
        </b-col>
        <b-col md="8">
          <div class="d-flex align-items-center justify-content-between">
            <FormulateInput
              type="text"
              class="mb-3"
              name="length"
              validation="^required|number"
              v-model="formData.length"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-end px-2">
        <b-button class="btn-theme-clear mr-2" @click="clearFormData"> Clear </b-button>
        <FormulateInput
          type="submit"
          class="btn-theme-custom btn-bold text-uppercase"
          label="Update"
        />
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import UpdatePassword from "./UpdatePassword.vue"
import UpdateRole from "./UpdateRole.vue"

export default {
  name: "PasswordSettings",
  components: {
    UpdatePassword,
    UpdateRole,
  },
  data() {
    return {
      accountDetail: [],
      createshow: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      formData: {
        numeric: false,
        lower_case: false,
        upper_case: false,
        special_character : "",
        length: "",
      },
      error: {
        message: "",
      },
    }
  },
  mounted() {
    this.getPasswordDetail()
  },
  methods: {
    clearFormData() {
      this.formData.upper_case = ""
      this.formData.lower_case = ""
      this.formData.numeric = ""
      this.formData.special_character = ""
      this.formData.length = ""
    },
    changePassword(arg) {
      this.createshow = false
    },

    getPasswordDetail() {
      this.$axios
        .get(this.base_url + "/password-security", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.formData.lower_case = response.data.result.lower_case
          this.formData.upper_case = response.data.result.upper_case
          this.formData.numeric = response.data.result.numeric
          this.formData.special_character =
            response.data.result.special_character
          this.formData.length = response.data.result.length
        })
    },
    async submitHandler(data) {
      var payload = {
        lower_case: data.lower_case,
        upper_case: data.upper_case,
        numeric: data.numeric,
        special_character: data.special_character,
        length: data.length,
      }
      const config = {
        headers: { Authorization: this.token },
      }

      this.$axios
        .put(this.base_url + "/password-security", payload, config)
        .then((response) => {
          if (response.data.status == 400) {
            this.error.message = response.data.message
          }
          if (response.status == 200) {
            this.$swal({
              icon: "success",
              text: response.data.message + "🙂",
              type: "success",
              //   timer: 2000,
            })
          }
        })
        .catch((error) => {
          this.error.message = error.response.data.message
          this.error.email = error.response.data[0].email[0]
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped></style>
