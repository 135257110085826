<template>
  <div class="page-maincontent">
    <CreateJobForm />
  </div>
</template>

<script>
import CreateJobForm from "./CreateJob.vue"

export default {
  name: "CreateJob",
  components: {
    CreateJobForm,
  },
}
</script>

<style scoped></style>
