<template>
  <div class="partners-listing">
   <template>
  <div class="partner-report-header">
    <b-row>
      <b-col lg="6" xl="5" class="my-1">
        <b-form-group
          label="Filter By"
          label-for="filter-by"
          label-cols-sm="3"
          label-cols-lg="3"
          class="mb-0"
          v-slot="{ ariaDescribedby }"

        >
          <b-input-group>
            <b-form-select
              :aria-describedby="ariaDescribedby"
              v-model="filter"
              :options="optionsFilterByStatus"
              @change="getPartners"
              placeholder="Please Select"
            ></b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" xl="7" class="my-1">
      </b-col>
    </b-row>
    <hr />
  </div>
</template>

    <b-card class="custom-card">
      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-form-group
            label="Search"
            label-for="filter-input"
            label-cols-sm="3"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="ml-auto search-filter"
          >
            <b-input-group size="sm">
              <b-form-input
              id="filter-input"
              v-model="filter"
              type="text"
              placeholder="Type to Search"
              @keyup="getPartners(currentPage, $event.target.value)"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="clearSearch(1), (filter = '')"
                >Clear</b-button
              >
            </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
         show-empty
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
      :items-provider="getPartners"
      >
      </b-table>
      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
            class="my-0 ml-auto float-right"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>
import ToggeComp from "../../components/ToggeComp.vue";
import Header from "./Header";
export default {
  name: "PartnersList",
  components: {
    "toggle-switch": ToggeComp,
    Header,
  },
  data() {
    return {
      checked: true,
      unchecked: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      items: [],
       optionsFilterByStatus: [
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" },
      ],
      fields: [
        { key: "id", label: "ID",sortable: true },
        { key: "name", label: "Partner Name",sortable: true },
        { key: "address1", label: "Partner Address",sortable: true },
        { key: "phone_number", label: "Phone Number",sortable: true },
        { key: "employer_count", label: "Employer Count",sortable: true },
        { key: "user_count", label: "Partner Users",sortable: true },
        { key: "status", label: "Status",sortable: true },
        
      ],
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      filter: "",
      totalItems: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getPartners(this.currentPage, (event = ""));
  },
  methods: {
      clearSearch() {
      this.filter = "";
      this.items=[];
      this.currentPage=1;
      this.getPartners(this.currentPage, (event = ""));
    },
    filterbyStatus(arg) {
      this.filter = arg.filter;
    },
  
    async getPartners(currentPage, event = "") {
      this.$axios
        .get(
          this.base_url +
            "/partners/?keyword_search=" +
            this.filter +
            "&page=" +
            this.currentPage,
          {
            headers: { Authorization: this.token },
          }
        )
        .then((response) => {
          if(this.filter==""){
            if(this.currentPage==1){
               this.items=[]; 
              }
            this.items = [...this.items,...response.data.results];
          }
          else{
           if(this.currentPage==1){
               this.items=[]; 
              }
              this.items = [...this.items,...response.data.results];
          }
          this.current_page = response.data.current_page;
          this.totalItems = response.data.result_count;
        });
    },
    partnerDetail(id) {
      this.$router.push("/partner-users/" + id);
    },
    activate(arg) {
      var payload = {
        id: arg.id,
      };
      const config = {
        headers: { Authorization: this.token },
      };
      this.$axios
        .post(
          this.base_url + `/partners/${arg.check ? "activate" : "inactivate"}/`,
          payload,
          config
        )
        .then((response) => {
          this.$swal(
            `${arg.check ? "Activated" : "Inactivated"}`,
            `Partner is now ${
              arg.check ? "activated" : "inactivated"
            } successfully 🙂`,
            "success"
          );
        });
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getPartners(value, (event = ""));
      },
    },
  },
};
</script>

<style scoped></style>
