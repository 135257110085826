<template>
  <div class="employer-user-details">
    <b-card class="custom-card">
      <template #header>
        <b-row class="align-items-center">
          <b-col sm="4" md="3" lg="3" xl="2" class="my-1">
            <b-form-group class="mb-0">
              <b-form-select
                v-model="userSelected"
                :options="userOptions"
                v-on:change="getData"
                name="employer"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <!-- Edit  User Modal -->
      <b-modal id="edit-user" v-model="editshow" title="Edit User">
        <EditUser />

        <template #modal-footer>
          <div class="w-100 button-action">
            <b-button class="btn-theme" size=""> Submit </b-button>
            <b-button variant="danger" size="" @click="editshow = false">
              Cancel
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
        responsive="lg"
        outlined
        hover
        head-variant="light"
      >
        <template #cell(actions)="items">
          <div class="d-flex align-items-center">
            <b-link
              @click="getUserDetail(items.item.id)"
              class="mr-2 mt-2 text-warning"
            >
              <span class="material-icons-outlined"> visibility </span>
            </b-link>

            <div class="d-inline-block" v-if="items.item.status == 'active'">
              <b-form-checkbox
                :checked="true"
                name="check-button"
                @change="inactivate(items.item.id)"
                switch
              >
              </b-form-checkbox>
            </div>
            <div class="d-inline-block" v-if="items.item.status == 'inactive'">
              <b-form-checkbox
                :checked="false"
                name="check-button"
                switch
                @change="activate(items.item.id)"
              >
              </b-form-checkbox>
            </div>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="pages"
            :per-page="perPage"
            base-url="#"
            class="my-0 ml-auto float-right"
          ></b-pagination-nav>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import EditUser from "../EmployerUsers/EditUser.vue"
export default {
  name: "UsersList",
  components: {
    EditUser,
  },
  data() {
    return {
      editshow: false,
      userSelected: null,
      checked: true,
      unchecked: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      userOptions: [],
      items: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "username", label: "User Name" },
        { key: "email", label: "User Email" },
        { key: "phone_number", label: "Phone Number" },
        { key: "employer", label: "Employer" },
        { key: "type", label: "User Type" },
        { key: "role", label: "Users Roles" },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20, { value: 100, text: "Show a lot" }],
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },

  mounted() {
    this.getData(this.currentPage)
    this.getEmployers()
  },

  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`
    },
    createUserModal() {
      this.$swal(
        "Added Successfully",
        "You are successfully added this user",
        "OK"
      )
    },

    getData(currentPage) {
      var employer = ""
      if (event) {
        var employer = event
      }

      this.$axios
        .get(this.base_url + "/users?employer=" + 68 + "&page=" + currentPage, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.items = response.data.results
          this.pages = response.data.total_pages
        })
    },
    getUserDetail(id) {
      this.$router.push("/users/" + id)
    },
    activate(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to activate this user",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes Activate it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          var payload = {
            id: id,
          }
          const config = {
            headers: { Authorization: this.token },
          }
          this.$axios
            .post(this.base_url + "/users/activate/", payload, config)
            .then((response) => {
              this.$swal(
                "Activated",
                "User is now activated successfully 🙂",
                "success"
              )
            })
        } else {
          this.$swal("Cancelled", "Your user is still intact", "info")
        }
      })
    },
    inactivate(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to inactivate this user",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes Inactivate it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          var payload = {
            id: id,
          }
          const config = {
            headers: { Authorization: this.token },
          }
          this.$axios
            .post(this.base_url + "/users/inactivate/", payload, config)
            .then((response) => {
              this.$swal(
                "Inactivate",
                "User is now inactivated successfully 🙂",
                "success"
              )
            })
        } else {
          this.$swal("Cancelled", "Your user is still intact", "info")
        }
      })
    },
    getEmployers() {
      this.$axios
        .get(this.base_url + "/employers", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          let newRoleOptions = response.data.results.map((data) => ({
            value: data.id,
            text: data.name,
          }))
          this.userOptions = newRoleOptions
        })
    },
  },
}
</script>

<style scoped></style>
