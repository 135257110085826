<template>
  <div class="employer-details">
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">User Details</h4>
        <div class="button-action"></div>
      </div>
    </div>
    <b-card class="custom-card">
      <b-row>
        <b-col sm="12" md="12" lg="6" xl="6" class="my-1">
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Name</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="text-secondary" v-if="employersDetails.first_name">
                {{
                  employersDetails.first_name + " " + employersDetails.last_name
                }}
              </h6>
              <h6 class="text-secondary" v-else>N/A</h6>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Phone Number</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="text-secondary" v-if="employersDetails.phone_number">
                {{ employersDetails.phone_number }}
              </h6>
              <h6 class="text-secondary" v-else>N/A</h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Email</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="">
                <b-link> {{ employersDetails.email }}</b-link>
              </h6>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6" class="my-1">
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">User Name</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="" v-if="employersDetails.username">
                {{ employersDetails.username }}
              </h6>
              <h6 class="" v-else>N/A</h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Role</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="">
                {{ employersDetails.role }}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Type</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="">
                {{ employersDetails.type }}
              </h6>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TextEditor from "../../components/TextEditor"
import CreateUser from "../EmployerUsers/CreateUser.vue"
export default {
  name: "EmployerUsersDetails",

  components: {
    TextEditor,
    CreateUser,
  },

  data() {
    return {
      createshow: false,
      id: this.$route.params.id,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      employersDetails: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  mounted() {
    this.getUserDetail(this.id)
  },
  methods: {
    createUserModal() {
      this.$swal(
        "Added Successfully",
        "You are successfully added this user",
        "OK"
      )
    },
    getUserDetail(id) {
      this.$axios
        .get(this.base_url + "/users/" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.employersDetails = response.data
        })
    },
  },
}
</script>

<style scoped></style>
