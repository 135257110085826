<template>
  <div class="page-maincontent">
    <div class="create-job">
      <div class="card-title mb-3">
        <div class="d-sm-flex align-items-center justify-content-sm-between">
          <h4 class="mb-sm-0">Create Job</h4>
        </div>
      </div>

      <b-row class="d-flex align-items-center">
        <b-col md="6" offset-md="3">
          <div
            class="cus-popup d-flex align-items-center justify-content-around"
          >
            <b-button class="custombtn" to="/import-from-ats"
              >Import From ATS's</b-button
            >
            <b-button class="custombtn" to="/create-job"
              >Create Manually</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportATSPopUp",
  components: {},
  data() {
    return {}
  },
}
</script>
<style scoped>
.cus-popup {
  border: 1px solid hsl(240, 3%, 7%) !important;
  background: #fff;
  border-radius: 5px;
  height: 400px;
}
.custombtn,
.custombtn:hover,
.custombtn:focus {
  background: #fff;
  color: #000;
  border: 1px solid hsl(240, 3%, 7%);
}
</style>
