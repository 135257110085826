<template>
    <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Jobs</h4>
          <b-dropdown
            text="Create"
            id="dropdown-buttons" right 
            variant="link"
            size="sm"
            toggle-class="text-decoration-none text-white"
            class="btn-theme"
          >
          <b-dropdown-item @click="showModal">
            <span> Collection Fields </span>
          </b-dropdown-item>
          <b-dropdown-item to="/create-job/">
            <span> Create Manually </span>
          </b-dropdown-item>
          <b-dropdown-item to="/import-from-ats/">
            <span> Import from ATS </span>
          </b-dropdown-item>
          </b-dropdown>

          <b-modal
            ref="my-modal"
            hide-footer
            title="Create job collection fields"
            size="xl"
          >
            <div class="d-block text-center">
              <CollectionForm :hideModal="hideModal" />
            </div>
          </b-modal>
      </div>
    </div>
    <JobsLayout />
  </div>
</template>

<script>
import JobsLayout from "./JobsLayout.vue"
import CollectionForm from "./CollectionForm.vue"

export default {
  name: "JobsListing",

  components: {
    JobsLayout,
    CollectionForm,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      totalJobs: null,
    }
  },
  mounted() {
    // Set the initial number of items
    this.getJobList(this.currentPage, (event = ""))
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = this.$route.query.page
    },
    getJobList(currentPage, event = "") {
      this.$axios
        .get(this.base_url + "/jobs?page=1", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.items = response.data.results
          this.current_page = response.data.current_page
          this.pages = response.data.total_pages
          this.totalJobs = response.data.result_count
          this.TotalPages = response.data.total_pages
        })
    },
    showModal() {
      console.log(this.$refs)
      this.$refs["my-modal"].show()
    },
    hideModal() {
      this.$refs["my-modal"].hide()
    },
  },
}
</script>

<style scoped>
.float-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  margin-right: -20px;
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 40px;
  background-color: #68a77d;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 9999;
}

.float-button > button.btn-link {
  color: #fff !important;
}

.collection-buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.modalFoo {
  width: 1500px;
}
</style>
