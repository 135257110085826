<template>
  <div>
    <FormulateInput
      type="email"
      name="email"
      validation="required|email"
      label="Email"
    />

    <FormulateInput
      v-model="initialRole"
      :options="modifiedRoleoptions"
      type="select"
      placeholder="Select Role"
      label="User Role"
      name="role"
      validation="required"
    />
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: "CreateMultiplePartnerUser",
  components: {},
  props: ["isHidden", "formData"],
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      roleoptions: [],
      newRoleOptions: [],
      initialRole: 7,
    }
  },
  methods: {
    getRole(event, index) {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      var userTypeId = parseData.type_id
      const _this = this

      axios
        .get(this.base_url + "/user-types/" + 3 + "/roles", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.roleoptions = response.data.results
        })
    },
  },
  mounted() {},
  created: function () {
    this.getRole()
  },
  computed: {
    modifiedRoleoptions() {
      let newRoleOptions = this.roleoptions.map(({ id, title }) => ({
        value: id,
        label: title,
      }))
      return newRoleOptions
    },
  },
}
</script>
<style scoped>
.z-index-8 {
  z-index: 8;
}
</style>
