<template>
  <div class="footer-content">
    <div class="d-flex align-items-center justify-content-between py-2">
      <div class="copyrights">
        <span class="copyrights-text"> &copy; 2022 Hoarde</span>
      </div>
      <b-nav class="footer-links">
        <b-nav-item>Terms</b-nav-item>
        <b-nav-item class="divider">|</b-nav-item>
        <b-nav-item>Privacy</b-nav-item>
      </b-nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
}
</script>

<style scoped>
/* #505D69 */
.footer-links .nav-link,
.copyrights-text {
  color: #505d69;
  font-size: 14px;
}
.footer-links .divider .nav-link {
  padding-left: 0;
  padding-right: 0;
}
.footer-content {
  margin-left: 240px;
  padding-left: 20px;
}
@media screen and (max-width: 992px) {
  .footer-content {
    margin-left: 0px;
  }
}
</style>
