<template>
  <div id="mfa-auth">
    <b-container fluid class="p-lg-0 height-100">
      <div class="position-relative height-100">
        <div class="card p-2 text-center">
          <h6>Please enter the OTP <br /></h6>
          <div><span> sent to your register email</span> <small></small></div>

          <div
            id="otp"
            class="inputs d-flex flex-row justify-content-center mt-2"
          >
            <input
              class="m-2 text-center form-control rounded"
              type="text"
              id="input1"
              maxlength="1"
              v-model="number1"
            />
            <input
              class="m-2 text-center form-control rounded"
              type="text"
              id="input2"
              maxlength="1"
              v-model="number2"
            />
            <input
              class="m-2 text-center form-control rounded"
              type="text"
              id="input3"
              maxlength="1"
              v-model="number3"
            />
            <input
              class="m-2 text-center form-control rounded"
              type="text"
              id="input4"
              maxlength="1"
              v-model="number4"
            />
            <input
              class="m-2 text-center form-control rounded"
              type="text"
              id="input4"
              maxlength="1"
              v-model="number5"
            />
            <input
              class="m-2 text-center form-control rounded"
              type="text"
              id="input4"
              maxlength="1"
              v-model="number6"
            />
          </div>
          <div class="mt-4">
            <button
              type="submit"
              class="btn btn-danger px-4 validate"
              @click="onSubmit"
            >
              Validate
            </button>
          </div>

          <div
            class="mt-3 content d-flex justify-content-center align-items-center"
          ></div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "mfa-auth",
  components: {},
  data() {
    return {
      number1: null,
      number2: null,
      number3: null,
      number4: null,
      number5: null,
      number6: null,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },
  methods: {
    onSubmit(event) {
      if (
        this.number1 == null ||
        this.number2 == null ||
        this.number3 == null ||
        this.number4 == null ||
        this.number5 == null ||
        this.number6 == null
      ) {
        this.$swal({
          icon: "error",
          text: "Invalid MFA code!",
          type: "error",
        }).finally(() => (this.loading = false))
      } else {
        this.$axios
          .get(
            this.base_url +
              "/mfa-auth/" +
              this.number1 +
              this.number2 +
              this.number3 +
              this.number4 +
              this.number5 +
              this.number6,
            { headers: { Authorization: this.token } }
          )
          .then((response) => {
            if (response.data.status_code == 200) {
              var userDetails = localStorage.getItem("user")
              var parseData = JSON.parse(userDetails)
              parseData.hasOwnProperty("role")
              var is_admin = null
              var type = null
              if (parseData.hasOwnProperty("role")) {
                is_admin = parseData.role
                type = parseData.type
              }
              if (parseData.hasOwnProperty("role_id")) {
                is_admin = parseData.role_id
                type = parseData.type_id
              }
              if (localStorage.getItem("jwt") != null) {
                if (this.$route.params.nextUrl != null) {
                  this.$router.push(this.$route.params.nextUrl)
                } else {
                  if (is_admin == 1 && type == 1) {
                    this.$router.push("/dashboard")
                  } else {
                    this.$router.push("/")
                  }
                }
              }
            } else {
              this.$swal({
                icon: "error",
                text: response.data.message,
                type: "error",
              }).then(() => {
                this.$router.push("/404")
              })
            }
          })
          .catch((error) => {
            this.$swal({
              icon: "error",
              text: error.response.data.message,
              type: "error",
            }).then(() => {
              this.$router.push("/404")
            })
          })
          .finally(() => (this.loading = false))
      }
    },
  },
}
</script>

<style scoped>
.height-100 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  width: 400px;
  border: none;
  height: 300px;
  margin: 0 auto;
  box-shadow: 0px 5px 20px 0px #6f1667;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card h6 {
  color: #6f1667;
  font-size: 20px;
}
.inputs input {
  width: 40px;
  height: 40px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.form-control:focus {
  box-shadow: none;
  border: 2px solid #6f1667;
}
.validate {
  border-radius: 20px;
  height: 40px;
  background-color: #6f1667;
  border: 1px solid #6f1667;
  width: 100%;
}
.content a {
  color: #d64f4f;
  transition: all 0.5s;
}
.content a:hover {
  color: #6f1667;
}
</style>
