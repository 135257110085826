<template>
  <div class="whatsnew">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center">
        <h4 class="mb-sm-0">Whats' New?</h4>
      </div>
    </div>
    <b-card no-body class="custom-card">
      <b-tabs class="tabs-icons">
        <b-tab active>
          <template #title>
            <span class="material-icons-outlined mr-1"> work_outline </span>
            Jobs
            <span class="new-noti ml-2">{{ recentCount.jobs }}</span>
          </template>
          <div class="jobs-list">
            <JobsList />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="material-icons-outlined mr-1"> business </span>
            Employers
            <span class="new-noti ml-2">{{ recentCount.employers }}</span>
          </template>
          <div class="employers-list">
            <EmployersList />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="material-icons-outlined mr-1"> people </span>
            Candidates
            <span class="new-noti ml-2">{{ recentCount.candidates }}</span>
          </template>
          <div class="candidates-list">
            <CandidatesList />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import JobsList from "./JobsList"
import CandidatesList from "./CandidatesList"
import EmployersList from "./EmployersListing"

export default {
  name: "WhatsNew",
  components: {
    JobsList,
    EmployersList,
    CandidatesList,
  },
  props: ["recentCount"],
  data() {
    return {}
  },
}
</script>

<style scoped></style>
