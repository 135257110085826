<template>
  <div class="my-language">
    <div class="card-title mb-2">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-inline-block">
          <h5 class="mb-0 font-weight-bold">Date & Time Formatting</h5>
        </div>
        <b-button size="sm" class="btn-theme" @click="createshow = true">
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> add </span>
            <span>Add Date & Time Format</span>
          </span>
        </b-button>
      </div>
    </div>
    <b-modal
      id="AddLanguage"
      title="Add Language"
      v-model="createshow"
      hide-footer
    >
      <FormulateForm
        class="text-left"
        @submit="submitHandler"
        v-model="formData"
      >
        <b-row>
          <b-col md="6" xl="12">
            <FormulateInput
              type="text"
              class="mb-3"
              name="date_format"
              validation="required"
              placeholder="Date Format"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12">
            <FormulateInput
              type="text"
              class="mb-3"
              name="time_format"
              validation="required"
              placeholder="Time Format"
            />
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="6" xl="12" class="text-center">
            <b-button
              type="submit"
              block
              class="btn-theme btn-bold text-uppercase d-inline-block w-auto"
              >Add</b-button
            >
          </b-col>
        </b-row>
      </FormulateForm>
    </b-modal>
    <b-card class="mb-4">
      <FormulateForm
        class="text-left"
        @submit="updateHandler"
        v-model="formDateTime"
      >
        <b-row>
          <b-col md="6" xl="4">
            <b-form-group label="Date Format">
              <FormulateInput
                v-model="dateselected"
                type="select"
                :options="dateoptions"
                placeholder="Select an date option"
              />
            </b-form-group>
            <b-form-group label="Time Format">
              <FormulateInput
                v-model="timeselected"
                type="select"
                :options="timeoptions"
                placeholder="Select an time option"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="6" xl="4" class="text-center">
            <b-button
              type="submit"
              block
              class="btn-theme d-inline-block w-auto"
              >Update</b-button
            >
          </b-col>
        </b-row>
      </FormulateForm>
    </b-card>
  </div>
</template>

<script>
export default {
  setup() {},
  data() {
    return {
      timeselected: null,
      dateselected: null,
      timezone: false,
      createshow: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      timeoptions: [],
      dateoptions: [],
      dateTimeOptions: [],
      formData: {},
      formDateTime: {},
    }
  },
  created() {
    this.getDateTimes()
    this.getDefaultDateTimeLocalization()
  },
  methods: {
    async submitHandler(data) {
      var payload = {
        date_format: data.date_format,
        time_format: data.time_format,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .post(this.base_url + "/datetime/", payload, config)
        .then((response) => {
          if (response.status == 201) {
            this.$swal({
              icon: "success",
              text: "Add datetime format successfully 🙂",
              type: "success",
              timer: 2000,
            })
            this.createshow = false
          }
        })
        .catch((error) => {})
        .finally(() => (this.loading = false))
    },
    async updateHandler(data) {
      var payload = {
        id: this.dateselected,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .put(this.base_url + "/localization/default_datetime/", payload, config)
        .then((response) => {
          if (response.status == 200) {
            this.getDefaultDateTimeLocalization()
            this.$swal({
              icon: "success",
              text: response.data.message + " 🙂",
              type: "success",
              timer: 2000,
            })
          }
        })
        .catch((error) => {})
        .finally(() => (this.loading = false))
    },
    getDateTimes() {
      this.$axios
        .get(this.base_url + "/datetime", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.dateoptions = response.data.results.map((language) => ({
            value: language.id,
            label: language.date_format,
          }))
          this.timeoptions = response.data.results.map((language) => ({
            value: language.id,
            label: language.time_format,
          }))
        })
    },
    getDefaultDateTimeLocalization() {
      this.$axios
        .get(this.base_url + "/localization", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          var date = response.data.result.datetime.split(" ")
          var selectedDateLabel = date[0]
          var selectedTimeLabel = date[1]
          var selectedDate = this.dateoptions.filter(function (elem) {
            if (elem.label == selectedDateLabel) {
              return elem
            }
          })
          var date = selectedDate.map((d) => ({
            value: d.value,
            label: d.label,
          }))
          this.dateselected = date[0].value
          var selectedTime = this.timeoptions.filter(function (elem) {
            if (elem.label == selectedTimeLabel) {
              return elem
            }
          })
          var date = selectedTime.map((d) => ({
            value: d.value,
            label: d.label,
          }))
          this.timeselected = date[0].value
        })
    },
  },
}
</script>
