<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Partner Reports</h4>
      </div>
    </div>
    <b-card no-body class="custom-card">
      <b-tabs class="tabs-icons">
        <b-tab active>
          <template #title> New Partner </template>
          <div class="new-partner p-3 p-lg-4">
            <NewPartner />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import NewPartner from "./NewPartner"
import PartnerUser from "./PartnerEmployer"
export default {
  name: "PartnerReports",

  components: {
    NewPartner,
    PartnerUser,
  },
}
</script>

<style scoped></style>
