<template>
  <div>
    <b-card>
    <FilterHeader @sendFilter="setfilter" />
    </b-card>
    <br/>
    <b-row>
      <b-col sm="4" md="4" lg="4" xl="4" class="my-1">
        <b-form-group
          label-for="per-page-select"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="perpage-select"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="8" md="8" lg="8" xl="8" class="my-1">
        <b-form-group
          label-for="filter-input"
          label-align-sm="right"
          label-size="sm"
          class="ml-auto search-filter"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="text"
              placeholder="Keyword Search"
              @keyup="getJobList(currentPage, $event.target.value, 2000)"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="clearSearch(1), (filter = '')"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
     <b-table
     show-empty
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
    >
      <template #cell(first_name)="items">
        <div>
          {{ items.item.first_name}} {{ items.item.last_name}} 
        </div>
      </template>
      <template #cell(job)="items">
        <div>
          <router-link :to="{ name: 'JobsDetails', params: { id: items.item.id }, hash: '#summary'}">{{items.item.job}}</router-link> 
        </div>
      </template>
      <template #cell(actions)="items">
        <div class="d-flex align-items-center">
          <b-link
            @click="getCandidateDetail(items.item.id)"
            class="mr-2 mt-2 text-warning"
          >
            <span class="material-icons-outlined"> visibility </span>
          </b-link>
          <b-link class="text-info" @click="template(items.item)">
            <span class="material-icons-outlined"> email </span>
          </b-link>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
        <b-form-group
          label-for="per-page-select"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="perpage-select"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
          class="my-0 ml-auto float-right"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>
import moment from "moment";
import ToggeComp from "../../components/ToggeComp.vue";
import FilterHeader from "./FilterHeader";
export default {
  name: "ListLayout",
  components: {
    "toggle-switch": ToggeComp,
    FilterHeader,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      items: [],


      fields: [
        { key: "id", label: "ID",sortable: true },
        {
          key: "created_at",
          label: "Application Date",sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              return moment(item.created_at).format("YYYY-MM-DD");
            } else {
            }
          },
        },
        { key: "first_name", label: "Candidate Name",sortable: true },
        { key: "job", label: "Job Application",sortable: true },
        { key: "email", label: "Email Address",sortable: true },
        { key: "source", label: "Source",sortable: true },
        { key: "actions", label: "Actions" },
      ],
      searchKey: "keyword_search",
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      filter: "",
      totalItems: null,
      filterByFromDate: "",
      filterByToDate: "",
      fromDate: "",
      toDate: "",
      filterByEmployer: "",
      employer: "",
      filterByJob: "",
      job: "",
      filterBySource: "",
      source: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getJobList(this.currentPage, (event = ""));
  },
  methods: {
    getCandidateDetail(id) {
      this.$router.push("/candidates/" + id);
    },
    setfilter(arg) {
      this.searchKey = arg.searchkey;
      if (arg.searchkey == "date_from") {
        this.filterByFromDate = arg.searchkey;
        this.fromDate = arg.filter;
      }
      if (arg.searchkey == "date_to") {
        this.filterByToDate = arg.searchkey;
        this.toDate = arg.filter;
      }
      if (arg.searchkey == "employer") {
        this.filterByEmployer = arg.searchkey;
        this.employer = arg.filter;
      }
      if (arg.searchkey == "source") {
        this.filterBySource = arg.searchkey;
        this.source = arg.filter;
      }
      if (arg.searchkey == "job") {
        this.filterByJob = arg.searchkey;
        this.job = arg.filter;
      }

      this.getJobList(this.currentPage, (event = ""));
    },
    getJobList(currentPage, event = "") {
      if (this.filter != "") {
        this.$axios
          .get(
            this.base_url +
              "/candidates?page=" +
              this.currentPage +
              "&" +
              this.searchKey +
              "=" +
              this.filter,
            {
              headers: { Authorization: this.token },
            }
          )
          .then((response) => {
            if(this.filter==""){
              if(this.currentPage==1){
               this.items=[]; 
              }
                  this.items = [...this.items,...response.data.results];
            }
          else{
          if(this.currentPage==1){
            this.items=[]; 
          }
          this.items = [...this.items,...response.data.results];
          }
          });
      } else {
        this.$axios
          .get(
            this.base_url +
            "/candidates?page=" +
            this.currentPage +
            // "&" +
            // this.searchKey +
            // "=" +
            // this.filter,
            "&employer=" + this.employer +
            "&job=" + this.job +
            "&source=" + this.source +
            "&date_from=" + this.fromDate +
            "&date_to=" + this.toDate,
            {
              headers: { Authorization: this.token },
            }
          )
          .then((response) => {
            if(this.filter==""){
              if(this.currentPage==1){
               this.items=[]; 
              }
                  this.items = [...this.items,...response.data.results];
            }
          else{
          if(this.currentPage==1){
            this.items=[]; 
          }
          this.items = [...this.items,...response.data.results];
          }
          });
      }
    },

    viewJobDetail(id) {
      this.$router.push("/jobs/" + id);
    },
    viewJobsForm(id) {
      this.$router.push("/jobs/" + id + "/form");
    },
    activate(arg) {
      var payload = {
        id: arg.id,
      };
      const config = {
        headers: { Authorization: this.token },
      };
      this.$axios
        .post(
          this.base_url + `/jobs/${arg.check ? "activate" : "inactivate"}`,
          payload,
          config
        )
        .then((response) => {
          this.$swal(
            `${arg.check ? "Activated" : "Inactivated"}`,
            `job is now  ${
              arg.check ? "activated" : "inactivated"
            } successfully 🙂`,
            "success"
          );
        });
    },
    clearSearch() {
      this.filter = "";
      this.source = "";
      this.employer = "";
      this.job = "";
      this.fromDate = "";
      this.toDate = "";
      this.getJobList(this.currentPage, (event = ""));
    },
  },
};
</script>

<style scoped></style>
