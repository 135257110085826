<template>
  <div class="campaign-details">
    <b-card no-body>
      <b-tabs class="tabs-icons">
        <b-tab active>
          <template #title>
            <span class="material-icons-outlined mr-1"> bar_chart </span>
            Funnel Chart
          </template>
          <div class="funnel-chart">
            <FunnelChart :type="Compaign" />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="material-icons-outlined mr-1"> report </span>
            Report View
          </template>
          <div class="report-view">
            <ReportView />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import FunnelChart from "../Dashboard/FunnelChart.vue"
import ReportView from "../Dashboard/FunnerReport.vue"
export default {
  name: "SuperDashboard",
  components: {
    FunnelChart,
    ReportView,
  },
  data() {
    return {
      Compaign: "Compaign",
    }
  },
}
</script>

<style scoped></style>
