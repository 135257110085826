<template>
  <div class="my-language">
    <div class="card-title mb-2">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-inline-block">
          <h5 class="mb-0 font-weight-bold">My Language</h5>
          <p class="fs-8 m-0 text-secondary">
            Add and manage your site language
          </p>
        </div>
        <b-button size="sm" class="btn-theme" @click="createshow = true">
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> add </span>
            <span>Add Language</span>
          </span>
        </b-button>
      </div>
    </div>
    <b-modal
      id="AddLanguage"
      title="Add Language"
      v-model="createshow"
      hide-footer
    >
      <FormulateForm
        class="text-left"
        @submit="submitHandler"
        v-model="formData"
      >
        <b-row>
          <b-col md="6" xl="12">
            <FormulateInput
              type="text"
              class="mb-3"
              name="language"
              validation="required"
              placeholder="Language"
            />
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="6" xl="12" class="text-center">
            <b-button
              type="submit"
              block
              class="btn-theme btn-bold text-uppercase d-inline-block w-auto"
              >Add</b-button
            >
          </b-col>
        </b-row>
      </FormulateForm>
    </b-modal>
    <b-card class="mb-4">
      <FormulateForm
        class="text-left"
        @submit="updateHandler"
        v-model="formlanguage"
      >
        <b-row>
          <b-col md="6" xl="4">
            <b-form-group label="Default Language">
              <FormulateInput
                type="select"
                v-model="selected"
                :options="options"
                placeholder="Select an option"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col md="6" xl="4" class="text-center">
            <b-button
              type="submit"
              block
              class="btn-theme d-inline-block w-auto"
              >Update</b-button
            >
          </b-col>
        </b-row>
      </FormulateForm>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "MyLanguage",

  components: {},
  data() {
    return {
      createshow: false,
      selected: null,
      formData: {},
      formlanguage: {},
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      options: [],
    }
  },
  created() {
    this.getLanguages()
    this.getDefaultLocalization()
  },
  methods: {
    async submitHandler(data) {
      var payload = {
        language: data.language,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .post(this.base_url + "/languages/", payload, config)
        .then((response) => {
          if (response.status == 201) {
            this.$swal({
              icon: "success",
              text: "Add Language successfully 🙂",
              type: "success",
              timer: 2000,
            })
            this.createshow = false
          }
        })
        .catch((error) => {})
        .finally(() => (this.loading = false))
    },
    async updateHandler(data) {
      var payload = {
        id: this.selected,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .put(this.base_url + "/localization/default_language/", payload, config)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              icon: "success",
              text: response.data.message + " 🙂",
              type: "success",
              timer: 2000,
            })
          }
        })
        .catch((error) => {})
        .finally(() => (this.loading = false))
    },
    getLanguages() {
      this.$axios
        .get(this.base_url + "/languages", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.options = response.data.results.map((language) => ({
            value: language.id,
            label: language.language,
          }))
        })
    },
    getDefaultLocalization() {
      this.$axios
        .get(this.base_url + "/localization", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          var valObj = this.options.filter(function (elem) {
            if (elem.label == response.data.result.language) {
              return elem
            }
          })
          var language = valObj.map((language) => ({
            value: language.value,
            label: language.label,
          }))
          this.selected = language[0].value
        })
    },
  },
}
</script>
