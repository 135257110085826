<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Screening Preview</h4>
      </div>
    </div>

    <QuestionPreview />
  </div>
</template>

<script>
import QuestionPreview from "./QuestionPreview"
export default {
  name: "ScreeningQuestionPreview",

  components: {
    QuestionPreview,
  },
}
</script>

<style scoped></style>
