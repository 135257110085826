<template>
  <div class="page-maincontent">
    <CreatePartnerForm />
  </div>
</template>

<script>
import CreatePartnerForm from "./CreatePartnerForm.vue"

export default {
  loader: false,
  name: "CreatePartner",
  components: {
    CreatePartnerForm,
  },
}
</script>

<style scoped></style>
