import { render, staticRenderFns } from "./CollectionForm.vue?vue&type=template&id=7519fe5d&scoped=true&"
import script from "./CollectionForm.vue?vue&type=script&lang=js&"
export * from "./CollectionForm.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./CollectionForm.vue?vue&type=style&index=1&id=7519fe5d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7519fe5d",
  null
  
)

export default component.exports