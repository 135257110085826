<template>
  <div class="page-maincontent">
    <!-- <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          ATS <span class="mr-2 text-secondary fs-8"></span>
        </h4>
        <b-button
          size="sm"
          class="btn-theme"
          v-b-modal.addATS
          @click="createshow = true"
        >
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> add </span>
            <span>Add ATS</span>
          </span>
        </b-button>
      </div>
    </div> -->
    <!-- Upload Campaign Modal -->
    <b-modal id="addATS" title="Add ATS" hide-footer>
      <AddATS @addAts="addAts" />
    </b-modal>
    <b-card class="custom-card">
      <ATSListing />
    </b-card>
  </div>
</template>

<script>
import ATSListing from "../ATS/ATSListing.vue"
import AddATS from "../ATS/AddATS.vue"
export default {
  name: "ATS",
  components: {
    ATSListing,
    AddATS,
  },
  data() {
    return {
      createshow: false,
    }
  },
  methods: {
    successAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to add this Campaign",
        type: "success",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes Add it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal("Success", "You successfully add this Campaign", "success")
        } else {
          this.$swal("Cancelled", "Your Campaign is still intact", "info")
        }
      })
    },
    addAts(arg) {
      this.createshow = false
    },
  },
}
</script>

<style scoped></style>
