<template>
  <div class="resume">
    <b-card class="mb-4">
      <template #header>
        <h6 class="font-weight-bold mb-0">Resume</h6>
      </template>
      <b-row class="mt-3">
        <b-col md="8">
          <div class="d-flex flex-stack align-items-center mb-2">
            <div class="pdf-icon mr-3">
              <span
                class="w-50px h-100 d-flex flex-column align-items-center justify-content-center"
              >
                <img
                  class="img-fluid"
                  src="../../assets/images/pdf_icon.png"
                  alt="icon"
                />
              </span>
            </div>
            <div class="pdf-path d-flex flex-column">
              <p class="font-weight-normal text-secondary mb-0">
                Resume - {{ resumeName }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="text-md-right mt-3 mt-md-0 mb-2">
            <a :href="resume" class="porto-button" target="blank"
              ><b-button to="" class="btn-theme">
                <span class="d-flex align-items-center">
                  <span class="material-icons-outlined"> file_download </span>
                  <span>Download</span>
                </span>
              </b-button></a
            >
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Resume",

  components: {},
  props: ["candidateDetail"],
  data() {
    return {
      resumeName: "",
      resume: "",
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      id: this.$route.params.id,
    }
  },
  mounted() {
    this.getCandidateDetail()
  },
  methods: {
    getCandidateDetail() {
      this.$axios
        .get(this.base_url + "/candidates/" + this.id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.resumeName = /[^/]*$/.exec(response.data.resume)[0]
          this.resume = response.data.resume
        })
    },
  },
}
</script>

<style scoped></style>
