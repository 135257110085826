<template>
  <div class="page-maincontent">
    <NotifiactionDetail />
  </div>
</template>

<script>
import NotifiactionDetail from "./NotificationDetail.vue"
export default {
  name: "AccountDetailIndex",

  components: {
    NotifiactionDetail,
  },
}
</script>

<style scoped></style>
