<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Employer Reports</h4>
      </div>
    </div>
    <b-card no-body class="custom-card">
      <b-tabs class="tabs-icons">
        <b-tab active>
          <template #title> New Employers </template>
          <div class="new-employer p-3 p-lg-4">
            <NewEmployer />
          </div>
        </b-tab>
        <b-tab>
          <template #title> Employer Users </template>
          <div class="employer-Users p-3 p-lg-4">
            <EmployerUser />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import NewEmployer from "./NewEmployer"
import EmployerUser from "./EmployerUser"

export default {
  name: "EmployerReports",

  components: {
    NewEmployer,
    EmployerUser,
  },
}
</script>

<style scoped></style>
