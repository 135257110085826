<template>
  <div>
    <!-- Applied Candidate Name and Skill Card -->
    <b-card class="custom-card mb-3">
      <b-modal
        id="addTemplate"
        title="Choose Email Template"
        v-model="addTemplate"
      >
        <div class="form-group mt-3">
          <label>Email Template</label>
          <b-form-select
            v-model="selectedTemplate"
            :options="optionsTemplate"
            placeholder="Choose Email Template"
          ></b-form-select>
        </div>
        <template #modal-footer>
          <div class="button-action text-center w-100">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button class="btn-theme" v-on:click="successAlert">
              Submit
            </b-button>
            <b-button variant="danger" @click="$bvModal.hide('addTemplate')">
              Discard
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-row>
        <b-col xl="6">
          <b-row class="p-1 detail">
            <p class="p-0 m-0 ml-2">
              <b-icon icon="person-fill" font-scale="3.5" class="mr-2" />
            </p>
            <div class="applicant-name">
              <h4 class="text-dark font-weight-bold m-0 p-0">
                {{ candidateDetail.full_name }}
              </h4>
              <p
                class="m-0 p-0 make-pointer"
                @click="template(candidateDetail.employer_info.id)"
              >
                {{ candidateDetail.email
                }}<b-icon icon="envelope-fill" font-scale="1" class="ml-2" />
              </p>
            </div>
          </b-row>
          <b-row lg="12">
            <b-col lg="6">
              <p v-if="candidateDetail.phone_number">
                <b-icon icon="telephone-fill" font-scale="1" class="mr-2" />{{
                  candidateDetail.phone_number
                }}
              </p>
              <p v-if="candidateDetail.address">
                <b-icon icon="house-door-fill" font-scale="1" class="mr-2" />{{
                  candidateDetail.address
                }}
              </p>
            </b-col>
            <b-col lg="6">
              <p v-if="candidateDetail.role_type">
                <b-icon icon="briefcase-fill" font-scale="1" class="mr-2" />{{
                  candidateDetail.role_type
                }}
              </p>
              <p v-if="candidateDetail.preferred_work_location">
                <b-icon icon="pin-map-fill" font-scale="1" class="mr-2" />{{
                  candidateDetail.preferred_work_location
                }}
              </p>
            </b-col>
          </b-row>
        </b-col>

        <b-col xl="6" class="border-left border-dark pl-4">
          <b-row class="p-1 detail">
            <p class="p-0 m-0 ml-2">
              <b-icon icon="building" font-scale="2.5" class="mr-2" />
            </p>
            <div>
              <h4 class="text-dark font-weight-bold m-0 p-0">
                {{ candidateDetail.employer_info.name }}
              </h4>
              <p class="m-0 p-0">
                <a
                  v-if="candidateDetail.employer_info.website"
                  :href="candidateDetail.employer_info.website"
                  target="_blank"
                >
                  {{ candidateDetail.employer_info.website }}
                </a>
              </p>
            </div>
          </b-row>
          <b-row lg="12">
            <b-col lg="12">
              <p v-if="candidateDetail.employer_info.address">
                <b-icon icon="map-fill" font-scale="1" class="mr-2" />
                {{ candidateDetail.employer_info.address }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <ContentFluid :candidateDetail="candidateDetail" />
    </b-card>
  </div>
</template>

<script>
import ContentFluid from "./ContentFluid.vue"
export default {
  name: "SidebarColumn",
  components: { ContentFluid },
  props: ["candidateDetail"],
  data() {
    return {
      addTemplate: false,
      candidateId: this.$route.params.id,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      selectedTemplate: null,
      optionsTemplate: [],
    }
  },
  // async mounted() {
  //   await this.getEmailTemplate()
  // },
  methods: {
    async getEmailTemplate(id) {
      this.$axios
        .get(this.base_url + "/filter-templates?employer=" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.optionsTemplate = response.data.result.map((data) => ({
            value: data.id,
            text: data.template_key,
          }))
        })
    },
    template(id) {
      this.addTemplate = true
      this.getEmailTemplate(id)
    },
    successAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to send this email template?",
        type: "info",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes Send it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          var payload = {
            candidate: this.candidateId,
            email_template: this.selectedTemplate,
          }
          const config = {
            headers: { Authorization: this.token },
          }
          this.$axios
            .post(this.base_url + "/send-email-templates/", payload, config)
            .then((response) => {
              if (response.status == 200) {
                this.$swal(
                  "Success",
                  response.data.message + " Successfully",
                  "success"
                )
                this.addTemplate = false
              }
            })
            .catch((error) => {})
            .finally(() => (this.loading = false))
        } else {
          this.$swal("Cancelled", "Your template is still intact", "info")
        }
      })
    },
  },
}
</script>

<style scoped>
.w-50px {
  width: 50px;
}
.h-40px {
  height: 40px;
}

.detail {
  display: flex;
  align-items: center;
}
.details-icon {
  background-color: #d3d8fb;
  color: #5664d2;
  border-radius: 6px;
}

.make-pointer {
  cursor: pointer;
}
</style>
