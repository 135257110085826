<template>
  <div class="auth-body-bg">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-container fluid class="p-lg-0" v-if="loader == false">
      <b-row class="m-lg-0">
        <b-col lg="6" xl="4">
          <div
            class="authentication-page-content p-md-4 d-lg-flex align-items-lg-center min-vh-100"
          >
            <div class="w-100">
              <b-row class="justify-content-center">
                <b-col lg="12" xl="9">
                  <div class="text-left">
                    <div class="text-left mb-3">
                      <router-link to="#" class="">
                        <img
                          src="../assets/images/logo.png"
                          alt=""
                          height="48"
                          class="auth-logo"
                        />
                      </router-link>
                    </div>

                    <h4 class="font-weight-700 mt-4">Welcome To Hoarde !</h4>
                    <p class="text-muted">
                      Setup Your Account To Start Using Hoarde.
                    </p>
                  </div>
                  <p class="error" v-if="error.message">
                    {{ error.message }}
                  </p>
                  <FormulateForm
                    class="mt-3 mb-3 mt-lg-5 mb-lg-5 text-left"
                    @submit="submitHandler"
                  >
                    <FormulateInput
                      type="text"
                      class="mb-3"
                      name="username"
                      validation="required"
                      placeholder="Username"
                      validation-name="User Name"
                    />
                    <FormulateInput
                      type="password"
                      class="mb-3"
                      name="password"
                      validation="required"
                      placeholder="Password"
                      validation-name="Password"
                    />
                    <FormulateInput
                      type="text"
                      class="mb-3"
                      name="first_name"
                      placeholder="First Name"
                      validation-name="First Name"
                    />
                    <FormulateInput
                      type="text"
                      class="mb-3"
                      name="last_name"
                      placeholder="Last Name"
                      validation-name="Last Name"
                    />

                    <FormulateInput
                      type="text"
                      class="mb-3"
                      name="email"
                      :value="id"
                      readonly
                    />
                    <b-form-group>
                      <VuePhoneNumberInput
                        v-model="phoneNumber"
                        name="phoneNumber"
                        @update="onUpdate"
                      />
                      <p class="error" v-if="error.phoneNumber">
                        {{ error.phoneNumber }}
                      </p>
                    </b-form-group>
                    <b-button
                      type="submit"
                      block
                      size="lg"
                      class="btn-theme btn-bold w-100 text-uppercase"
                      >Submit Here</b-button
                    >
                  </FormulateForm>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col lg="6" xl="8">
          <div class="authentication-bg">
            <div class="bg-overlay"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script src="http://code.jquery.com/ui/1.12.1/jquery-ui.min.js"></script>
<script>
import VuePhoneNumberInput from "vue-phone-number-input"
import "vue-phone-number-input/dist/vue-phone-number-input.css"
export default {
  name: "login",
  components: {
    VuePhoneNumberInput,
  },

  data() {
    return {
      loader: false,
      phoneNumber: null,
      phoneIsValid: false,
      token: "Bearer " + localStorage.getItem("jwt"),
      id: this.$route.params.email,
      base_url: process.env.VUE_APP_BASE_URL,
      form: {
        email: "",
        password: "",
        username: "",
        first_name: "",
        last_name: "",
      },
      error: {
        message: "",
        phoneNumber: "",
      },
    }
  },

  methods: {
    onUpdate(payload) {
      this.phoneIsValid = payload.isValid

      if (this.phoneNumber != null) {
        if (payload.isValid == false) {
          this.error.phoneNumber = "Phone Number is wrong"
        } else {
          this.error.phoneNumber = ""
        }
      }
    },
    checkAccountExistOrNot() {
      this.loader = true
      this.$axios
        .get(this.base_url + "/setup-account/" + this.id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          if (response.data.status_code == 200) {
            this.createshow = false
            this.$router.push("/404")
          }
        })
        .catch((error) => {
          this.loader = false
          if (error.response.data.status_code == 400) {
            this.$router.push("/setup-account/" + this.id)
          }
          if (error.response.data.status_code == 404) {
            this.$router.push("/404")
          }
        })

        .finally(() => (this.loading = false))
    },
    async submitHandler(data) {
      var payload = {
        email: data.email,
        password: data.password,
        username: data.username,
        first_name: data.first_name,
        last_name: data.last_name,
        phone_number: this.phoneNumber,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      if (this.phoneIsValid == true) {
        this.$axios
          .put(this.base_url + "/setup-account/" + this.id, payload, config)
          .then((response) => {
            if (response.data.status == false) {
              this.error.email = response.data.message
            }
            if (response.status == 200) {
              this.$swal({
                icon: "success",
                text: "User is now activated successfully 🙂",
                type: "success",
              }).then((result) => {
                if (result.value) {
                  this.$router.push("/login")
                }
              })
            }
            if (response.data.status_code == 400) {
              this.error.message = response.data.message
            }
          })
          .catch((error) => {
            this.error.message = error.response.data.message
          })
          .finally(() => (this.loading = false))
      }
    },
  },
  created() {
    this.checkAccountExistOrNot()
  },
}
</script>

<style scoped>
.authentication-bg {
  background-image: url(../assets/images/authentication-bg1.jpg);
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.authentication-bg .bg-overlay {
  background-color: #292626;
}
.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.4;
  background-color: #000;
}
.min-vh-100 {
  min-height: 100vh;
}
.authentication-page-content {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.error {
  color: red;
}
@media screen and (max-width: 767px) {
  .authentication-page-content {
    height: auto;
  }
  .min-vh-100 {
    min-height: auto !important;
  }
  .authentication-bg {
    height: 30vh;
    margin-top: 20px;
  }
}
.spinn-loader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
</style>
