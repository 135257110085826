<template>
  <div class="create-user">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <p class="error" v-if="error.message">
      {{ error.message }}
    </p>
    <p class="error" v-if="error.email">
      {{ error.email }}
    </p>
    <FormulateForm class="text-left" @submit="submitHandler" v-model="formData">
      <div>
        <FormulateInput
          type="group"
          name="userData"
          :repeatable="true"
          add-label="+ Add More"
          validation="required"
        >
          <CreateMultipleUser
            :isHidden="isHidden"
            :formData="formData"
            @active="activate"
          />
        </FormulateInput>
      </div>
      <b-row class="d-flex justify-content-end px-2">
        <b-button variant="danger" @click="clearFormData"> Clear </b-button>
        <FormulateInput
          type="submit"
          class="btn-theme-custom btn-bold text-uppercase"
          label="Submit"
        />
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import axios from "axios"
import CreateMultipleUser from "../../components/CreateMultiplePartnerUser"

export default {
  name: "CreateUser",
  components: {
    CreateMultipleUser,
  },
  props: ["createdPartnerId"],
  data() {
    return {
      loader: false,
      input: this.createdPartnerId,
      id: this.$route.params.id,
      isHidden: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      roleoptions: [],
      newRoleOptions: [],
      formData: {},
      error: {
        message: "",
        email: "",
      },
    }
  },

  methods: {
    activate(arg) {
      this.isHidden = arg.isHidden
    },
    clearFormData() {
      this.formData.userData = ""
    },

    async submitHandler(data) {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      var userTypeId = parseData.type_id
      this.loader = true
      var partner = this.input
      if (this.input != "") {
        if (this.userTypeId != "1") {
          var partner = this.input
        } else {
          var partner = 1
        }
      } else {
        var partner = 1
      }

      var payload = this.formData.userData.map((data) => ({
        email: data.email,
        role: data.role,
        type: 3,
        employer: null,
        partner: partner,
      }))
      const config = {
        headers: { Authorization: this.token },
      }
      axios
        .post(this.base_url + "/bulk-invite-user/", payload, config)
        .then((response) => {
          this.loader = false
          if (response.data.status == 400) {
            this.error.message = response.data.message
            this.error.email = response.email[0]
          }
          if (response.status == 201) {
            this.$router.push("/partners")
          }
        })
        .catch((error) => {
          this.loader = false
          this.error.message = error.response.data.message
          this.error.email = error.response.data[0].email
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped>
.error {
  color: red;
}
select[multiple] {
  height: 150px;
  overflow: auto;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.delete-btn {
  margin-bottom: -20px;
}
.spinn-loader {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
</style>
