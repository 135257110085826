<template>
  <div class="job-listing">
    <b-card class="custom-card">
      <ListLayout />
    </b-card>
  </div>
</template>

<script>
import ListLayout from "./ListLayout.vue"

export default {
  name: "JobsList",

  components: {
    ListLayout,
  },
  data() {
    return {
      layout: "list",
    }
  },
  methods: {
    toggleLayout: function (layout) {
      this.layout = layout
    },
  },
}
</script>

<style scoped></style>
