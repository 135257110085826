<template>
  <b-card class="custom-card" v-if="!isHidden">
    <div class="d-flex justify-content-between">
      <div class="tutorial-link text-right">
        <a
          href="javascript:;"
          class="font-weight-bold text-decoration-underline"
          id="tutorialVideo"
          @click="showModal"
          >Tutorial</a
        >
      </div>
      <div class="tutorial-link text-right">
        <b-button @click="closeModal">
          <span class="material-icons-outlined"> close </span>
        </b-button>
      </div>
    </div>
    <div class="create-job text-center">
      <div class="job-icon">
        <span class="material-icons-outlined md-100 text-blue-light">
          work_outline
        </span>
      </div>
      <div class="create-job-text mt-3">
        <h3 class="text-center text-secondary">
          You do not have any <br />
          active job!
        </h3>
      </div>
      <div class="create-job-text mt-5">
        <b-button @click="checkEmployerConnectedToAts" variant="success">
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> add </span>
            <span class="text-uppercase">Create a Job</span>
          </span>
        </b-button>
        <b-modal
          size="lg"
          ref="tutorialVideo"
          hide-header
          hide-footer
          body-class="p-0"
        >
          <b-embed
            type="iframe"
            aspect="16by9"
            src="https://www.youtube.com/embed/WRC7y8VriuM"
            allowfullscreen
          ></b-embed>
          <b-link class="close-btn" @click="hideModal">
            <span class="material-icons-outlined"> close </span>
          </b-link>
        </b-modal>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "CreateJobCard",
  data() {
    return {
      isHidden: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },
  mounted() {
    this.setRoute()
  },
  methods: {
    showModal() {
      this.$refs["tutorialVideo"].show()
    },
    hideModal() {
      this.$refs["tutorialVideo"].hide()
    },
    closeModal() {
      this.isHidden = true
    },

    setRoute() {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      parseData.hasOwnProperty("role")
      var role = null
      if (parseData.hasOwnProperty("role")) {
        role = parseData.role
      }
      if (parseData.hasOwnProperty("role_id")) {
        role = parseData.role_id
      }

      // var role = parseData.role;
      var employer = parseData.employer
      var type = parseData.type

      if (role == 1 && employer == null) {
        this.$router.push("/dashboard")
      } else {
        this.$router.push("/")
      }
    },
    checkEmployerConnectedToAts() {
      this.$axios
        .get(this.base_url + "/ats/?company=" + 1, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          if (response.data.result.connected_ats_count == 0) {
            this.$router.push("/create-job")
          } else {
            this.$router.push("/create-job-type")
          }
        })
    },
  },
}
</script>
<style scoped>
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
}
</style>
