<template>
  <div class="page-maincontent">
    <AppliedDetails />
  </div>
</template>

<script>
import AppliedDetails from "./AppliedDetails.vue"
export default {
  name: "CandidateDetail",

  components: {
    AppliedDetails,
  },
}
</script>

<style scoped></style>
