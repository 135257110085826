<template>
  <div class="position-relative z-index-8">
    <VuePhoneNumberInput />
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input"
import "vue-phone-number-input/dist/vue-phone-number-input.css"

export default {
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      phoneNumber: null,
    }
  },
}
</script>
<style scoped>
.z-index-8 {
  z-index: 8;
}
</style>
