<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Email Template</h4>
        <b-button to="/create-email-template" size="sm" class="btn-theme" >
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> add </span>
            <span>Create</span>
          </span>
        </b-button>
      </div>
    </div>
    <TemplateListing />
  </div>
</template>

<script>
import TemplateListing from "./TemplateListing"
export default {
  name: "EmailTemplateBuilder",
  components: {
    TemplateListing,
  },
}
</script>

<style scoped></style>
