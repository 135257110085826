<template>
  <div class="partner-user-details">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Partner Employers</h4>

        <div class="button-action">
          <b-button class="btn-theme" @click="createEmployerUser = true">
            <span class="d-flex align-items-center">
              <span class="material-icons-outlined"> add </span>
              <span>Create New</span>
            </span>
          </b-button>
        </div>
      </div>
    </div>
    <!-- Create New User Modal -->
    <b-modal
      v-model="createEmployerUser"
      id="createEmployerUser"
      title="Create Partner Employer"
      hide-footer
    >
      <CreateEmployerUser :createdPartnerId="id" />

      <template #modal-footer>
        <div class="w-100 button-action">
          <b-button class="btn-theme" size="" v-on:click="createUserModal">
            Submit
          </b-button>
          <b-button
            variant="danger"
            size=""
            @click="createEmployerUser = false"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Edit  User Modal -->
    <b-modal id="edit-user" v-model="editshow" title="Edit User">
      <EditUser />

      <template #modal-footer>
        <div class="w-100 button-action">
          <b-button class="btn-theme" size=""> Submit </b-button>
          <b-button variant="danger" size="" @click="editshow = false">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-card class="custom-card">
      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-form-group
            label="Search"
            label-for="filter-input"
            label-cols-sm="3"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="ml-auto search-filter"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        :items="items"
        :fields="fields"
        stacked="md"
        :per-page="perPage"
        show-empty
        :filter="filter"
        :filter-included-fields="filterOn"
        small
        responsive="lg"
        outlined
        hover
        head-variant="light"
        ref="partnerUserList"
      >
        <template #cell(actions)="items">
          <div class="d-flex align-items-center">
            <b-link
              @click="employerUsers(items.item.id)"
              class="mr-2 mt-2 text-warning"
            >
              <span class="material-icons-outlined"> visibility </span>
            </b-link>

            <div class="d-inline-block">
              <toggle-switch
                :values="items.item.status === 'active'"
                @active="activate"
                :id="items.item.id"
                :comment="'employer'"
              ></toggle-switch>
            </div>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="pages"
            :per-page="perPage"
            base-url="#"
            class="my-0 ml-auto float-right"
          ></b-pagination-nav>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import CreateEmployerUser from "../PartnerUsers/CreateEmployerUser.vue"
import EditUser from "../PartnerUsers/EditUser.vue"
import ToggeComp from "../../components/ToggeComp.vue"
export default {
  name: "PartnerUsersList",
  components: {
    CreateEmployerUser,
    EditUser,
    "toggle-switch": ToggeComp,
  },
  data() {
    return {
      createEmployerUser: false,
      editshow: false,
      id: this.$route.params.id,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      items: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Employer Name", sortable: true },
        { key: "email", label: "Email" },
        { key: "phone_number", label: "Phone Number" },
        { key: "employee_count", label: "Users Count" },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      sortBy: "",
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  mounted() {
    this.getPartnersUsers(this.currentPage)
  },
  methods: {
    employerUsers(id) {
      this.$router.push("/employer-users/" + id)
    },
    linkGen(pageNum) {
      this.currentPage = pageNum
      return pageNum === currentPage ? "?" : `?page=${pageNum}`
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    createUserModal() {
      this.$swal(
        "Added Successfully",
        "You are successfully added this user",
        "OK"
      )
    },
    getPartnersUsers(currentPage) {
      this.$axios
        .get(this.base_url + "/partners/" + this.id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.currentPage = response.data.current_page
          this.items = response.data.employer
        })
    },
    activate(arg) {
      var payload = {
        id: arg.id,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .post(
          this.base_url +
            `/employers/${arg.check ? "activate" : "inactivate"}/`,
          payload,
          config
        )
        .then((response) => {
          if (response.status_code == 400) {
            this.Swal.fire("Oops!", error.response.data.message, "error")
          } else {
            this.$swal(
              `${arg.check ? "Activated" : "Inactivated"}`,
              `Employer is now ${
                arg.check ? "activated" : "inactivated"
              } successfully 🙂`,
              "success"
            )
          }
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data.message, "error")
          window.location.reload()
        })
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getPartnersUsers(value)
      },
    },
  },
}
</script>

<style scoped></style>
