<template>
  <div class="auth-body-bg">
    <b-container fluid class="p-lg-0">
      <b-row class="m-lg-0">
        <b-col lg="6" xl="4">
          <div
            class="authentication-page-content p-md-4 d-lg-flex align-items-lg-center min-vh-100"
          >
            <div class="w-100">
              <b-row class="justify-content-center">
                <b-col lg="12" xl="9">
                  <div class="text-left">
                    <div class="text-left mb-3">
                      <router-link to="#" class="">
                        <img
                          src="../assets/images/logo.png"
                          alt=""
                          height="48"
                          class="auth-logo"
                        />
                      </router-link>
                    </div>

                    <h4 class="font-weight-700 mt-4">Welcome Back !</h4>
                    <p class="text-muted">Sign in to continue to hoarde</p>
                  </div>
                  <FormulateForm
                    class="mt-3 mb-3 mt-lg-5 mb-lg-5 text-left"
                    @submit="submitHandler"
                  >
                    <p class="error" v-if="error.message">
                      {{ error.message }}
                    </p>
                    <FormulateInput
                      type="text"
                      class="mb-3"
                      name="email"
                      validation="required"
                      placeholder="Email"
                    />
                    <FormulateInput
                      type="password"
                      class="mb-3"
                      name="password"
                      validation="required"
                      placeholder="Password"
                    />
                    <b-form-group id="input-group-3" label-for="input-3">
                      <div class="d-flex justify-content-between">
                        <b-form class="font-weight-600"
                          ></b-form
                        >
                        <router-link
                          to="/forgot-password"
                          class="font-weight-600"
                          >Forgot Password</router-link
                        >
                      </div>
                    </b-form-group>
                    <b-button
                      type="submit"
                      block
                      size="lg"
                      class="btn-theme btn-bold w-100 text-uppercase"
                      >Login</b-button
                    >
                  </FormulateForm>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col lg="6" xl="8">
          <div class="authentication-bg">
            <div class="bg-overlay"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: {
        message: "",
      },
      base_url: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    removeLocalStorageOnLoad: function () {
      localStorage.removeItem("user")
      localStorage.removeItem("jwt")
      localStorage.removeItem("unloadTime")
    },
    async submitHandler(data) {
      var payload = {
        email: data.email,
        password: data.password,
      }
      this.$axios
        .post(this.base_url + "/login/", payload)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.result.mfa_form == true) {
              localStorage.setItem("user", JSON.stringify(response.data.result))
              localStorage.setItem("jwt", response.data.result.access_token)
              var now = new Date().getTime()
              localStorage.setItem("setupTime", now)
              this.$router.push("/mfa-auth")
            } else {
              localStorage.setItem("user", JSON.stringify(response.data.result))
              localStorage.setItem("jwt", response.data.result.access_token)
              var now = new Date().getTime()
              localStorage.setItem("setupTime", now)
              var userDetails = localStorage.getItem("user")
              var parseData = JSON.parse(userDetails)
              parseData.hasOwnProperty("role")
              var is_admin = null
              var type = null
              if (parseData.hasOwnProperty("role")) {
                is_admin = response.data.result.role
                type = response.data.result.type
              }
              if (parseData.hasOwnProperty("role_id")) {
                is_admin = response.data.result.role_id
                type = response.data.result.type_id
              }
              if (localStorage.getItem("jwt") != null) {
                // this.$emit("loggedIn");
                if (this.$route.params.nextUrl != null) {
                  this.$router.push(this.$route.params.nextUrl)
                } else {
                  if (is_admin == 1 && type == 1) {
                    this.$router.push("/dashboard")
                  } else {
                    this.$router.push("/")
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          this.error.message = error.response.data.detail
        })
        .finally(() => (this.loading = false))
    },
  },
  created: function () {
    this.removeLocalStorageOnLoad()
  },
}
</script>

<style scoped>
.authentication-bg {
  background-image: url(../assets/images/authentication-bg.jpg);
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.authentication-bg .bg-overlay {
  background-color: #292626;
}
.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.4;
  background-color: #000;
}
.min-vh-100 {
  min-height: 100vh;
}
.authentication-page-content {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.error {
  color: red;
}
@media screen and (max-width: 767px) {
  .authentication-page-content {
    height: auto;
  }
  .min-vh-100 {
    min-height: auto !important;
  }
  .authentication-bg {
    height: 30vh;
    margin-top: 20px;
  }
}
</style>
