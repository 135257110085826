<template>
  <div class="employer-details">
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Notifiaction Details</h4>
      </div>
    </div>

    <!-- Edit Privacy Policy Modal -->

    <b-card class="custom-card">
      <b-row>
        <b-col sm="12" md="12" lg="6" xl="6" class="my-1">
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Log Entry Action Time</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="text-secondary">{{ log_entry_action_time }}</h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Log Entry Object Repr</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="text-secondary">
                {{ notificationsDetails.log_entry_object_repr }}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Log Entry</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="text-secondary">
                {{ notificationsDetails.log_entry }}
              </h6>
            </b-col>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Log Entry User</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="text-secondary">
                {{ notificationsDetails.log_entry_user }}
              </h6>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6" class="my-1">
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Log Entry Action Flag</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="">
                {{ notificationsDetails.log_entry_action_flag }}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Log Entry Content Type</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="">
                {{ notificationsDetails.log_entry_content_type }}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Log Entry Id</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="">
                {{ notificationsDetails.log_entry_id }}
              </h6>
            </b-col>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Log Entry Object Id</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="">
                {{ notificationsDetails.log_entry_object_id }}
              </h6>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="12" class="my-1">
          <b-row>
            <b-col sm="12" md="12" lg="12" class="my-1">
              <h6 class="font-weight-bold">Log Entry Change Message</h6>
            </b-col>
            <b-col sm="12" md="12" lg="12" class="my-1">
              <h6 class="text-secondary">
                {{ notificationsDetails.log_entry_change_message }}
              </h6>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import moment from "moment"
export default {
  name: "EmployerUsersDetails",

  components: {},

  data() {
    return {
      notificationsDetails: [],
      log_entry_action_time: "",
      id: this.$route.params.id,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },

  mounted() {
    this.getNotificationDetail(this.id)
  },
  methods: {
    getNotificationDetail(id) {
      this.$axios
        .get(this.base_url + "/notifications/" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.notificationsDetails = response.data
          this.log_entry_action_time = moment(
            response.data.log_entry_action_time
          ).format("MMMM D, YY")
        })
    },
  },
  watch: {
    $route(to, from) {
      const id = to.params.id
      this.getNotificationDetail(id)
    },
  },
}
</script>

<style scoped></style>
