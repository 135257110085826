<template>
  <div id="app" :class="{ sidebartoggle: toggleClass }" class="vertical-menu">
    <div class="simplebar-wrapper allow-scroll">
      <div id="sidebar-menu">
        <b-navbar-nav
          vertical
          class="metismenu list-unstyled"
          id="side-menu"
          v-if="role == 1 && employer == ''"
        >
          <b-nav-item
            to="/"
            exact
            active-class="active"
            @click="activate(1)"
            :class="{ activenav: active_el == 1 }"
          >
            <i class="material-icons-outlined"> space_dashboard </i>
            <span>Dashboard</span>
          </b-nav-item>

          <!-- Navbar dropdowns -->
          <b-nav-item
            to="/employers"
            exact
            active-class="active"
            @click="activate(1)"
            :class="{ activenav: active_el == 1 }"
          >
            <i class="material-icons-outlined"> business </i>
            <span>Employers</span>
          </b-nav-item>
          <b-nav-item
            to="/partners"
            exact
            active-class="active"
            @click="activate(1)"
            :class="{ activenav: active_el == 1 }"
          >
            <i class="material-icons-outlined"> handshake </i>
            <span>Partners</span>
          </b-nav-item>
          <b-nav-item
            to="/jobs"
            exact
            active-class="active"
            @click="activate(1)"
            :class="{ activenav: active_el == 1 }"
          >
            <i class="material-icons-outlined"> work_outline </i>
            <span>Jobs</span>
          </b-nav-item>
          <b-nav-item
            to="/candidates"
            exact
            active-class="active"
            @click="activate(5)"
            :class="{ activenav: active_el == 5 }"
          >
            <i class="material-icons-outlined"> people </i>
            <span>Candidates</span>
          </b-nav-item>

          <b-nav-item
            to="/campaign"
            @click="activate(6)"
            :class="{ activenav: active_el == 6 }"
          >
            <i class="material-icons-outlined"> campaign </i>
            <span>Campaigns</span>
          </b-nav-item>

          <b-nav-item-dropdown
            variant="link"
            class="justify-content-left custom-dropdown"
            toggle-class="text-decoration-none"
            @show="activate(7)"
            :class="{ activenav: active_el == 7 }"
          >
            <template #button-content exact-active-class="active">
              <i class="material-icons-outlined"> report </i>
              <span>Reports</span>
            </template>
            <b-dropdown-item to="/employer-reports"
              >Employer Reports</b-dropdown-item
            >
            <b-dropdown-item to="/partner-reports"
              >Partner Reports</b-dropdown-item
            >
            <b-dropdown-item to="/applicant-reports"
              >Applicant Reports</b-dropdown-item
            >
            <b-dropdown-item to="/job-reports">Job Reports </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            variant="link"
            class="justify-content-left custom-dropdown"
            toggle-class="text-decoration-none"
            @show="activate(8)"
            :class="{ activenav: active_el == 8 }"
          >
            <template #button-content exact-active-class="active">
              <i class="material-icons-outlined"> settings </i>
              <span>Settings</span>
            </template>
            <!-- <b-dropdown-item to="/roles">Assign Role</b-dropdown-item> -->
            <b-dropdown-item to="/permission"
              >Assign Permission</b-dropdown-item
            >
            <b-dropdown-item to="/ats"> ATS </b-dropdown-item>
            <b-dropdown-item to="/email-template-builder"
              >Email Template Builder</b-dropdown-item
            >
            <b-dropdown-item to="/localization"> Localization </b-dropdown-item>
            <b-dropdown-item to="/screening-questions"
              >Screening Questions</b-dropdown-item
            >
            <b-dropdown-item to="/security"> Security </b-dropdown-item>

            <b-dropdown-item to="/employer-users" exact active-class="active"
              >Users</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav
          vertical
          class="metismenu list-unstyled"
          id="side-menu"
          v-if="role != 1 && employer != ''"
        >
          <b-nav-item
            to="/index"
            exact
            active-class="active"
            @click="activate(1)"
            :class="{ activenav: active_el == 1 }"
          >
            <i class="material-icons-outlined"> space_dashboard </i>
            <span>Dashboard</span>
          </b-nav-item>

          <b-nav-item
            to="/applicants"
            exact
            active-class="active"
            @click="activate(2)"
            :class="{ activenav: active_el == 2 }"
          >
            <i class="material-icons-outlined"> people </i>
            <span>Talent</span>
          </b-nav-item>

          <b-nav-item-dropdown
            variant="link"
            class="justify-content-left custom-dropdown"
            toggle-class="text-decoration-none"
            @show="activate(3)"
            :class="{ activenav: active_el == 3 }"
          >
            <template #button-content>
              <i class="material-icons-outlined"> work_outline </i>
              <span>Jobs</span>
            </template>
            <b-dropdown-item
              active-class="active"
              @click="checkEmployerConnectedToAts"
              >Create job</b-dropdown-item
            >
            <b-dropdown-item to="/jobs" exact active-class="active"
              >Total Jobs</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item
            to="/campaign"
            exact
            active-class="active"
            @click="activate(4)"
            :class="{ activenav: active_el == 4 }"
          >
            <i class="material-icons-outlined"> campaign </i>
            <span>Campaigns</span>
          </b-nav-item>

          <b-nav-item-dropdown
            variant="link"
            class="justify-content-left custom-dropdown"
            toggle-class="text-decoration-none"
            @show="activate(5)"
            :class="{ activenav: active_el == 5 }"
          >
            <template #button-content exact-active-class="active">
              <i class="material-icons-outlined"> report </i>
              <span>Reports</span>
            </template>
            <b-dropdown-item to="/applicant-reports"
              >Applicant Reports</b-dropdown-item
            >
            <b-dropdown-item to="/job-reports">Job Reports </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            variant="link"
            class="justify-content-left custom-dropdown"
            toggle-class="text-decoration-none"
            @show="activate(6)"
            :class="{ activenav: active_el == 6 }"
          >
            <template #button-content>
              <i class="material-icons-outlined"> settings </i>
              <span>Administrator</span>
            </template>
            <b-dropdown-item
              @click="getAuthEmplyerUsers"
              exact
              active-class="active"
              >Users</b-dropdown-item
            >
            <b-dropdown-item to="/permission" exact active-class="active"
              >Assign Permission</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    toggleClass: {
      type: Boolean,
    },
  },
  data() {
    return {
      active_el: 0,
      role: null,
      partner: null,
      employer: "",
      authUserId: null,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },
  mounted() {
    this.setRoute()
  },
  methods: {
    setRoute() {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      var role = null
      if (parseData.hasOwnProperty("role")) {
        role = parseData.role
      }
      if (parseData.hasOwnProperty("role_id")) {
        role = parseData.role_id
      }
      var employer = parseData.employer
      var partner = parseData.partner
      if (role == 1 && employer == null) {
        this.role = role
        this.employer = ""
      } else {
        this.role = role
        this.employer = employer
        this.partner = partner
        if (partner == null) {
          this.authUserId = parseData.employer
        } else {
          this.authUserId = parseData.partner
        }
      }
    },
    checkEmployerConnectedToAts() {
      this.$axios
        .get(this.base_url + "/ats/?company=" + 1, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          if (response.data.result.connected_ats_count == 0) {
            this.$router.push("/create-job")
          } else {
            this.$router.push("/create-job-type")
          }
        })
    },
    activate(el) {
      this.active_el = el
    },
    getAuthEmplyerUsers() {
      if (this.partner == null) {
        this.$router.push("/employer-users/" + this.authUserId)
      } else {
        this.$router.push("/partner-users/" + this.authUserId)
      }
    },
  },
}
</script>

<style scoped>
.allow-scroll {
  overflow: scroll;
  height: 100%;
}
.allow-scroll::-webkit-scrollbar {
  display: none;
}
</style>
