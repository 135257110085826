<template>
  <b-card class="custom-card">
    <h3 class="font-weight-noraml">
      Welcome, <span class="d-inline text-secondary">{{ username }}</span>
    </h3>
    <p class="text-secondary mb-0">
      Please add a job first with click, the
      <span class="font-weight-bold text-dark">“CREATE A JOB”</span> button
      below
    </p>
  </b-card>
</template>

<script>
export default {
  name: "WelcomeCard",
  data() {
    return {
      username: "",
    }
  },
  methods: {
    createUserName() {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      this.username = parseData.name
    },
  },
  created: function () {
    this.createUserName()
  },
}
</script>
