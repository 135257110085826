<template>
  <div class="employer-user-details">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">User List</h4>

        <div class="button-action">
          <b-button
            class="btn-theme"
            v-b-modal.create-user
            @click="createshow = true"
          >
            <span class="d-flex align-items-center">
              <span class="material-icons-outlined"> add </span>
              <span>Create New</span>
            </span>
          </b-button>
        </div>
      </div>
    </div>
    <!-- Create New User Modal -->
    <b-modal
      id="create-user"
      v-model="createshow"
      title="Create New User"
      hide-footer
    >
      <CreateEmployerUser
        :CreatedEmployerId="CreatedEmployerId"
        :userTypeId="userTypeId"
        @createShowModelHide="createShowModelHide"
      />

      <template #modal-footer>
        <div class="w-100 button-action">
          <b-button class="btn-theme" size="" v-on:click="createUserModal">
            Submit
          </b-button>
          <b-button variant="danger" size="" @click="createshow = false">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Edit  User Modal -->
    <b-modal id="edit-user" v-model="editshow" title="Edit User">
      <EditUser />

      <template #modal-footer>
        <div class="w-100 button-action">
          <b-button class="btn-theme" size=""> Submit </b-button>
          <b-button variant="danger" size="" @click="editshow = false">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-card class="custom-card">
      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-form-group
            label="Search"
            label-for="filter-input"
            label-cols-sm="3"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="ml-auto search-filter"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="text"
                placeholder="Type to Search"
                @keyup="getEmployerUsers(currentPage, $event.target.value)"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="clearSearch(1), (filter = '')"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
         show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        small
        responsive="lg"
        outlined
        hover
        head-variant="light"
        ref="employerUserList"
        :items-provider="getEmployerUsers"
      >
        <template #cell(actions)="items">
          <div class="d-flex align-items-center">
            <b-link
              @click="getUserDetail(items.item.id)"
              class="mr-2 mt-2 text-warning"
            >
              <span class="material-icons-outlined"> visibility </span>
            </b-link>

            <div class="d-inline-block">
              <toggle-switch
                :values="items.item.status === 'active'"
                @active="activate"
                :id="items.item.id"
                :comment="'user'"
              ></toggle-switch>
            </div>
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
            class="my-0 ml-auto float-right"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>
import CreateEmployerUser from "../EmployerUsers/CreateEmployerUser.vue";
import EditUser from "../EmployerUsers/EditUser.vue";
import ToggeComp from "../../components/ToggeComp.vue";
export default {
  name: "EmployerUsersList",
  components: {
    CreateEmployerUser,
    EditUser,
    "toggle-switch": ToggeComp,
  },
  data() {
    return {
      createshow: false,
      editshow: false,
      id: this.$route.params.id,
      userTypeId: null,
      CreatedEmployerId: null,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      items: [],
      fields: [
        { key: "id", label: "ID",sortable: true },
        { key: "username", label: "User Name",sortable: true },
        { key: "email", label: "User Email",sortable: true },
        { key: "phone_number", label: "Phone Number",sortable: true },

        { key: "role", label: "Users Roles",sortable: true },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      filter: "",
      totalItems: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.CreatedEmployerId = this.id;
    this.getEmployerUsers(this.currentPage, (event = ""));
  },
  methods: {
    getUserDetail(id) {
      this.$router.push("/users/" + id);
    },
    createUserModal() {
      this.$swal(
        "Added Successfully",
        "You are successfully added this user",
        "OK"
      );
    },
    getEmployerUsers(currentPage, event = "") {
      var userDetails = localStorage.getItem("user");
      var parseData = JSON.parse(userDetails);
      this.userTypeId = parseData.type_id;
      this.$axios
        .get(
          this.base_url +
            "/users?employer=" +
            this.id +
            "&page=" +
            this.currentPage +
            "&keyword_search=" +
            this.filter,
          {
            headers: { Authorization: this.token },
          }
        )
        .then((response) => {
          // this.currentPage = response.data.current_page;
           if(this.filter==""){
            if(this.currentPage==1){
               this.items=[]; 
              }
            this.items = [...this.items,...response.data.results];
          }
          else{
           if(this.currentPage==1){
               this.items=[]; 
              }
              this.items = [...this.items,...response.data.results];
          }
           this.totalItems = response.data.result_count;
        });
    },
    activate(arg) {
      var payload = {
        id: arg.id,
      };
      const config = {
        headers: { Authorization: this.token },
      };
      this.$axios
        .post(
          this.base_url + `/users/${arg.check ? "activate" : "inactivate"}/`,
          payload,
          config
        )
        .then((response) => {
          this.$swal(
            `${arg.check ? "Activated" : "Inactivated"}`,
            `User is now ${
              arg.check ? "activated" : "inactivated"
            } successfully 🙂`,
            "success"
          );
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data.message, "error");
          window.location.reload();
        });
    },
    clearSearch() {
      this.filter = "";
      this.items=[];
      this.currentPage=1;
      this.getEmployerUsers(this.currentPage, (event = ""));
    },
    createShowModelHide(arg) {
      this.createshow = false;
      this.getEmployerUsers(this.currentPage, (event = ""));
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getEmployerUsers(value);
      },
    },
  },
};
</script>

<style scoped></style>
