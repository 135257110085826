<template>
  <div class="job-details">
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center just0ify-content-sm-between">
        <h4 class="mb-sm-0">Job Details</h4>
      </div>
    </div>

    <b-card class="custom-card mb-3">
      <template #header>
        <b-row>
          <b-col>
            <b-button to="/jobs" size="sm" variant="secondary">
              <span class="d-flex align-items-center">
                <span class="material-icons-outlined"> chevron_left </span>
                <span>Back</span>
              </span>
            </b-button>
          </b-col>
          <b-col>
            <div class="text-right">
              <b-button v-on:click="gotoUpdateJob" class="btn-theme">
                <span class="d-flex align-items-center">
                  <span class="material-icons-outlined"> edit </span>
                  <span>Edit Job</span>
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
      <b-card class="mb-3">
        <template #header>
          <b-row class="align-items-center">
            <b-col>
              <router-link to="" class="text-dark d-block">
                <h3 class="employer-name font-weight-600">{{ jobsDetails.name }}</h3>
                <!-- <p class="employer-tagline mb-0">
                  {{ jobsDetails.name }}
                </p> -->
              </router-link>
            </b-col>
            <b-col>
              <div class="text-right">
                <div class="posted-on mr-3">
                  <span class="font-weight-bold text-secondary">Post Date</span>
                  <span class="font-weight-bold text-secondary px-1">:</span>
                  <span class="font-weight-normal text-secondary">{{
                    postDate
                  }}</span>
                </div>
                <div class="symbol-group symbol-hover justify-content-end mt-3">
                  <div
                    class="applied-text font-weight-bold mr-3 text-secondary"
                  >
                    Share this job
                  </div>
                  <b-link
                    hreif="https://twitter.com/"
                    class="symbol-social symbol-28px mr-2"
                    target="_blank"
                  >
                    <img alt="Pic" src="../../assets/images/twitter.png" />
                  </b-link>
                  <router-link
                    to="https://www.facebook.com/"
                    class="symbol-social symbol-28px mr-2"
                    target="_blank"
                  >
                    <img alt="Pic" src="../../assets/images/facebook.png" />
                  </router-link>
                  <router-link
                    to="https://www.linkedin.com/signup"
                    class="symbol-social symbol-28px mr-2"
                    target="_blank"
                  >
                    <img alt="Pic" src="../../assets/images/linkedin.png" />
                  </router-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
        <b-row class="align-items-center">
          <b-col>
            <p class="employer-location d-flex align-items-center mb-1">
              <span class="material-icons-outlined mr-2">place</span>
              <span class="mr-2">{{ jobsDetails.location }}</span>
            </p>
            <p class="employer-job-type d-flex align-items-center mb-1">
              <span class="material-icons-outlined mr-2">schedule</span>
              Full Time
            </p>
          </b-col>
          <b-col>
            <h6 class="font-weight-bold mb-0">Employer</h6>
            <p class="employer-job-type mb-0">
              {{ jobsDetails.employer }}
            </p>
          </b-col>
          <b-col>
            <h6 class="font-weight-bold mb-0">ATS</h6>
            <p class="employer-job-type mb-0">
              {{ jobsDetails.ats }}
            </p>
          </b-col>
          <b-col>
            <h6 class="font-weight-bold mb-0">Employment Type</h6>
            <p class="employer-job-type mb-0">
              {{ jobsDetails.employment_type }}
            </p>
          </b-col>
          <b-col>
            <h6 class="font-weight-bold mb-0">Job Status</h6>
            <div class="d-block">
              <p class="text-success font-weight-bold mb-0">
                <span class="active-notify"></span>
                <span class="ml-2">{{ jobsDetails.status }}</span>
              </p>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-tabs no-body>
        <b-tabs v-model="tabIndex">
          <b-tab href="#job-summary">
            <template #title> Job Summary </template>
            <div class="jobs-summary">
              <JobSummary :jobsDetail="jobsDetail" />
            </div>
          </b-tab>
          <b-tab href="#no-of-count">
            <template #title> No. of Applicants </template>
            <div class="jobs-applicants">
              <JobApplicants />
            </div>
          </b-tab>
        </b-tabs>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import moment from "moment"
import JobSummary from "../JobDetail/JobSummary.vue"
import JobApplicants from "../JobDetail/JobApplicants.vue"
export default {
  name: "Details",
  components: {
    JobSummary,
    JobApplicants,
  },

  data() {
    return {
      postDate: "",
      id: this.$route.params.id,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      jobsDetails: [],
      jobsDetail: {
        description: "",
        skills: "",
        experience: "",
        allDetail: [],
      },
      tabIndex: 0,
      tabs: ["#summary", "#applicants"],
    }
  },

  mounted() {
    this.getJobDetail(this.id)
  },
  created() {
    this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.hash)
  },
  methods: {
    gotoUpdateJob() {
      this.$router.push("/update-job/" + this.id)
    },
    getJobDetail(id) {
      this.$axios
        .get(this.base_url + "/jobs/" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.jobsDetails = response.data
          this.postDate = moment(this.jobsDetails.created_at).format(
            "YYYY-MM-DD"
          )
          let regex = /(<([^>]+)>)/gi
          this.jobsDetail.description = this.jobsDetails.description.replace(
            regex,
            ""
          )
          this.jobsDetail.skills = this.jobsDetails.skill.replace(regex, "")
          this.jobsDetail.experience = this.jobsDetails.experience.replace(
            regex,
            ""
          )

          this.jobsDetail.allDetail = this.jobsDetails
        })
    },
  },
}
</script>

<style scoped>
.employer-name {
  font-size: 24px;
  color: #212529;
}
.employer-tagline {
  font-size: 14px;
}
.employer-location,
.employer-job-type,
.employer-tagline {
  color: #5f5f5f;
}
.employer-location,
.employer-job-type,
.employer-location .material-icons-outlined,
.employer-job-type .material-icons-outlined {
  font-size: 16px;
}
.active-notify {
  background-color: #28a745;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 100px;
}
</style>
