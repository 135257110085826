<template>
  <div class="compaign">
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Campaign</h4>
        <div class="compaign-filter">
          <b-form inline>
            <b-form-select
              class="ml-sm-2"
              v-model="filterselected"
              :options="filteroptions"
              size="sm"
            ></b-form-select>
            <b-form-select
              class="ml-sm-2"
              v-model="viewselected"
              :options="viewoptions"
              size="sm"
            ></b-form-select>
          </b-form>
        </div>
      </div>
    </div>
    <b-card no-body class="custom-card">
      <b-tabs class="tabs-icons">
        <b-tab active>
          <template #title>
            <span class="material-icons-outlined mr-1"> bar_chart </span>
            Funnel Chart
          </template>
          <div class="funnel-chart">
            <FunnelChart />
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="material-icons-outlined mr-1"> report </span>
            Report View
          </template>
          <div class="report-view">
            <ReportView />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import FunnelChart from "./FunnelChart"
import ReportView from "./FunnerReport"
export default {
  name: "Compaign",
  components: {
    FunnelChart,
    ReportView,
  },

  data() {
    return {
      filterselected: null,
      viewselected: null,
      filteroptions: [
        { value: null, text: "All Adverts" },
        { value: "aa", text: "Facebook" },
        { value: "ab", text: "Instagram" },
        { value: "ac", text: "Linkedin" },
      ],
      viewoptions: [
        { value: null, text: "View as" },
        { value: "va", text: "%" },
        { value: "vb", text: "$" },
        { value: "vc", text: "Value" },
      ],
    }
  },
}
</script>

<style scoped>
@media screen and (max-width: 576px) {
  .compaign-filter form {
    display: flex;
    flex-flow: inherit;
    justify-content: space-between;
  }
}
</style>
