<template>
  <div class="create-job">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Job Form</h4>
      </div>
    </div>
    <b-card class="custom-card">
      <FormulateForm
        class="text-left"
        @submit="submitHandler"
        v-model="formData"
      >
        <div
          class="col-lg-12"
          v-for="(article, index) in form_fields"
          :key="index"
        >
          <b-row class="mt-4">
            <b-col lg="6" xl="6" v-if="article[0] && article[0].type == 'file'">
              <FormulateInput
                v-if="article[0].required == true"
                :label="article[0].label"
                :type="article[0].type"
                :name="article[0].name"
                validation="mime:application/pdf|required"
                @change="fileUpload()"
              >
              </FormulateInput>
              <FormulateInput
                v-else
                :label="article[0].label"
                :type="article[0].type"
                :name="article[0].name"
                validation="mime:application/pdf"
                @change="fileUpload()"
              >
              </FormulateInput>
              <p class="error" v-if="error.resume">
                {{ error.resume }}
              </p>
            </b-col>
            <b-col
              lg="6"
              xl="6"
              v-if="article[0] && article[0].type == 'dropdown'"
            >
              <FormulateInput
                :label="article[0].label"
                type="select"
                :options="article[0].choices"
                :name="article[0].name"
                placeholder="Select an option"
                validation="required"
              >
              </FormulateInput>
            </b-col>
            <b-col lg="6" xl="6" v-else>
              <FormulateInput
                v-if="article[0].required == true"
                :label="article[0].label"
                :type="article[0].type"
                :name="article[0].name"
                validation="required"
              >
              </FormulateInput>
              <FormulateInput
                v-else
                :label="article[0].label"
                :type="article[0].type"
                :name="article[0].name"
                :id="article[0].name"
              >
              </FormulateInput>
            </b-col>
            <b-col lg="6" xl="6" v-if="article[1] && article[1].type == 'file'">
              <FormulateInput
                v-if="article[1].required == true"
                :label="article[1].label"
                :type="article[1].type"
                :name="article[1].name"
                @change="fileUpload()"
                validation="mime:application/pdf|required"
              >
              </FormulateInput>
              <FormulateInput
                v-else
                :label="article[1].label"
                :type="article[1].type"
                :name="article[1].name"
                @change="fileUpload()"
                validation="mime:application/pdf"
              >
              </FormulateInput>
              <p class="error" v-if="error.address">
                {{ error.address }}
              </p>
            </b-col>
            <b-col
              lg="6"
              xl="6"
              v-if="article[1] && article[1].type == 'dropdown'"
            >
              <FormulateInput
                :label="article[1].label"
                type="select"
                :options="article[1].choices"
                :name="article[1].name"
                placeholder="Select an option"
                validation="required"
              >
              </FormulateInput>
            </b-col>
            <b-col lg="6" xl="6" v-else-if="article[1]">
              <FormulateInput
                v-if="article[1].required == true"
                :label="article[1].label"
                :type="article[1].type"
                :name="article[1].name"
                validation="required"
              >
              </FormulateInput>
              <FormulateInput
                v-else
                :label="article[1].label"
                :type="article[1].type"
                :name="article[1].name"
              >
              </FormulateInput>
            </b-col>
            <b-col lg="6" xl="6" v-else> </b-col>
          </b-row>
        </div>
        <FormulateInput
          type="submit"
          class="btn-theme-custom btn-bold text-uppercase mt-4"
          label="Apply"
        />
      </FormulateForm>
    </b-card>
  </div>
</template>
<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css"
export default {
  name: "CreateJobForm",
  data() {
    return {
      loader: false,
      input: null,
      checked: true,
      selected: null,
      status: true,
      employers: [],
      observer: null,
      limit: 20,
      page: 1,
      totalPage: 3,
      search: "",
      countries: ["dsd", "ds"],
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      id: this.$route.params.id,
      file: "",
      formData: {
        job: 668,
        first_name: "",
        last_name: "",
        email: "",
        country_code: "",
        experience: {
          title: "",
          company: null,
          current: null,
          startDate: null,
          endDate: null,
          location: null,
          description: null,
        },
        phone_number: null,
        country: null,
        region: null,
        region_code: null,
        city: null,
        skype: null,
        linkedin: null,
        facebook: null,
        twitter: null,
        website: null,
        preferred_work_location: null,
        role_type: null,
        resume: "",
        cover_letter: "",
        education: {
          institution: "",
          degree: null,
          major: null,
          current: false,
          location: null,
          startDate: null,
          endDate: null,
          description: null,
        },
        privacy_policy_accepted: true,
      },
      form_fields: [],
      error: {
        resume: "",
      },
    }
  },
  methods: {
    fileUpload() {
      if (this.formData.resume) {
        const file = document.querySelector("input[type=file]").files[0]
        const reader = new FileReader()
        let rawImg
        reader.onloadend = () => {
          rawImg = reader.result
          this.file = rawImg
        }
        reader.readAsDataURL(file)
      }
    },
    getJobFormFields() {
      this.$axios
        .get(this.base_url + "/job-form?job_id=" + this.id, {})
        .then((response) => {
          console.log(response)
          this.form_fields = response.data.result.form_fields
          this.required_fields = response.data.result.required_fields
          const res = []
          while (response.data.result.length > 0) {
            const chunk = response.data.result.splice(0, 2)
            res.push(chunk)
          }
          this.form_fields = res
        })
      //TODO: NEED Error catching
    },

    submitHandler(data) {
      this.loader = true
      var payload = {
        job: this.id,
        first_name: this.formData.first_name,
        last_name: this.formData.last_name,
        email: this.formData.email,
        country_code: this.formData.country_code,
        experience: {
          title: this.formData.experience.title,
          company: this.formData.experience.company,
          current: this.formData.experience.current,
          startDate: this.formData.experience.startDate,
          endDate: this.formData.experience.endDate,
          location: this.formData.experience.location,
          description: this.formData.experience.description,
        },
        phone_number: this.formData.phone_number,
        country: this.formData.country,
        region: this.formData.region,
        region_code: this.formData.region_code,
        city: this.formData.city,
        skype: this.formData.skype,
        linkedin: this.formData.linkedin,
        facebook: this.formData.facebook,
        twitter: this.formData.twitter,
        website: this.formData.website,
        preferred_work_location: this.formData.preferred_work_location,
        role_type: this.formData.role_type,
        resume: this.file,
        cover_letter: this.formData.cover_letter,
        education: {
          institution: this.formData.education.institution,
          degree: this.formData.education.degree,
          major: this.formData.education.major,
          current: this.formData.education.current,
          location: this.formData.education.location,
          startDate: this.formData.education.startDate,
          endDate: this.formData.education.endDate,
          description: this.formData.education.description,
        },
        privacy_policy_accepted: this.formData.privacy_policy_accepted,
      }
      // const config = {
      //   headers: { Authorization: this.token },
      // }
      this.$axios
        .post(this.base_url + "/create-candidate/", payload)
        .then((response) => {
          if (response.status == 201) {
            this.loader = false
            this.$swal("Success", "You successfully added 🙂", "success")
            this.formData.first_name = ""
            this.formData.last_name = ""
            this.formData.email = ""
            this.formData.country_code = ""
            this.formData.experience.title = ""
            this.formData.experience.company = null
            this.formData.experience.current = null
            this.formData.experience.startDate = null
            this.formData.experience.endDate = null
            this.formData.experience.location = null
            this.formData.experience.description = null
            this.formData.phone_number = null
            this.formData.country = null
            this.formData.region = null
            this.formData.resume = ""
            this.formData.region_code = null
            this.formData.city = null
            this.formData.skype = null
            this.formData.linkedin = null
            this.formData.facebook = null
            this.formData.twitter = null
            this.formData.website = null
            this.formData.cover_letter = ""
            this.formData.education = ""
            this.formData.education.institution = ""
            this.formData.education.degree = null
            this.formData.education.major = null
            this.formData.education.current = null
            this.formData.education.location = null
            this.formData.education.startDate = null
            this.formData.education.endDate = null
            this.formData.education.description = null
          }
        })
        .catch((error) => {
          this.loader = false
          // if (error.response.resume[0]) {
          //   this.error.resume = "Please Select Only pdf File.";
          // }
          // if (error.response.data.name) {
          //   this.error.name = error.response.data.name[0];
          // }
          // if (error.response.data.logo) {
          //   this.error.logo = error.response.data.logo[0];
          // }
        })
        .finally(() => (this.loading = false))
    },

    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag,
      }
      this.options.push(tag)
    },
  },
  watch: {
    search() {
      this.page = 1
      this.limit = 20
      this.employers = []
      this.getEmployers()
    },
  },
  mounted() {},
  created: function () {
    this.getJobFormFields()
  },
  computed: {},
}
</script>

<style scoped>
.spinn-loader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
</style>
