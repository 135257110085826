<template>
  <div class="filter-header border-bottom p-3">
    <b-row>
      <b-col lg="6" xl="5" class="my-1">
        <b-form-group
          label="Filter By:"
          label-for="filter-by"
          label-cols-sm="3"
          label-cols-lg="2"
          class="mb-0"
        >
          <v-select
            :options="paginated"
            :filterable="false"
            :reduce="(option) => option.value"
            label="label"
            @open="onOpen"
            @close="onClose"
            @search="(query) => (search = query)"
            class="mb-3"
            placeholder="Select Job"
            id="emp"
            v-on:input="sendFilterByJob($event)"
            name="job"
          >
            <template #list-footer>
              <li v-show="hasNextPage" ref="load" class="loader"></li>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" xl="2" class="my-1">
        <b-form-select
          v-model="filterBySource"
          :options="optionsFilterBySource"
          @change="sendFilterBySource"
        ></b-form-select>
      </b-col>
      <b-col lg="6" xl="4" class="my-1">
        <b-form-group
          label="Filter By Date"
          label-for="filter-date"
          label-cols-sm="3"
          label-cols-lg="3"
          class="mb-0"
        >
          <b-input-group>
            <b-form-datepicker
              placeholder="From"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }"
              v-model="fromDate"
              @input="sendFilterByFromDate"
            ></b-form-datepicker>
            <b-form-datepicker
              placeholder="To"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }"
              v-model="toDate"
              @input="sendFilterByToDate"
            ></b-form-datepicker>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" xl="4" class="my-1"> </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "FilterHeader",
  data() {
    return {
      filterByJob: null,
      filterBySource: "",
      currentPage: 1,
      optionsFilterByJob: [],
      filterByJobList: [],
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      optionsFilterBySource: [{ value: "", text: "Select Job Source..." }],
      observer: null,
      limit: 20,
      page: 1,
      totalPage: 3,
      search: "",
      countries: ["dsd", "ds"],
      pages: null,
      fromDate: "",
      toDate: "",
    }
  },
  created() {
    this.getApplicants()
    this.getSourceList()
  },
  methods: {
    activate(event, keyName) {
      this.form_fields[keyName] = event
    },
    setRequired(event, keyName) {
      this.required_fields[keyName] = event
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    async setPage() {
      this.page = this.page + 1
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 20
        this.page += 1
        await this.getApplicants(this.page)
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    sendFilterByJob(event) {
      const _this = this
      _this.$emit("sendFilter", { filter: event })
    },
    sendFilterBySource() {
      const _this = this
      _this.$emit("sendFilter", { filter: this.filterBySource })
    },
    sendFilterByToDate() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.toDate,
        searchkey: "created_at_before",
      })
    },
    sendFilterByFromDate() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.fromDate,
        searchkey: "created_at_after",
      })
    },
    async getApplicants(currentPage, event = "") {
      if (this.page <= this.pages || this.page == 1) {
        this.$axios
          .get(this.base_url + "/candidates?page=" + this.page, {
            headers: { Authorization: this.token },
          })
          .then((response) => {
            this.pages = response.data.total_pages
            this.filterByJobList = [
              ...this.filterByJobList,
              ...response.data.results.map((data) => ({
                value: data.job,
                label: data.job,
              })),
            ]

            const values = this.filterByJobList.map((result) => result.value)
            const filtered = this.filterByJobList.filter(
              ({ value }, index) => !values.includes(value, index + 1)
            )
            this.optionsFilterByJob = filtered
          })
      }
    },
    getSourceList() {
      this.$axios
        .get(this.base_url + "/filter-sources", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          const aa = response.data.result.map((result) =>
            this.optionsFilterBySource.push({
              value: result.name,
              text: result.name,
            })
          )
        })
    },
  },
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  watch: {
    search() {
      this.page = 1
      this.limit = 20
      this.optionsFilterByJob = []
      this.getApplicants()
    },
  },
  computed: {
    filtered() {
      return this.optionsFilterByJob
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return true
    },
    formDataLength() {
      return this.formData(this.formData, "1")
    },
  },
}
</script>
<style scoped>
.ats-box,
.manual-box {
  background-color: #f5f5f5;
  padding: 16px 40px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.ats-box.active,
.manual-box.active {
  background-color: #007bff;
  border: 1px solid #007bff;
  color: #fff;
}
.error {
  color: red;
}
select[multiple] {
  height: 150px;
  overflow: auto;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.delete-btn {
  margin-bottom: -20px;
}
</style>
