<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Security</h4>
      </div>
    </div>
    <b-card no-body class="custom-card">
      <b-tabs class="tabs-icons">
        <b-tab active>
          <template #title>
            <span class="material-icons-outlined mr-1"> lock </span>
            Password
          </template>
          <div class="password-settings p-2 p-md-4">
            <div class="card-title">
              <h5 class="mb-0 font-weight-bold">Password</h5>
            </div>
            <b-card class="">
              <PasswordSettings />
            </b-card>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="material-icons-outlined mr-1"> schedule </span>
            Logs
          </template>
          <div class="report-view p-2 p-md-4">
            <b-card no-body class="border-0">
              <Logs />
              <LoginSessions />
            </b-card>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="material-icons-outlined mr-1"> no_encryption </span>
            MFA
          </template>
          <div class="report-view p-2 p-md-4">
            <b-card no-body class="border-0">
              <MFA />
            </b-card>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="material-icons-outlined mr-1"> pin </span>
            IP Wightlist
          </template>
          <div class="report-view p-2 p-md-4">
            <b-card no-body class="border-0">
              <IPWightlist />
            </b-card>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import PasswordSettings from "./PasswordSettings"
import Logs from "./Logs"
import LoginSessions from "./LoginSessions"
import MFA from "./MFA"
import IPWightlist from "./IPWightlist"
export default {
  name: "Security",

  components: {
    PasswordSettings,
    Logs,
    LoginSessions,
    MFA,
    IPWightlist,
  },
}
</script>

<style scoped></style>
