<template>
   <div class="assessment"> 
     <!-- This Component for AmChart Library --> 
        <!-- <AmChartLibrary/>  -->

        <b-card class="mb-4">
          <template #header>
              <h6 class="font-weight-bold mb-0"> Resume</h6>
          </template>
          <b-row class="mt-3">
              <b-col md="4">
                <div class="mark-obtain-chart text-center">
                 
                  <apexchart ref="radialBarChart" type="radialBar" height="230" width="230" :options="skillChart" :series="series2"></apexchart>
              
                </div>
              </b-col>
              <b-col md="4">
                <div class="mark-obtain-chart text-center">
                 
                  <apexchart type="radialBar" height="230" width="230"  :options="experiencechart " :series="series3"></apexchart>
                 
                </div>
              </b-col>
              <b-col md="4">
                <div class="mark-obtain-chart text-center">
                  
                  <apexchart type="radialBar" height="230" width="230"  :options="totalchart " :series="series4"></apexchart>
                  
                </div>
              </b-col>
          </b-row>
        </b-card>
        <b-card class="mb-4">
          <template #header>
              <h6 class="font-weight-bold mb-0"> Assessment</h6>
          </template>
          <div class="bg-light p-3">
            <b-row class="align-items-center">
              <b-col>
                <div class="assesment-name">
                  <h6 class="font-weight-bold mb-0"> Assessment</h6>
                </div>
                <div class="assesment-status mt-2">
                  <b-button variant="success" size="sm" class="px-4" v-if="QA_assessment.status=='Complete'">{{ QA_assessment.status }}</b-button>
                  <b-button variant="danger" size="sm" class="px-4" v-else>{{ QA_assessment.status }}</b-button>
                </div>  
              </b-col>
             
          </b-row>
          </div>
          <b-row class="mt-3 align-items-center">
              <b-col md="6">
                <b-list-group>
                  <b-list-group-item>
                    <p class="text-secondary m-0">
                    <span class="d-inline-block mr-2">Total Question :</span>
                    <span class="d-inline-block font-weight-bold ml-2">{{ QA_assessment.total_questions }}</span>
                  </p>
                  </b-list-group-item>
                  <b-list-group-item>
                      <p class="text-secondary m-0">
                      <span class="d-inline-block mr-2">Total Answered : </span>
                      <span class="d-inline-block font-weight-bold ml-2">{{ QA_assessment.total_answered }}</span>
                    </p>
                  </b-list-group-item>
                  <b-list-group-item>
                    <p class="text-secondary m-0">
                      <span class="d-inline-block mr-2">Expired Question : </span>
                      <span class="d-inline-block font-weight-bold ml-2">{{ QA_assessment.expired }}</span>
                    </p>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
              <b-col md="6">
                <div class="mark-obtain-chart text-center" >
                  <apexchart type="radialBar" height="230" width="230" :options="chartAssessment " :series="series"></apexchart>
                </div>
              </b-col>
          </b-row>
       </b-card>
       <b-card class="mb-4">
          <template #header>
              <h6 class="font-weight-bold mb-0"> 
                Screening Questions
              </h6>
          </template>
          <b-card class="mb-3 bg-light" v-for="q in questions" v-bind:key=(q.id)>
            <div class="scr-que position-relative">
              <div class="que-name">
                <h6 class="font-weight-bold position-relative que-name-text m-0">{{q.que_name}}</h6>
              </div>
              <div class="que-desc mt-2 pl-4">
                <p class="text-secondary m-0"><strong>Question: </strong>
                  {{q.que_desc}}
                </p>
                <p class="text-secondary m-0">
                  {{q.answer}}
                </p>
              </div>
            </div>
          </b-card>
       </b-card>
  </div> 
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'Assessment',

  components:{
    apexchart: VueApexCharts,
  },

  data: function() {
    return {
          base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      id: this.$route.params.id,
      QA_assessment:[],
      // screening_QA:[],
      questions:[],
      series: [],
      series2: [],
      series3: [],
      series4: [],
      chart: {
        id: 'chart',
      },
      chartAssessment: {
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%',
            }
          },
        },
        labels: ['Score'],
        fill: {
          colors: ['#147AD6']
        }
      },
      skillChart: {
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%',  
            }
          },
        },
        labels: ['Skills'],
        fill: {
          colors: ['#147AD6', ]
        }
      },
      experiencechart: {
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%',
            }
          },
        },
        labels: ['Experience'],
        fill: {
          colors: ['#EC6666']
        },
      },
      totalchart: {
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%',
            }
          },
        },
        labels: ['Total'],
        fill: {
          colors: ['#60CF83'],
        }
      },
  }
    
  },
 mounted() {
    this.getCandidateDetail();
  },
  methods: {
    getCandidateDetail() {
      this.$axios
        .get(this.base_url + "/candidates/" + this.id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
        const percentage = response.data.QA_assessment.percentage.slice(0, -1);
        const skill = response.data.resume_assessment.skill.slice(0, -1);
        const experience = response.data.resume_assessment.experience.slice(0, -1);
        const total = response.data.resume_assessment.total.slice(0, -1);
        
        this.series=[percentage];
        this.series2=[skill];
        this.series3=[experience];
        this.series4=[total];
        this.QA_assessment=response.data.QA_assessment;
        // this.screening_QA=response.data.screening_QA;
         this.questions = this.questions = [
            ...this.questions,
            ...response.data.screening_QA[0].GENERAL.map((data,key) => ({
              id: key,
              que_name:data.category,
              que_desc:data.question,
              answer:"Answer: "+data.answer
            })),
          ];
        });
    },
    
  },
 
   
}
</script>

<style scoped>

/* #skillchart, #experiencechart #totalchart{
  height: 120px;
  width: 120px;
} */
.que-name-text{
  padding-left:30px;
}
.que-name-text:before{
  content: "";
  width: 16px;
  height: 16px;
  background-color: #6C68A7;
  display: inline-block;
  border-radius: 100px;
  position: absolute;
  left: 0;
  top: 4px;
}
.checkmark {
  position: absolute;
  top:0;
  right:0;
}
.checkmark-success > span{
  color: #00CD00;
}
.mark-obtain-chart .apexcharts-canvas{
  margin: 0 auto;
}
</style>
