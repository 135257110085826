<template>
  <div class="update-password">
    <FormulateForm
      class="mt-3 mb-3 mt-lg-5 mb-lg-5 text-left"
      @submit="submitHandler"
      v-model="formData"
    >
      <p class="error" v-if="error.message">
        {{ error.message }}
      </p>
      <FormulateInput
        type="password"
        class="mb-3"
        name="old_password"
        validation="required"
        placeholder=""
        label="Current Password"
        :validation-messages="{
          required: 'Old Password field is required',
        }"
      />
      <FormulateInput
        type="password"
        class="mb-3"
        name="password"
        validation="required"
        placeholder=""
        label="New Password"
        :validation-messages="{
          required: 'New Password field is required',
        }"
      />
      <FormulateInput
        type="password"
        class="mb-3"
        name="password_confirm"
        validation="required|confirm"
        validation-name="Confirmation"
        placeholder=""
        label="Confirm Password"
        :validation-messages="{
          required: 'Confirm Password field is required',
        }"
      />

      <b-row class="d-flex justify-content-end px-3">
        <b-button class="btn-theme-clear mr-2" @click="clearFormData">Clear</b-button>
        <FormulateInput
          type="submit"
          class="btn-theme-custom btn-bold text-uppercase"
          label="Submit"
        />
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
export default {
  name: "UpdatePassword",
  components: {},
  data() {
    return {
      accountDetail: [],
      formData: {},
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      error: {
        message: "",
      },
    }
  },
  mounted() {
    this.getLogedInUserDetail()
  },
  methods: {
    clearFormData() {
      this.formData.old_password = ""
      this.formData.password = ""
      this.formData.password_confirm = ""
    },
    getLogedInUserDetail() {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      this.accountDetail = parseData
    },
    async submitHandler(data) {
      var employer = this.input

      var payload = {
        old_password: data.old_password,
        new_password: data.password,
        repeat_new_password: data.password_confirm,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .put(this.base_url + "/change-password/", payload, config)
        .then((response) => {
          if (response.data.status == 400) {
            this.error.message = response.data.message
          }
          if (response.status == 200) {
            this.$swal({
              icon: "success",
              text: response.data.message + "🙂",
              type: "success",
              //   timer: 2000,
            }).then((result) => {
              if (result.value) {
                const _this = this
                _this.$emit("changePassword", { createshow: false })
              }
            })
          }
        })
        .catch((error) => {
          this.error.message = error.response.data.message
          this.error.email = error.response.data[0].email[0]
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped></style>
