<template>
  <div class="page-title-box my-2">
    <b-row class="align-items-center">
      <b-col>
        <h4 class="font-weight-noraml mb-md-0">
          Welcome, <span class="d-inline text-secondary">{{ username }}</span>
        </h4>
      </b-col>
      <b-col>
        <div class="text-sm-right">
                    <b-dropdown
            text="Create Job"
            id="dropdown-buttons" right 
            variant="link"
            size="sm"
            toggle-class="text-decoration-none text-white"
            class="btn-theme"
          >
          <b-dropdown-item @click="showModal">
            <span> Collection Fields </span>
          </b-dropdown-item>
          <b-dropdown-item to="/create-job/">
            <span> Create Manually </span>
          </b-dropdown-item>
          <b-dropdown-item to="/import-from-ats/">
            <span> Import from ATS </span>
          </b-dropdown-item>
          </b-dropdown>

          <b-modal
            ref="my-modal"
            hide-footer
            title="Create job collection fields"
            size="xl"
          >
            <div class="d-block text-center">
              <CollectionForm :hideModal="hideModal" />
            </div>
          </b-modal>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CollectionForm from "../Jobs/CollectionForm.vue"
export default {
  name: "PageHeader",
  components: {
    CollectionForm
  },
  data() {
    return {
      username: "",
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },
  methods: {
    createUserName() {
      var hours = 9
      var now = new Date().getTime()
      var setupTime = localStorage.getItem("setupTime")
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear()
        this.$router.push("/login")
      }
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      this.username = parseData.name
    },
    checkEmployerConnectedToAts() {
      // var userDetails = localStorage.getItem("user");
      // var parseData = JSON.parse(userDetails);
      // var id = parseData.user;
      this.$axios
        .get(this.base_url + "/ats/?company=" + 1, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          if (response.data.result.connected_ats_count == 0) {
            this.$router.push("/create-job")
          } else {
            this.$router.push("/create-job-type")
          }
        })
    },
    showModal() {
      console.log(this.$refs)
      this.$refs["my-modal"].show()
    },
    hideModal() {
      this.$refs["my-modal"].hide()
    },
  },
  created: function () {
    this.createUserName()
  },
}
window.onbeforeunload = function (e) {
  window.localStorage.unloadTime = JSON.stringify(new Date())
}

// window.onload = function () {
//   let loadTime = new Date();
//   let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
//   let refreshTime = loadTime.getTime() - unloadTime.getTime();

//   if (refreshTime > 3600000) {
//     //3000 milliseconds
//     window.localStorage.removeItem("jwt");
//     window.localStorage.removeItem("user");
//   }
// };
</script>

<style scoped></style>
