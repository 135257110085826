<template>
  <div class="upload-campaign">
    <FormulateForm class="text-left" @submit="onSubmit" v-model="form">
      <b-form-group>
        <FormulateInput
          name="source"
          :options="sourceOptions"
          type="select"
          label="Source"
          placeholder="Select an option"
          validation=""
          validation-name="Question Category"
        />
      </b-form-group>
      <b-form-group>
        <v-select
          :options="paginated"
          :filterable="false"
          :reduce="(option) => option.value"
          label="label"
          @open="onOpen"
          @close="onClose"
          @search="(query) => (search = query)"
          class="mb-3"
          placeholder="Select Employer"
          id="emp"
          v-model="input"
        >
          <template #list-footer>
            <li v-show="hasNextPage" ref="load" class="loader"></li>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <FormulateInput
          type="file"
          name="file"
          label="Select your documents to upload"
          validation="required"
          single
          @change="changebaseFormat"
        />
      </b-form-group>
      <div id="preview" v-if="fileName">
        {{ fileName }}
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <FormulateInput
            type="submit"
            class="btn-theme-custom btn-bold text-uppercase"
            label="Submit"
          />
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
export default {
  name: "UploadCampaign",
  components: {},
  data() {
    return {
      fileName: "",
      input: "",
      form: {
        file: "",
      },
      file1: null,
      url: "",
      sourceSelected: null,
      jobSelected: null,
      sourceOptions: [],
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      jobOptions: [],
      observer: null,
      limit: 20,
      page: 1,
      totalPage: 3,
      search: "",
      countries: ["dsd", "ds"],
    }
  },
  methods: {
    changebaseFormat(e) {
      const file = e.target.files[0]
      this.fileName = file.name
      this.createBase64FormatImage(file)
    },
    createBase64FormatImage(file) {
      const reader = new FileReader()
      let rawImg
      reader.onload = (e) => {
        this.form.file = reader.result
      }
      reader.readAsDataURL(file)
      this.url = URL.createObjectURL(file)
    },
    getSourceList() {
      this.$axios
        .get(this.base_url + "/filter-sources", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.sourceOptions = response.data.result.map((result) => ({
            label: result.name,
            value: result.id,
          }))
        })
    },
    activate(event, keyName) {
      this.form_fields[keyName] = event
    },
    setRequired(event, keyName) {
      this.required_fields[keyName] = event
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    async setPage() {
      this.page = this.page + 1
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 20
        this.page += 1
        await this.getJobs(this.page)
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },

    getJobs(page) {
      var employer = this.input
      if (this.page <= this.totalPage) {
        this.$axios
          .get(this.base_url + "/jobs?page=" + this.page + "&keyword_search=", {
            headers: { Authorization: this.token },
          })
          .then((response) => {
            this.totalPage = response.data.totalPage
            this.jobOptions = [
              ...this.jobOptions,
              ...response.data.results.map((data) => ({
                value: data.id,
                label: data.name,
              })),
            ]
          })
      }
    },
    onSubmit(event) {
      var payload = {
        source: this.form.source,
        job: this.input,
        file: this.form.file,
      }
      const config = {
        headers: { Authorization: this.token },
      }

      this.$axios
        .post(this.base_url + "/upload-campaign/", payload, config)
        .then((response) => {
          const _this = this
          _this.$emit("createShowModelHide", { createshow: false })
          if (response.status == 200) {
            this.$swal({
              icon: "success",
              text: "Employer Updated successfully 🙂",
              type: "success",
              timer: 1500,
            })
          }
        })
        .catch((error) => {})
        .finally(() => (this.loading = false))
    },
  },
  created() {
    this.getSourceList()
    this.getJobs(this.page)
  },
  watch: {
    search() {
      this.page = 1
      this.limit = 20
      this.jobOptions = []
      this.getJobs()
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },

  computed: {
    filtered() {
      return this.jobOptions
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return true
    },
    formDataLength() {
      return this.formData(this.formData, "1")
    },
  },
}
</script>

<style scoped></style>
