<template>
  <div class="logs">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="mb-0 font-weight-bold">IP Wightlist</h5>
        <b-button
          size="sm"
          class="btn-theme"
          v-b-modal.AddWightlist
          @click="createshow = true"
        >
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> add </span>
            <span>Add New</span>
          </span>
        </b-button>
      </div>
    </div>
    <b-modal
      id="AddWightlist"
      title="Add IP Address"
      hide-footer
      v-model="createshow"
    >
      <AddIPAddress @addShowModelHide="addShowModelHide" />
      <template #modal-footer>
        <div class="button-action text-center w-100">
          <b-button class="btn-theme"> Submit </b-button>
          <b-button variant="danger" @click="$bvModal.hide('AddWightlist')">
            Discard
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-table
      show-empty
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
      :items-provider="whitelistSecurity"
    >
      <template #cell(actions)="items">
        <div class="d-flex align-items-center">
          <b-link
            @click="updateShow(items.item.id)"
            class="mr-2 mt-2 text-warning"
          >
            <span class="material-icons-outlined"> edit </span>
          </b-link>

          <b-link class="text-danger" v-on:click="errorAlert(items.item.id)">
            <span class="material-icons-outlined"> delete </span>
          </b-link>
        </div>
      </template>
    </b-table>

    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
        <b-form-group
          label-for="per-page-select"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="perpage-select"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
          class="my-0 ml-auto float-right"
        ></b-pagination>
      </b-col>
      <!-- Create New User Modal -->
      <b-modal
        id="update-ipwight-list"
        v-model="updateshow"
        title="Update IP Wight List"
        hide-footer
      >
        <UpdateIPAddress
          :ipWightListId="ipWightListId"
          @updateShowModelHide="updateShowModelHide"
        />

        <template #modal-footer>
          <div class="w-100 button-action">
            <b-button class="btn-theme" size="" v-on:click="createUserModal">
              Submit
            </b-button>
            <b-button variant="danger" size="" @click="createshow = false">
              Cancel
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-row>
  </div>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>
import ToggeComp from "../../components/ToggeComp.vue";
import AddIPAddress from "./AddIPAddress";
import UpdateIPAddress from "./UpdateIPAddress";

export default {
  name: "IPWightlist",
  components: {
    AddIPAddress,
    UpdateIPAddress,
    "toggle-switch": ToggeComp,
  },
  data() {
    return {
      createshow: false,
      updateshow: false,
      ipWightListId: null,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      items: [],
      checked: true,
      unchecked: false,
      fields: [
        { key: "id", label: "ID" },
        { key: "ip_address", label: "IP Address" },
        { key: "time", label: "Time" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Auth Status" },
      ],
      totalRows: null,
      pages: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      sortBy: "",
      filter: "",
      totalItems: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items

    this.whitelistSecurity(this.currentPage, (event = ""));
  },
  methods: {
    addShowModelHide(arg) {
      this.createshow = false;
      this.whitelistSecurity(this.currentPage, (event = ""));
    },
    updateShowModelHide(arg) {
      this.updateshow = false;
      this.whitelistSecurity(this.currentPage, (event = ""));
    },
    updateShow(id) {
      this.ipWightListId = id;
      this.updateshow = true;
    },
    
    whitelistSecurity(currentPage, event = "") {
      this.$axios
        .get(this.base_url + "/whitelist-security?page=" + this.currentPage, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          if(this.currentPage==1){
          this.items=[]; 
        }
        this.items = [...this.items,...response.data.results];
        this.current_page = response.data.current_page;
        this.pages = response.data.total_pages;
        this.totalItems = response.data.result_count;
        });
    },
    activate(arg) {
      var payload = {
        id: arg.id,
      };
      const config = {
        headers: { Authorization: this.token },
      };
      this.$axios
        .post(
          this.base_url + `/jobs/${arg.check ? "activate" : "inactivate"}`,
          payload,
          config
        )
        .then((response) => {
          this.$swal(
            `${arg.check ? "Activated" : "Inactivated"}`,
            `job is now  ${
              arg.check ? "activated" : "inactivated"
            } successfully 🙂`,
            "success"
          );
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data.message, "error");
          window.location.reload();
        });
    },
    clearSearch() {
      this.filter = "";
      this.whitelistSecurity(this.currentPage, (event = ""));
    },

    errorAlert(id) {
      var id = id;
      this.$swal({
        title: "Are you sure?",
        text: "You want to delete this Add IP Address",
        type: "success",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$axios
            .delete(this.base_url + "/whitelist-security/" + id + "/", {
              headers: { Authorization: this.token },
            })
            .then((response) => {
              if (response.status == 200) {
                this.$swal("Success", response.data.message, "success");
              }
            });
        } else {
          this.$swal("Cancelled", "Your IP Address is still intact", "info");
        }
      });
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.whitelistSecurity(value, (event = ""));
      },
    },
  },
};
</script>

<style scoped></style>
