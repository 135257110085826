<template>
  <div :class="{ sidebar_minimize: sidebar }">
    <Sidebar v-if="shouldShowSidebar" :toggleClass="sidebar" />
    <SidebarWelcome v-if="shouldShowWelcomeSidebar" :toggleClass="sidebar" />
    <!-- If using vue-router -->
    <Header @clicked="onClickChild" />
    <div class="maincontent">
      <router-view />
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import Sidebar from "../components/Sidebar.vue"
import SidebarWelcome from "../components/SidebarWelcome.vue"

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Sidebar,
    SidebarWelcome,
  },
  data() {
    return {
      sidebar: false,
    }
  },
  props: {
    toggleClass: {
      type: Boolean,
    },
  },
  methods: {
    onClickChild() {
      this.sidebar = !this.sidebar
    },
  },
  computed: {
    shouldShowSidebar() {
      return this.$route.meta.sidebar !== false
    },
    shouldShowWelcomeSidebar() {
      return this.$route.meta.sidebar == false
    },
  },
}
</script>

<style>
#app {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
