<template>
  <div id="app">
    <editor
      apiKey="45b0b2zxyd5tn5w785wn8bycwxiu7m0bcr5isrtphjhto7u6"
      :init="{
        height: 400,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
      }"
    />
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue"

export default {
  name: "app",
  components: {
    editor: Editor,
  },
}
</script>
