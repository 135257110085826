<template>
  <div class="add-permission">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <FormulateForm class="text-left" @submit="submitHandler" v-model="formData">
      <FormulateInput
        type="text"
        class="mb-3"
        name="title"
        label="Title"
        validation="required"
        placeholder="Title"
      />
      <div>
        <label>Role</label>
        <FormulateInput
          type="group"
          name="userData"
          :repeatable="true"
          add-label="+ Add More"
          validation="required"
        >
          <FormulateInput
            type="text"
            class="mb-3"
            name="title"
            validation="required"
            placeholder="Role Title"
          />
        </FormulateInput>
      </div>
      <b-row class="d-flex justify-content-end px-2">
        <b-button class="btn-theme-clear mr-2" @click="clearFormData"> Clear </b-button>
        <FormulateInput
          type="submit"
          class="btn-theme-custom btn-bold text-uppercase"
          label="Submit"
        />
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
export default {
  name: "AddPermission",

  components: {},
  data() {
    return {
      loader: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      formData: {},
    }
  },
  methods: {
    clearFormData() {
      this.formData.title = ""
      this.formData.userData = ""
    },
    async submitHandler(data) {
      this.loader = true
      var employer = this.input
      var payload = {
        title: this.formData.title,
        role: this.formData.userData,
      }
      const config = {
        headers: { Authorization: this.token },
      }

      this.$axios
        .post(this.base_url + "/permissions/", payload, config)
        .then((response) => {
          if (response.status == 201) {
            window.location.reload()
            this.loader = false
          }
        })
        .catch((error) => {
          this.loader = false
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>
<style scoped>
.formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable {
  position: relative;
}
.spinn-loader {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
</style>
