<template>
  <div class="login-sessions">
    <div class="card-title">
      <h5 class="mb-0 font-weight-bold">Login Sessions</h5>
    </div>
    <b-table
      show-empty
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
      :items-provider="loginSessionSecurity"
    >
    </b-table>
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1"> </b-col>
       <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
            class="my-0 ml-auto float-right"
          ></b-pagination>
        </b-col>
    </b-row>
  </div>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>
export default {
  name: "LoginSessions",
  components: {},
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
       base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      fields: [
        { key: "id", sortable: false },
        { key: "browser", sortable: true },
        { key: "user", sortable: true },
        { key: "location", sortable: true },
        { key: "device", sortable: false },
        { key: "ip_address",label:"IP Address", sortable: false },
        { key: "time",label:"Logs Time", sortable: true },
        { key: "status", sortable: false },
      ],
      items: [],
     totalRows: 1,
      pages: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      sortBy: "",
      filter: "",
      totalItems: null,
    };
  },
   computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },

  async mounted() {
    await this.loginSessionSecurity(this.currentPage, (event = ""));
  },
  methods: {
   async loginSessionSecurity(currentPage, event = "") {
      this.$axios
        .get(
          this.base_url +
            "/login-session-security?page="+this.currentPage,
          {headers: { Authorization: this.token },}
        )
        .then((response) => {
          if(this.currentPage==1){
          this.items=[]; 
        }
        this.items = [...this.items,...response.data.results];
        this.current_page = response.data.current_page;
        this.pages = response.data.total_pages;
        this.totalItems = response.data.result_count;
        });
    },
    
    clearSearch() {
      this.filter = "";
      this.loginSessionSecurity(this.currentPage, (event = ""));
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.loginSessionSecurity(value, (event = ""));
      },
    },
  },
};
</script>

<style scoped></style>
