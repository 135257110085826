<template>
  <div class="page-maincontent">
    <EditJobForm />
  </div>
</template>

<script>
import EditJobForm from "./EditJob.vue"

export default {
  name: "EditJob",
  components: {
    EditJobForm,
  },
}
</script>

<style scoped></style>
