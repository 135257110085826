<template>
  <div class="page-maincontent">
    <UsersDetails />
  </div>
</template>

<script>
import UsersDetails from "./UserDetails.vue"
export default {
  name: "UsersDetail",
  components: {
    UsersDetails,
  },
}
</script>

<style scoped></style>
