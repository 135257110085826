<template>
  <div class="list-group-container">
    <ul class="list-group" @scroll="loadMore">
      <!-- use @scroll.native when using a drag&drop library (like Vuedraggable) if you need to move the items to another column -->
      <li v-for="(item, index) in items" :key="index">
        <div v-for="(notification, index1) in notifications" :key="index1">
          <div
            @click="gotoNotificationDetail(notification.id)"
            v-if="index1 == index"
          >

              <b-card style="max-height: 10rem;" :class="notification.is_read ? 'read' : 'unread'">
                <b-card-text class="truncate">
                  <small class="time">{{ notification.log_entry_action_time }}</small>
                  <br/>
                  <small>{{ notification.log_entry_message }}</small>
                </b-card-text>
              </b-card>

          </div>
        </div>
      </li>
    </ul>
    <!-- <transition name="fade">
      <p v-if="loading" class="loading">Loading</p>
    </transition> -->
  </div>
</template>
<script>
import moment from "moment"
export default {
  name: "Header",
  data() {
    return {
      page: 1,
      itemsPerPage: null,
      loading: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      notifications: [],
      totalPages: 1,
    }
  },
  computed: {
    items() {
      return this.itemsPerPage
    },
  },
  created() {
    this.getNotifiacations()
  },
  methods: {
    gotoNotificationDetail(id) {
      this.$router.push("/notifiaction-detail/" + id).catch(() => {})
    },
    loadMore(e) {
      let { scrollTop, clientHeight, scrollHeight } = e.target
      if (!this.loading && scrollTop + clientHeight >= (scrollHeight * 4) / 5) {
        this.loading = true
        if (this.totalPages >= this.page) {
          setTimeout(() => {
            this.page++
            this.loading = false
            this.getNotifiacations()
          }, 1000)
        }
      }
    },
    getNotifiacations() {
      if (this.totalPages >= this.page) {
        this.$axios
          .get(this.base_url + "/notifications/?page=" + this.page, {
            headers: { Authorization: this.token },
          })
          .then((response) => {
            this.notifications = [
              ...this.notifications,
              ...response.data.results.map((result) => ({
                id: result.id,
                is_read: result.is_read,
                log_entry: result.log_entry,
                log_entry_action_time: result.log_entry_action_time,
                log_entry_id: result.log_entry_id,
                log_entry_message: result.log_entry_message,
              })),
            ]

            this.totalPages = response.data.total_pages
            // this.pages= this.items.length/10;

            this.itemsPerPage = response.data.result_count
          })
      }
    },
  },
}
</script>
<style>
.list-group-container {
  position: relative;
}

.list-group {
  width: 300px;
  max-height: 70vh;
  overflow-y: scroll;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.2), 0 0 0 1px rgba(10, 10, 10, 0.2);
}

.unread{
  background-color: #3f4254;
  padding: 0px !important;
  color: white;
  border-radius: 0 !important;
}

.time{
  float: right;
}

.read {
   background-color:#d3d3d3;
   padding: 0px !important;
   border-radius: 0 !important;
}


.loading {
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  width: 100px;
  margin: auto;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  border-radius: 4px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
