<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Preview Template</h4>
      </div>
    </div>

    <TemplatePreview />
  </div>
</template>

<script>
import TemplatePreview from "./TemplatePreview"
export default {
  name: "EmailBuilderPreview",

  components: {
    TemplatePreview,
  },
}
</script>

<style scoped></style>
