<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Permission</h4>
          <b-button @click="createshow = true" size="sm" class="btn-theme">
          <span>Create New</span>
          </b-button>
      </div>
    </div>
    <b-card class="custom-card">
      <PermissionList />
    </b-card>

    <b-modal
      id="AddPermission"
      title="Create Permission"
      v-model="createshow"
      hide-footer
    >
      <AddPermission />
      <template #modal-footer>
        <div class="button-action text-center w-100">
          <b-button class="btn-theme" v-on:click="successAlert">
            Submit
          </b-button>
          <b-button variant="danger" @click="$bvModal.hide('AddPermission')">
            Discard
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PermissionList from "./PermissionList"
import AddPermission from "./AddPermission"
export default {
  name: "Permission",

  components: {
    PermissionList,
    AddPermission,
  },
  data() {
    return {
      createshow: false,
    }
  },
  methods: {
    successAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to add the permission",
        type: "success",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes Add it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal("Success", "You successfully Add permission", "success")
        } else {
          this.$swal("Cancelled", "Your Role is still intact", "info")
        }
      })
    },
  },
}
</script>

<style scoped></style>
