<template>
  <div>
    <b-card no-body class="custom-card mb-3">
      <b-tabs>
        <b-tab active v-if="candidateDetail.skill.length > 0">
          <template #title> Application </template>
          <div class="card-body application">
            <ApplicationSummary :candidateDetail="candidateDetail" />
          </div>
        </b-tab>
        <b-tab v-if="candidateDetail.resume">
          <template #title> Resume </template>
          <div class="card-body resume">
            <Resume :candidateDetail="candidateDetail" />
          </div>
        </b-tab>
        <b-tab
          v-if="
            candidateDetail.resume &&
            candidateDetail.resume_assessment.total !== '0 %'
          "
        >
          <template #title> Assessment </template>
          <div class="card-body assessment">
            <Assessment :candidateDetail="candidateDetail" />
          </div>
        </b-tab>
        <b-tab>
          <template #title> Communication </template>
          <div class="card-body communication">
            <Communication :candidateDetail="candidateDetail" />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import ApplicationSummary from "../CandidatesDetails/ApplicationSummary.vue"
import Resume from "../CandidatesDetails/Resume.vue"
import Assessment from "../CandidatesDetails/Assessment.vue"
import Communication from "../CandidatesDetails/Communication.vue"
export default {
  name: "ContentFluid",

  components: {
    ApplicationSummary,
    Resume,
    Assessment,
    Communication,
  },
  props: ["candidateDetail"],
  // data() {
  //     return {

  //   }
  // }
}
</script>

<style scoped>
.nav-tabs .nav-item .nav-link {
  display: inherit;
}
</style>
