<template>
  <div class="upload-campaign">
    <b-form class="form-horizontal" @submit="onSubmit">
      <b-form-group>
        <div class="customlabel">
          <label for="">Provider</label>
          <span class="material-icons-outlined">*</span>
        </div>
        <b-form-select
          v-model="providerSelected"
          :options="providerOptions"
        ></b-form-select>
        <p class="error" v-if="error.providerSelected">
          {{ error.providerSelected }}
        </p>
      </b-form-group>

      <b-form-group>
        <div class="customlabel">
          <label for="">HT API Key</label>
          <span class="material-icons-outlined">*</span>
        </div>
        <b-form-input placeholder="XXXXXXXXX" v-model="form.api">
        </b-form-input>
        <p class="error" v-if="error.api">
          {{ error.api }}
        </p>
      </b-form-group>

      <b-form-group>
        <div class="customlabel">
          <label for="">Token Type</label>
        </div>
        <b-form-input placeholder="Bearer" v-model="form.token_type" disabled>
        </b-form-input>
      </b-form-group>

      <b-form-group>
        <div class="customlabel">
          <label for="">Token ID</label>
          <span class="material-icons-outlined">*</span>
        </div>
        <b-form-input placeholder="T45XD796SEFF" v-model="form.access_token">
        </b-form-input>
        <p class="error" v-if="error.access_token">
          {{ error.access_token }}
        </p>
      </b-form-group>

      <b-form-group>
        <div class="customlabel">
          <label for="">Refresh Token</label>
          <span class="material-icons-outlined">*</span>
        </div>
        <b-form-input placeholder="ASD578SDF004" v-model="form.refresh_token">
        </b-form-input>
        <p class="error" v-if="error.refresh_token">
          {{ error.refresh_token }}
        </p>
      </b-form-group>

      <b-form-group id="configuration">
        <div class="customlabel">
          <label for="">Configuration</label>
          <span class="material-icons-outlined">*</span>
        </div>
        <ckeditor
          :height="500"
          :editor="editor"
          v-model="form.configuration"
        ></ckeditor>
        <p class="error" v-if="error.configuration">
          {{ error.configuration }}
        </p>
      </b-form-group>

      <b-button type="submit" class="btn-theme mr-2">Submit</b-button>
      <b-button variant="danger" @click="clearFormData"> Discard </b-button>
    </b-form>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
export default {
  name: "AddATS",
  components: {},
  data() {
    return {
      editor: ClassicEditor,
      file1: null,
      providerSelected: null,
      jobSelected: null,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      user: localStorage.getItem("user"),
      providerOptions: [
        { value: null, text: "Choose Provider" },
        { value: "1", text: "SmartRecruiters" },
        { value: "2", text: "jobadder" },
        { value: "3", text: "workday" },
        { value: "4", text: "sap" },
      ],
      form: {
        api: "",
        token_type: "Bearer",
        expires_in: null,
        access_token: "",
        refresh_token: "",
        configuration: "",
      },
      error: {
        providerSelected: "",
        access_token: "",
        refresh_token: "",
        api: "",
        configuration: "",
      },
    }
  },
  methods: {
    clearFormData() {
      this.form.api = ""
      this.form.access_token = ""
      this.form.refresh_token = ""
      this.form.configuration = ""
      this.providerSelected = null
    },
    onSubmit(event) {
      event.preventDefault()

      var payload = {
        api: this.form.api,
        access_token: this.form.access_token,
        refresh_token: this.form.refresh_token,
        token_type: this.form.token_type,
        expires_in: this.form.expires_in,
        configuration: this.form.configuration,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      var id = this.providerSelected
      if (
        this.providerSelected == null ||
        this.form.access_token == "" ||
        this.form.refresh_token == "" ||
        this.form.api == "" ||
        this.form.configuration == ""
      ) {
        if (this.providerSelected == null) {
          this.error.providerSelected = "Please Select Provider "
        }
        if (this.form.access_token == "") {
          this.error.access_token = "This field may not be blank."
        }
        if (this.form.refresh_token == "") {
          this.error.refresh_token = "This field may not be blank."
        }
        if (this.form.api == "") {
          this.error.api = "This field may not be blank."
        }
        if (this.form.configuration == "") {
          this.error.configuration = "This field may not be blank."
        }
      } else {
        this.$axios
          .post(
            this.base_url + "/ats/" + id + "/configuration/",
            payload,
            config
          )
          .then((response) => {
            if (response.status == 201) {
              event.target.reset()
              this.providerSelected = null
              this.form.configuration = ""
              const _this = this
              _this.$emit("addAts", { createshow: false })
              window.location.reload()
            }
          })
          .catch((error) => {})
          .finally(() => (this.loading = false))
      }
    },
  },
}
</script>

<style scoped></style>
