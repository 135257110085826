<template>
  <div class="employer-listing">
    <b-card class="custom-card">
      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-form-group
            label="Search"
            label-for="filter-input"
            label-cols-sm="3"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="ml-auto search-filter"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="text"
                placeholder="Type to Search"
                @keyup="getData(currentPage, $event.target.value)"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="clearSearch(1), (filter = '')"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        small
        responsive="lg"
        outlined
        hover
        head-variant="light"
        :items-provider="getData"
      >
        <template #cell(actions)="items">
          <div class="d-flex align-items-center">
            <b-link
              @click="employerUsers(items.item.id)"
              class="mr-2 mt-2 text-warning"
            >
              <span class="material-icons-outlined"> visibility </span>
            </b-link>
            <div class="d-inline-block">
              <toggle-switch
                :values="items.item.status === 'active'"
                @active="activate"
                :id="items.item.id"
                :comment="'employer'"
              ></toggle-switch>
            </div>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
            class="my-0 ml-auto float-right"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import ToggeComp from "../../components/ToggeComp.vue"
export default {
  name: "EmployerList",

  components: {
    "toggle-switch": ToggeComp,
  },
  data() {
    return {
      checked: true,
      unchecked: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      items: [],
      fields: [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Employer Name", sortable: true },
        { key: "full_address", label: "Employer Address", sortable: true },
        { key: "phone_number", label: "Phone Number", sortable: true },
        { key: "employee_count", label: "Employer Users", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      filter: "",
      totalItems: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },

  async mounted() {
    await this.getData(this.currentPage, (event = ""))
  },
  methods: {
    async getData(currentPage, event = "") {
      this.$axios
        .get(
          this.base_url +
            "/employers/?keyword_search=" +
            this.filter +
            "&page=" +
            this.currentPage,
          { headers: { Authorization: this.token } }
        )
        .then((response) => {
          if (this.filter == "") {
            if (this.currentPage == 1) {
              this.items = []
            }
            this.items = [...this.items, ...response.data.results]
          } else {
            if (this.currentPage == 1) {
              this.items = []
            }
            this.items = [...this.items, ...response.data.results]
          }
          this.currentPage = response.data.current_page
          this.totalItems = response.data.result_count
        })
    },
    employerUsers(id) {
      this.$router.push("/employer-users/" + id)
    },
    activate(arg) {
      var payload = {
        id: arg.id,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .post(
          this.base_url +
            `/employers/${arg.check ? "activate" : "inactivate"}/`,
          payload,
          config
        )
        .then((response) => {
          if (response.status_code == 400) {
            this.Swal.fire("Oops!", error.response.data.message, "error")
          } else {
            this.$swal(
              `${arg.check ? "Activated" : "Inactivated"}`,
              `Employer is now ${
                arg.check ? "activated" : "inactivated"
              } successfully 🙂`,
              "success"
            )
          }
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data.message, "error")
          window.location.reload()
        })
    },
    clearSearch() {
      this.filter = ""
      this.items = []
      this.currentPage = 1
      this.getData(this.currentPage, (event = ""))
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getData(value, (event = ""))
      },
    },
  },
}
</script>

<style scoped>
</style>
