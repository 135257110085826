<template>
  <div class="page-maincontent">
    <ImportFromAtsForm />
  </div>
</template>

<script>
import ImportFromAtsForm from "./ImportFromAtsForm.vue"

export default {
  name: "ImportFromAts",
  components: {
    ImportFromAtsForm,
  },
}
</script>

<style scoped></style>
