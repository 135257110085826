<template>
  <div class="ats-listing">
    <div class="connected-ats-list mb-4">
      <div class="connected-title mb-2">
        <h5 class="font-weight-bold mb-0">Total Employers Connected: {{ employersTotal }}</h5>
      </div>
      <br/>

      <div>
        <b-card-group deck>
          <b-card style="max-width: 40rem;" class="mb-2" img-height="200" @click="viewJobsForm(aats.atsID)" v-for="aats in available_ATS" v-bind:key="aats.id" :title=aats.atsName :img-src=aats.logo img-alt="Image" img-top>

            <b-card-text class="small text-muted text-right">{{ aats.website }}</b-card-text>
            <b-card-text>{{ aats.catsDesc }}</b-card-text>
            <template #footer>
              <small class="text-muted alignleft">{{ aats.createdAt }}</small>
              <small class="text-muted alignright">{{ aats.employerCount }}</small>
            </template>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
export default {
  name: "ATSListing",
  components: {},
  data() {
    return {
      comapny: 1,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      currentPage: 1,
      employersTotal: "",
      available_ATS: [],
    }
  },
  mounted() {
    this.getAllATS()
  },
  methods: {
    getAllATS() {
      const config = {
        headers: { Authorization: this.token },
      }
      this.$axios
        .get(this.base_url + "/ats", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.available_ATS = response.data.result.ats.map((data) => ({
            atsID: data.id,
            atsName: data.name,
            catsDesc: data.description,
            logo: data.logo,
            createdAt: moment(
                  data.created_at
                ).format("MMMM D, YYYY"),
            employerCount: data.employer_count,
            btnConnected: "Connected",
            integrationTut: "See Integration Tutorial",
          }))
          this.employersTotal = response.data.result.total_connected_employers
        })
    },
    viewJobsForm(id) {
      this.$router.push("/ats/" + id);
    },
  },
}
</script>

<style>

.cards:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0,0,0,0.11),
    0 24px 46px var(--box-shadow-color);
}

.alignleft {
	float: left;
}
.alignright {
	float: right;
}

</style>
