<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Campaign Details</h4>
      </div>
    </div>
    <b-card class="custom-card">
      <Details />
    </b-card>
  </div>
</template>

<script>
import Details from "./Details"
export default {
  name: "CampaignDetail",
  components: {
    Details,
  },
  methods: {},
}
</script>

<style scoped></style>
