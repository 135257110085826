var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"update-password"},[_c('FormulateForm',{staticClass:"mt-3 mb-3 mt-lg-5 mb-lg-5 text-left",on:{"submit":_vm.submitHandler},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[(_vm.error.message)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.error.message)+" ")]):_vm._e(),_c('FormulateInput',{staticClass:"mb-3",attrs:{"type":"password","name":"old_password","validation":"required","placeholder":"","label":"Current Password","validation-messages":{
        required: 'Old Password field is required',
      }}}),_c('FormulateInput',{staticClass:"mb-3",attrs:{"type":"password","name":"password","validation":"required","placeholder":"","label":"New Password","validation-messages":{
        required: 'New Password field is required',
      }}}),_c('FormulateInput',{staticClass:"mb-3",attrs:{"type":"password","name":"password_confirm","validation":"required|confirm","validation-name":"Confirmation","placeholder":"","label":"Confirm Password","validation-messages":{
        required: 'Confirm Password field is required',
      }}}),_c('b-row',{staticClass:"d-flex justify-content-end px-3"},[_c('b-button',{staticClass:"btn-theme-clear mr-2",on:{"click":_vm.clearFormData}},[_vm._v("Clear")]),_c('FormulateInput',{staticClass:"btn-theme-custom btn-bold text-uppercase",attrs:{"type":"submit","label":"Submit"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }