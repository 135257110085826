<template>
  <div class="page-maincontent">
    <WelcomeCard />
    <CreateJobCard />
  </div>
</template>

<script>
import WelcomeCard from "./WelcomeCard.vue"
import CreateJobCard from "./CreateJobCard.vue"

export default {
  name: "WelcomeDashboard",
  components: {
    WelcomeCard,
    CreateJobCard,
  },
  data() {
    return {
      isHidden: false,
    }
  },
  methods: {
    closeModal() {
      this.isModalVisible = false
    },
  },
}
</script>

<style scoped></style>
