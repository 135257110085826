<template>
  <div class="applicants">
    <FilterHeader @sendFilter="setfilter" />
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1"> </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
        <b-form-group
          label="Search"
          label-for="filter-input"
          label-cols-sm="3"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="ml-auto search-filter"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="text"
              placeholder="Type to Search"
              @keyup="getApplicants(currentPage, $event.target.value)"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="clearSearch(1), (filter = '')"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      show-empty
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
      :items-provider="getApplicants"
    >
      <template #cell(actions)="items">
        <div class="d-flex align-items-center">
          <b-link
            @click="getCandidateDetail(items.item.id)"
            class="mr-2 mt-2 text-warning"
          >
            <span class="material-icons-outlined"> visibility </span>
          </b-link>
          <b-link class="text-info" @click="template(items.item.id)">
            <span class="material-icons-outlined"> email </span>
          </b-link>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
        <b-form-group
          label-for="per-page-select"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="perpage-select"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
        <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
            class="my-0 ml-auto float-right"
          ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>
import moment from "moment";
import FilterHeader from "./FilterHeader";
export default {
  name: "JobApplicants",

  components: {
    FilterHeader,
  },

  data() {
    return {
      addTemplate: false,
      checked: false,
      switchchecked: true,
      selectedTemplate: null,
      candidateId: null,
      JobFilter: null,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      JobListFilter: [],
      optionsTemplate: [{ value: null, text: "Please select email template" }],
      items: [],

      fields: [
        { key: "id", label: "ID",sortable: true },
        {
          key: "created_at",
          label: "Application Date",sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              return moment(item.created_at).format("YYYY-MM-DD");
            } else {
            }
          },
        },
        { key: "first_name", label: "Candidate Name",sortable: true },
        { key: "job", label: "Job Application",sortable: true },
        { key: "email", label: "Email Address",sortable: true },
        { key: "source", label: "Source",sortable: true },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      pages: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      sortBy: "",
      filter: "",
      filterOn: [],
      filterByFromDate: "",
      filterByToDate: "",
      fromDate: "",
      toDate: "",
      totalItems: null,
    };
  },
  async mounted() {
    await this.getApplicants(this.currentPage, (event = ""));
    await this.getEmailTemplate();
  },
  methods: {
    setfilter(arg) {
      this.filter = arg.filter;
      if (arg.searchkey == "created_at_after") {
        this.filterByFromDate = arg.searchkey;
        this.filter = "";
        this.fromDate = arg.filter;
      }
      if (arg.searchkey == "created_at_before") {
        this.filterByToDate = arg.searchkey;
        this.filter = "";
        this.toDate = arg.filter;
      }
      this.getApplicants();
    },
  
    async getApplicants(currentPage, event = "") {
      if (this.filter != "") {
        this.$axios
          .get(
            this.base_url +
              "/candidates?page=" +
              this.currentPage +
              "&keyword_search=" +
              this.filter,
            {
              headers: { Authorization: this.token },
            }
          )
          .then((response) => {
            if(this.filter==""){
              if(this.currentPage==1){
               this.items=[]; 
              }
            this.items = [...this.items,...response.data.results];
          }
          else{
           if(this.currentPage==1){
               this.items=[]; 
              }
              this.items = [...this.items,...response.data.results];
          }
          this.current_page = response.data.current_page;
          this.pages = response.data.total_pages;
          this.totalItems = response.data.result_count;
          });
      } else {
        if (
          this.filterByFromDate != "created_at_after" &&
          this.filterByToDate != "created_at_before"
        ) {
          this.$axios
            .get(this.base_url + "/candidates?page=" + this.currentPage, {
              headers: { Authorization: this.token },
            })
            .then((response) => {
            if(this.currentPage==1){
               this.items=[]; 
            }
            this.items = [...this.items,...response.data.results];
            this.current_page = response.data.current_page;
            this.pages = response.data.total_pages;
            this.totalItems = response.data.result_count;
            });
        } else {
          if (this.filterByFromDate != "" && this.filterByToDate == "") {
            var url =
              this.base_url +
              "/candidates?page=" +
              this.currentPage +
              "&" +
              this.filterByFromDate +
              "=" +
              this.fromDate;
            this.filter = this.fromDate;
          }

          if (this.filterByFromDate == "" && this.filterByToDate != "") {
            var url =
              this.base_url +
              "/candidates?page=" +
              this.currentPage +
              "&" +
              this.filterByToDate +
              "=" +
              this.toDate;
            this.filter = this.toDate;
          }
          if (this.filterByFromDate != "" && this.filterByToDate != "") {
            var url =
              this.base_url +
              "/candidates?page=" +
              this.currentPage +
              "&" +
              this.filterByFromDate +
              "=" +
              this.fromDate +
              "&" +
              this.filterByToDate +
              "=" +
              this.toDate;
          }
          this.$axios
            .get(url, {
              headers: { Authorization: this.token },
            })
            .then((response) => {
             if(this.filter==""){
              if(this.currentPage==1){
               this.items=[]; 
              }
                this.items = [...this.items,...response.data.results];
              }
              else{
              if(this.currentPage==1){
                  this.items=[]; 
                  }
                  this.items = [...this.items,...response.data.results];
              }
              this.current_page = response.data.current_page;
              this.pages = response.data.total_pages;
              this.totalItems = response.data.result_count;
            });
        }
      }
    },
    async getEmailTemplate() {
      this.$axios
        .get(this.base_url + "/email-templates", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
         
          this.optionsTemplate = response.data.results.map((data) => ({
            value: data.id,
            text: data.template_key,
          }));
        });
    },
    getCandidateDetail(id) {
      this.$router.push("/candidates/" + id);
    },
    template(id) {
      this.addTemplate = true;
      this.candidateId = id;
      // this.$router.push("/candidates/" + id);
    },
    successAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to add this template",
        type: "info",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes Add it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          var payload = {
            candidate: this.candidateId,
            email_template: this.selectedTemplate,
          };
          const config = {
            headers: { Authorization: this.token },
          };
          this.$axios
            .post(this.base_url + "/communications/", payload, config)
            .then((response) => {
             
              if (response.status == 200) {
                this.$swal(
                  "Success",
                  response.data.message + " Successfully",
                  "success"
                );
                this.addTemplate = false;
              }
            })
            .catch((error) => {
           
            })
            .finally(() => (this.loading = false));
        } else {
          this.$swal("Cancelled", "Your template is still intact", "info");
        }
      });
    },
    clearSearch() {
      this.filter = "";
      this.getApplicants(this.currentPage, (event = ""));
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getApplicants(value, (event = ""));
      },
    },
  },
};
</script>

<style scoped>
/* successAlert */
</style>
