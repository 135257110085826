<template>
  <div class="report-table p-4">
    <b-table
      outlined
      responsive="sm"
      size="sm"
      hover
      head-variant="light"
      :items="items"
    ></b-table>
  </div>
</template>

<script>
export default {
  name: "FunnelReport",
  components: {},
  data() {
    return {
      items: [
        {
          Value: "Number",
          Impression: "154",
          Clicks: "45845",
          Landing: "154",
          Jobs: "4567",
          Application: "250",
        },
        {
          Value: "%",
          Impression: "60%",
          Clicks: "80%",
          Landing: "75%",
          Jobs: "65%",
          Application: "90%",
        },
        {
          Value: "$",
          Impression: "$452",
          Clicks: "$563",
          Landing: "$458",
          Jobs: "$568",
          Application: "$756",
        },
      ],
    }
  },
}
</script>

<style scoped></style>
