<template>
  <div class="candidate-list p-4">
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
        <b-form-group
          label-for="per-page-select"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="perpage-select"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
        <b-form-group
          label="Search"
          label-for="filter-input"
          label-cols-sm="3"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="ml-auto search-filter"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="text"
              placeholder="Type to Search"
              @keyup="getCandidateList(currentPage, $event.target.value)"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="clearSearch(1), (filter = '')"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      show-empty
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
      :items-provider="getCandidateList"
    >
      <template #cell(actions)="items">
        <div class="d-flex align-items-center">
          <b-link
            @click="getCandidateDetail(items.item.id)"
            class="mr-2 mt-2 text-warning"
          >
            <span class="material-icons-outlined"> visibility </span>
          </b-link>
          <div class="d-inline-block">
            <toggle-switch
              :values="items.item.status === 'active'"
              @active="activate"
              :id="items.item.id"
              :comment="'candidate'"
            ></toggle-switch>
          </div>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
        <b-form-group
          label-for="per-page-select"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="perpage-select"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
         <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
            class="my-0 ml-auto float-right"
          ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>
import ToggeComp from "../../components/ToggeComp.vue";
export default {
  name: "CandidateList",
  components: { "toggle-switch": ToggeComp },
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      items: [],
      checked: true,
      items: [],
      fields: [
        { key: "id", label: "ID",sortable: true },
        { key: "first_name", label: "Candidate Name",sortable: true },
        { key: "job", label: "Job Application",sortable: true },
        { key: "email", label: "Email Address",sortable: true },
        { key: "source", label: "Source",sortable: true },
        { key: "actions", label: "Actions" },
      ],
      totalRows: null,
      pages: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      sortBy: "",
      filter: "",
      filterOn: [],
       totalItems: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.getCandidateList(this.currentPage, (event = ""));
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering

      this.currentPage = this.$route.query.page;
    },

    getCandidateList(currentPage, event = "") {
      this.$axios
        .get(this.base_url + "/candidates?recent=true&page=" +
              this.currentPage +
              "&keyword_search=" +
              this.filter, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          if(this.filter==""){
            this.items = [...this.items,...response.data.results];
          }
          else{
           if(this.currentPage==1){
               this.items=[]; 
              }
              this.items = [...this.items,...response.data.results];
          }
          this.current_page = response.data.current_page;
          this.pages = response.data.total_pages;
          this.totalRows = response.data.result_count;
          this.totalItems = response.data.result_count;
        });
    },
    getCandidateDetail(id) {
      this.$router.push("/candidates/" + id);
    },
    activate(arg) {
      var payload = {
        id: arg.id,
      };
      const config = {
        headers: { Authorization: this.token },
      };
      this.$axios
        .post(
          this.base_url +
            `/candidates/${arg.check ? "activate" : "inactivate"}/`,
          payload,
          config
        )
        .then((response) => {
          this.$swal(
            `${arg.check ? "Activated" : "Inactivated"}`,
            `Candidate is now ${
              arg.check ? "activated" : "inactivated"
            } successfully 🙂`,
            "success"
          );
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data.message, "error");
          window.location.reload();
        });
    },
    deleteModal(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to delete this user",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const config = {
            headers: { Authorization: this.token },
          };
          this.$axios
            .delete(this.base_url + "/candidates/" + id, config)
            .then((response) => {
              if (response.status == 200) {
                this.$swal(
                  "Deleted",
                  "Candidate removed successfully! 🙂",
                  "success"
                );
              }
            });
        } else {
          this.$swal("Cancelled", "Your file is still intact", "info");
        }
      });
    },
    clearSearch() {
      this.filter = "";
      this.items=[];
      this.currentPage=1;
      this.getCandidateList(this.currentPage, (event = ""));
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getCandidateList(value, (event = ""));
      },
    },
  },
};
</script>

<style scoped></style>
