<template>
  <div class="auth-body-bg">
    <b-container fluid class="p-lg-0">
      <b-row class="m-lg-0">
        <b-col lg="6" xl="4">
          <div
            class="authentication-page-content p-md-4 d-lg-flex align-items-lg-center min-vh-100"
          >
            <div class="w-100">
              <b-row class="justify-content-center">
                <b-col lg="12" xl="9">
                  <div class="text-left">
                    <div class="text-left mb-3">
                      <router-link to="#" class="">
                        <img
                          src="../assets/images/logo.png"
                          alt=""
                          height="48"
                          class="auth-logo"
                        />
                      </router-link>
                    </div>

                    <h4 class="font-weight-700 mt-4">Register account</h4>
                    <p class="text-muted">Get your free hoarde account now.</p>
                  </div>
                  <b-form class="mt-3 mb-3 mt-lg-5 mb-lg-5 text-left">
                    <b-form-group
                      id="email-address"
                      label-for="email-address"
                      class="mb-3"
                    >
                      <b-form-input
                        size="lg"
                        id="email-address"
                        type="email"
                        placeholder="Email Address"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="user-name" class="mb-3">
                      <b-form-input
                        size="lg"
                        id="user-name"
                        type="text"
                        placeholder="User Name"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group class="mb-3" id="text-password">
                      <b-form-input
                        size="lg"
                        type="password"
                        id="text-password"
                        placeholder="Password"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group class="mb-3" id="confirm-password">
                      <b-form-input
                        size="lg"
                        type="password"
                        id="confirm-password"
                        placeholder="Confirm Password"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-3" label-for="input-3">
                      <div class="d-flex justify-content-between">
                        <b-form-checkbox class="font-weight-600"
                          >By registering you agree to the
                          hoarde</b-form-checkbox
                        >
                      </div>
                    </b-form-group>
                  </b-form>
                  <b-button
                    block
                    size="lg"
                    class="btn-theme btn-bold w-100 text-uppercase"
                    >Signup</b-button
                  >
                  <div class="text-left mt-3 font-weight-600">
                    Already have account?
                    <router-link to="/">Login</router-link>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col lg="6" xl="8">
          <div class="authentication-bg">
            <div class="bg-overlay"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SignupPage",
  components: {},
}
</script>

<style scoped>
.authentication-bg {
  background-image: url(../assets/images/authentication-bg.jpg);
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
}
.authentication-bg .bg-overlay {
  background-color: #292626;
}
.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.4;
  background-color: #000;
}
.min-vh-100 {
  min-height: 100vh;
}
.authentication-page-content {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 767px) {
  .authentication-page-content {
    height: auto;
  }
  .min-vh-100 {
    min-height: auto !important;
  }
  .authentication-bg {
    height: 30vh;
    margin-top: 20px;
  }
}
</style>
