<template>
  <div class="create-user">
    <p class="error" v-if="error.message">
      {{ error.message }}
    </p>
    <p class="error" v-if="error.email">
      {{ error.email }}
    </p>
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <FormulateForm class="text-left" @submit="submitHandler" v-model="formData">
      <div>
        <FormulateInput
          type="group"
          name="userData"
          :repeatable="true"
          add-label="+ Add More"
          validation="required"
        >
          <CreateMultipleUser
            :isHidden="isHidden"
            :formData="formData"
            @active="activate"
          />
        </FormulateInput>
      </div>
      <b-row class="d-flex justify-content-end px-2">
        <b-button variant="danger" @click="clearFormData"> Clear </b-button>
        <FormulateInput
          type="submit"
          class="btn-theme-custom btn-bold text-uppercase"
          label="Submit"
        />
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import axios from "axios"
import CreateMultipleUser from "../../components/CreateMultipleUser"

export default {
  name: "CreateUser",
  components: {
    CreateMultipleUser,
  },
  props: ["CreatedEmployerId"],
  data() {
    return {
      loader: false,
      index: 0,
      id: this.$route.params.id,
      input: {
        value: "",
      },
      isHidden: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      roleoptions: [],
      newRoleOptions: [],
      employers: [],
      formData: {},
      error: {
        message: "",
        email: "",
      },
    }
  },

  methods: {
    activate(arg) {
      this.isHidden = arg.isHidden
    },
    clearFormData() {
      this.formData.userData = ""
    },

    async submitHandler(data) {
      this.loader = true
      var employer = this.id
      var payload = this.formData.userData.map((data) => ({
        email: data.email,
        role: data.role,
        type: 2,
        employer: this.id,
        partner: null,
      }))

      const config = {
        headers: { Authorization: this.token },
      }
      axios
        .post(this.base_url + "/bulk-invite-user/", payload, config)
        .then((response) => {
          if (response.data.status == 400) {
            this.error.message = response.data.message
            this.error.email = response.email[0]
          }

          if (response.status == 201) {
            const _this = this
            _this.$emit("createShowModelHide", { createshow: false })
            this.loader = false
          }
        })
        .catch((error) => {
          this.loader = false
          this.error.message = error.response.data.message
          this.error.email = error.response.data[0].email[0]
        })
        .finally(() => (this.loading = false))
    },
  },
  watch: {},
  mounted() {},
  created: function () {},
  computed: {},
}
</script>

<style scoped>
.error {
  color: red;
}
select[multiple] {
  height: 150px;
  overflow: auto;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.delete-btn {
  margin-bottom: -20px;
}
.spinn-loader {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
</style>
