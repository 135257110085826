<template>
  <div class="employer-details">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Employer Details</h4>
        <div class="button-action">
          <b-button v-on:click="gotoUpdateEmployer" class="btn-theme">
            <span class="d-flex align-items-center">
              <span class="material-icons-outlined"> edit </span>
              <span>Edit Employer</span>
            </span>
          </b-button>
        </div>
      </div>
    </div>

    <!-- Edit Term and Condition Modal -->
    <b-modal
      ref="edit-terms"
      id="edit-terms"
      size="lg"
      v-model="termshow"
      title="Edit Term and Condition"
      hide-footer
    >
      <b-form class="form-horizontal" @submit="onSubmit">
        <b-row>
          <b-col lg="12" xl="12">
            <b-form-group id="emp-terms" label="Term and Condition">
              <ckeditor
                :editor="editor"
                v-model="form.terms_and_conditions"
              ></ckeditor>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
          <b-button type="submit" class="btn-theme mr-2">Submit</b-button>
          <b-button class="btn-theme-cancel mr-2"  @click="hideModalTC">Cancel</b-button>
          <b-button class="btn-theme-clear mr-2" @click="clearFormDataTC">Clear</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <!-- Edit Privacy Policy Modal -->
    <b-modal
      ref="edit-privacy"
      id="edit-privacy"
      size="lg"
      v-model="privacyshow"
      title="Edit Privacy Policy"
      hide-footer
    >
      <b-form class="form-horizontal" @submit="onSubmit">
        <b-row>
          <b-col lg="12" xl="12">
            <b-form-group id="emp-terms" label="Term and Condition">
              <ckeditor
                :editor="editor"
                v-model="form.privacy_policy"
              ></ckeditor>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
          <b-button type="submit" class="btn-theme mr-2">Submit</b-button>
          <b-button class="btn-theme-cancel mr-2"  @click="hideModalPrivacy">Cancel</b-button>
          <b-button class="btn-theme-clear mr-2" @click="clearFormDataPrivacy">Clear</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-card class="custom-card">
      <!-- items="items" :fields="fields" v-for="detail in details" :key="detail.id"-->
      <b-row>
        <b-col sm="12" md="12" lg="6" xl="6" class="my-1">
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Employer Name</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="text-secondary">{{ employersDetails.name }}</h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Employer Address</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="text-secondary">
                {{ employersDetails.address1 }}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Phone Number</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="text-secondary">
                {{ employersDetails.phone_number }}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Terms & Conditions</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <span class="material-icons-outlined"> shield </span>
              <b-link
                class="text-secondary"
                v-b-modal.edit-terms
                @click="show = true"
              >
                <span class="material-icons-outlined"> edit </span>
              </b-link>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6" class="my-1">
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Recruiter</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="">
                <b-link>{{ employersDetails.website }}</b-link>
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Employer Email</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="">
                <b-link> {{ employersDetails.email }}</b-link>
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Employer Website</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <h6 class="">
                <b-link> {{ employersDetails.website }}</b-link>
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="4" class="my-1">
              <h6 class="font-weight-bold">Privacy Policy</h6>
            </b-col>
            <b-col sm="12" md="12" lg="8" class="my-1">
              <span class="material-icons-outlined"> description </span>
              <b-link
                class="text-secondary"
                v-b-modal.edit-privacy
                @click="show = true"
              >
                <span class="material-icons-outlined"> edit </span>
              </b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TextEditor from "../../components/TextEditor"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
export default {
  name: "EmployerUsersDetails",

  components: {
    TextEditor,
  },

  data() {
    return {
      termshow: false,
      privacyshow: false,
      checked: true,
      unchecked: false,
      id: this.$route.params.id,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      editor: ClassicEditor,
      form: {
        name: "",
        terms_and_conditions: "",
        privacy_policy: "",
      },
      employersDetails: [
        {
          name: "",
          address: "",
          phone_number: "",
        },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key }
        })
    },
  },
  mounted() {
    this.employerDetail(this.id)
  },
  methods: {
    clearFormDataTC() {
      this.form.terms_and_conditions = ""
    },
    clearFormDataPrivacy() {
      this.form.privacy_policy = ""
    },
    hideModalTC() {
      this.$refs["edit-terms"].hide()
    },
    hideModalPrivacy() {
      this.$refs["edit-privacy"].hide()
    },
    employerDetail(id) {
      this.$axios
        .get(this.base_url + "/employers/" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.employersDetails = response.data
          this.form.name = response.data.name
          this.form.terms_and_conditions = response.data.terms_and_conditions
          this.form.privacy_policy = response.data.privacy_policy
        })
    },

    gotoUpdateEmployer() {
      this.$router.push("/update-employer/" + this.id)
    },
    onSubmit(event) {
      event.preventDefault()
      var payload = {
        name: this.form.name,
        terms_and_conditions: this.form.terms_and_conditions,
        privacy_policy: this.form.privacy_policy,
      }
      this.$axios
        .put(this.base_url + "/employers/" + this.id + "/", payload)
        .then((response) => {
          if (response.status == 200) {
            this.privacyshow = false
            this.termshow = false
          }
        })
        .catch((error) => {})
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped></style>
