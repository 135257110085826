<template>
  <div class="filter-header border-bottom p-3">
    <b-row>
      <b-col lg="6" xl="3" class="my-1">
        <b-form-group
          label="Filter By:"
          label-for="filter-by"
          label-cols-sm="3"
          label-cols-lg="2"
          class="mb-0"
        >
          <b-form-select
            v-model="filterByAts"
            :options="optionsFilterByATS"
            @change="sendFilterByATS"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" xl="2" class="my-1">
        <b-form-select
          v-model="filterByStatus"
          :options="optionsFilterByStatus"
          @change="sendFilterByStatus"
        ></b-form-select>
      </b-col>
      <b-col lg="6" xl="4" class="my-1">
        <b-form-group
          label="Filter By Date"
          label-for="filter-date"
          label-cols-sm="3"
          label-cols-lg="3"
          class="mb-0"
        >
          <b-input-group>
            <b-form-datepicker
              placeholder="From"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }"
              v-model="fromDate"
              @input="sendFilterByFromDate"
            ></b-form-datepicker>
            <b-form-datepicker
              placeholder="To"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }"
              v-model="toDate"
              @input="sendFilterByToDate"
            ></b-form-datepicker>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" xl="4" class="my-1"> </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "FilterHeader",
  data() {
    return {
      currentPage: 1,
      optionsFilterByATS: [{ value: "", text: "Select ATS..." }],
      filterByATSList: [],
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      optionsFilterByStatus: [
        { value: "", text: "Select Status" },
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" },
      ],

      filterByAts: "",
      filterByStatus: "",
      fromDate: "",
      toDate: "",
    }
  },
  created() {
    this.getAts()
  },
  methods: {
    sendFilterByToDate() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.toDate,
        searchkey: "date_to",
      })
    },
    sendFilterByFromDate() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.fromDate,
        searchkey: "date_from",
      })
    },
    sendFilterByATS() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.filterByAts,
        searchkey: "keyword_search",
      })
    },
    sendFilterByStatus() {
      const _this = this
      _this.$emit("sendFilter", {
        filter: this.filterByStatus,
        searchkey: "status",
      })
    },
    async getAts(currentPage, event = "") {
      this.$axios
        .get(this.base_url + "/ats", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.filterByATSList = response.data.result.ats.map((data) => ({
            value: data.name,
            text: data.name,
          }))

          const values = this.filterByATSList.map((result) => result.name)
          const filtered = this.filterByATSList.filter(
            ({ value }, index) => !values.includes(value, index + 1)
          )
          const aa = filtered.map((result) =>
            this.optionsFilterByATS.push({
              value: result.value,
              text: result.value,
            })
          )
        })
    },
  },
}
</script>
