<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Edit Email Template</h4>
      </div>
    </div>

    <EditTemplate />
  </div>
</template>

<script>
import EditTemplate from "./EditTemplate"

export default {
  name: "CreateEmailTemplate",
  components: {
    EditTemplate,
  },
}
</script>

<style scoped></style>
