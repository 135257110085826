<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Screening Questions</h4>
        <b-button to="/create-screening-question" size="sm" class="btn-theme">
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> add </span>
            <span>Create</span>
          </span>
        </b-button>
      </div>
    </div>
    <b-card class="custom-card">
      <QuestionsListing />
    </b-card>
  </div>
</template>

<script>
import QuestionsListing from "./QuestionsListing"

export default {
  name: "ScreeningQuestions",
  components: {
    QuestionsListing,
  },
}
</script>

<style scoped></style>
