<template>
  <div class="create-job">
    <b-card class="custom-card">
      <b-form class="form-horizontal" @submit="onSubmit">
        <b-row>
          <b-col lg="6" xl="6" class="text-left">
            <b-form-group id="company" class="v-select-custom" label="Employer">
              <v-select
                :options="paginated"
                :filterable="false"
                :reduce="(option) => option.value"
                label="label"
                @open="onOpen"
                @close="onClose"
                @search="(query) => (search = query)"
                class="mb-2"
                placeholder="Select Employer"
                id="emp"
                v-model="form.company"
              >
                <template #list-footer>
                  <li v-show="hasNextPage" ref="load" class="loader"></li>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-left">
            <b-row class="mb-2">
              <b-col lg="12" xl="12">
                <h6>Basic Information Section</h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox v-model="form_fields.first_name" class="mb-2">
                  First Name
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.first_name"
                  class="mb-2 custom-radio"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox v-model="form_fields.last_name" class="mb-2">
                  Last Name
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.last_name"
                  class="mb-2 custom-radio"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox v-model="form_fields.email" class="mb-2">
                  Email
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.email"
                  class="mb-2 custom-radio"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.phone_number"
                  class="mb-2"
                  @change="activate($event, 'phone_number')"
                >
                  Contact Number
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.phone_number"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'phone_number')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.country"
                  class="mb-2"
                  @change="activate($event, 'country')"
                >
                  Country
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.country"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'country')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.region"
                  class="mb-2"
                  @change="activate($event, 'region')"
                >
                  Region
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.region"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'region')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.city"
                  class="mb-2"
                  @change="activate($event, 'city')"
                >
                  City
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.city"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'city')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.street"
                  class="mb-2"
                  @change="activate($event, 'street')"
                >
                  Street
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.street"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'street')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.skype"
                  class="mb-2"
                  @change="activate($event, 'skype')"
                >
                  Skype
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.skype"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'skype')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.linke_din"
                  class="mb-2"
                  @change="activate($event, 'linke_din')"
                >
                  LinkedIn
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.linke_din"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'linke_din')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.facebook"
                  class="mb-2"
                  @change="activate($event, 'facebook')"
                >
                  Facebook
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.facebook"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'facebook')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.twitter"
                  class="mb-2"
                  @change="activate($event, 'twitter')"
                >
                  Twitter
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.twitter"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'twitter')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.website"
                  class="mb-2"
                  @change="activate($event, 'website')"
                >
                  Website
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.website"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'website')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>

          <b-col class="text-left">
            <b-row class="mb-2">
              <b-col lg="12" xl="12">
                <h6>Professional Experience</h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.work_exp_title"
                  class="mb-2"
                  @change="activate($event, 'work_exp_title')"
                >
                  Title
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.work_exp_title"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'work_exp_title')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.work_exp_company"
                  class="mb-2"
                  @change="activate($event, 'work_exp_company')"
                >
                  Company
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.work_exp_company"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'work_exp_company')"
                >
                </b-form-checkbox>
              </b-col>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.work_exp_startDate"
                  class="mb-2"
                  @change="activate($event, 'work_exp_startDate')"
                >
                  Start Date
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.work_exp_startDate"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'work_exp_startDate')"
                >
                </b-form-checkbox>
              </b-col>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.work_exp_endDate"
                  class="mb-2"
                  @change="activate($event, 'work_exp_endDate')"
                >
                  End Date
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.work_exp_endDate"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'work_exp_endDate')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.work_exp_current"
                  unchecked-value="resume"
                  class="mb-2"
                  @change="activate($event, 'work_exp_current')"
                >
                  Current
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.work_exp_current"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'work_exp_current')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col lg="12" xl="12">
                <h6>Education</h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.edu_Institution"
                  class="mb-2"
                  @change="activate($event, 'edu_Institution')"
                >
                  Institution
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.edu_Institution"
                  F
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'edu_Institution')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.edu_degree"
                  class="mb-2"
                  @change="activate($event, 'edu_degree')"
                >
                  Degree
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.edu_degree"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'edu_degree')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.edu_major"
                  class="mb-2"
                  @change="activate($event, 'edu_major')"
                >
                  Major
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.edu_major"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'edu_major')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.edu_location"
                  class="mb-2"
                  @change="activate($event, 'edu_location')"
                >
                  Location
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.edu_location"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'edu_location')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.edu_startDate"
                  class="mb-2"
                  @change="activate($event, 'edu_startDate')"
                >
                  Start Date
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.edu_startDate"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'edu_startDate')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.edu_endDate"
                  class="mb-2"
                  @change="activate($event, 'edu_endDate')"
                >
                  End Date
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.edu_endDate"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'edu_endDate')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.edu_description"
                  class="mb-2"
                  @change="activate($event, 'edu_description')"
                >
                  Description
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.edu_description"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'edu_description')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>

          <b-col class="text-left">
            <b-row class="mb-2">
              <b-col lg="12" xl="12">
                <h6>Documents</h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox v-model="form_fields.resume" class="mb-2">
                  Resume
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.resume"
                  class="mb-2 custom-radio"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8">
                <b-form-checkbox
                  v-model="form_fields.cover_letter"
                  class="mb-2"
                  @change="activate($event, 'cover_letter')"
                >
                  Cover Letter
                </b-form-checkbox>
              </b-col>
              <b-col lg="4">
                <b-form-checkbox
                  v-model="required_fields.cover_letter"
                  class="mb-2 custom-radio"
                  @change="setRequired($event, 'cover_letter')"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-4 text-right">
          <b-col>
            <b-button type="submit" class="btn-theme mr-2">Create</b-button>
            <b-button class="btn-theme-cancel mr-2" @click="hideModal">Cancel</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
export default {
  name: "CreateJobForm",
  props: ["hideModal"],
  data() {
    return {
      input: null,
      checked: true,
      editor: ClassicEditor,
      selected: null,
      status: true,
      employers: [],
      observer: null,
      limit: 20,
      page: 1,
      totalPage: 3,
      search: "",
      countries: ["dsd", "ds"],
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      form: {
        ats: null,
        company: null,
        name: "Collection Form",
      },
      form_fields: {
        first_name: true,
        last_name: true,
        email: true,
        phone_number: false,
        country: false,
        region: false,
        city: false,
        street: false,
        skype: false,
        linke_din: false,
        facebook: false,
        twitter: false,
        website: false,
        resume: false,
        cover_letter: false,
        edu_Institution: false,
        edu_degree: false,
        edu_major: false,
        edu_location: false,
        edu_startDate: false,
        edu_endDate: false,
        edu_description: false,
        work_exp_title: false,
        work_exp_company: false,
        work_exp_current: false,
        work_exp_startDate: false,
        work_exp_endDate: false,
      },
      required_fields: {
        first_name: true,
        last_name: true,
        email: true,
        phone_number: false,
        country: false,
        region: false,
        city: false,
        street: false,
        skype: false,
        linke_din: false,
        facebook: false,
        twitter: false,
        website: false,
        resume: false,
        cover_letter: false,
        edu_Institution: false,
        edu_degree: false,
        edu_major: false,
        edu_location: false,
        edu_startDate: false,
        edu_endDate: false,
        edu_description: false,
        work_exp_title: false,
        work_exp_company: false,
        work_exp_current: false,
        work_exp_startDate: false,
        work_exp_endDate: false,
      },
      value: [],
    }
  },
  methods: {
    activate(event, keyName) {
      this.form_fields[keyName] = event
    },
    setRequired(event, keyName) {
      this.required_fields[keyName] = event
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    async setPage() {
      this.page = this.page + 1
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 20
        this.page += 1
        await this.getEmployers(this.page)
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },

    getEmployers(page) {
      var employer = this.input

      this.$axios
        .get(
          this.base_url +
            "/employers/?with_collection=true&keyword_search=" +
            this.search +
            "&page=" +
            this.page,
          {
            headers: { Authorization: this.token },
          }
        )
        .then((response) => {
          this.employers = [
            ...this.employers,
            ...response.data.results.map((data) => ({
              value: data.id,
              label: data.name,
            })),
          ]
        })

      //  this.$axios
      // .get(this.base_url+"/employers/" + this.id, {
      //   headers: { Authorization: this.token },
      // }).then((response)=>{
      //   this.input.label=response.data.name;
      //   this.input.value=response.data.id;
      // })
    },
    clearFormData() {
      this.form.ats = null
      this.form.company = null
      this.form.name = ""
      this.form.department = ""
      this.form.industry = ""
      this.form.experience_level = ""
      this.form.employment_type = ""
      this.form.description = ""
      this.form.experience = ""
      this.form.skills = []
      this.form.gtm_job = ""
    },
    onSubmit(event) {
      console.log("foo")
      event.preventDefault()
      if (this.checked == true) {
        var status = "active"
      } else {
        var status = "inactive"
      }

      var now = new Date()
      //  var formatted_date = new Date().toJSON().slice(0,10).replace(/-/g,'/');
      var form_fields = this.form_fields
      var form_fields_result = []
      Object.keys(form_fields).forEach((key) => {
        const item = form_fields[key]
        if (item === true) {
          form_fields_result.push(key)
        }
      })
      var required_fields = this.required_fields
      var required_fields_result = []
      Object.keys(required_fields).forEach((key) => {
        const item = required_fields[key]
        if (item === true) {
          required_fields_result.push(key)
        }
      })
      var payload = {
        company: this.form.company,
        name: this.form.name,
        status: status,
        ats: this.form.ats,
        created_at: now,
        form_fields: form_fields_result,
        required_fields: required_fields_result,
      }
      const config = {
        headers: { Authorization: this.token },
      }

      this.$axios
        .post(this.base_url + "/jobs", payload, config)
        .then((response) => {
          if (response.status == 200) {
            event.target.reset()
            this.form.experience = ""
            this.$router.push("/jobs")
          }
        })
        .catch((error) => {
          // if (error.response.data.email) {
          //   this.error.email = error.response.data.email[0];
          // }
          // if (error.response.data.name) {
          //   this.error.name = error.response.data.name[0];
          // }
          // if (error.response.data.logo) {
          //   this.error.logo = error.response.data.logo[0];
          // }
        })
        .finally(() => (this.loading = false))
    },
  },
  watch: {
    search() {
      this.page = 1
      this.limit = 20
      this.employers = []
      this.getEmployers()
    },
  },
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  created: function () {
    this.getEmployers(this.page)
  },
  computed: {
    filtered() {
      return this.employers
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return true
    },
    formDataLength() {
      return this.formData(this.formData, "1")
    },
  },
}
</script>

<style scoped>
.error {
  color: red;
}
select[multiple] {
  height: 150px;
  overflow: auto;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.delete-btn {
  margin-bottom: -20px;
}
</style>
