<template>
  <div class="page-maincontent">
    <PageHeader />
    <StatisticsCard :statsCard="dashboardData.statsCard" />
    <Campaign />
    <WhatsNew :recentCount="recentCount" />
  </div>
</template>

<script>
import PageHeader from "./Header"
import StatisticsCard from "./StatsCard"
import Campaign from "./Campaign"
import WhatsNew from "./WhatsNew"
export default {
  name: "SuperDashboard",
  components: {
    PageHeader,
    StatisticsCard,
    Campaign,
    WhatsNew,
  },
  data() {
    return {
      dashboardData: {
        statsCard: [],
      },
      recentCount: {
        candidates: [],
        employers: [],
        jobs: [],
      },
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },
  mounted() {
    this.getDashboardData()
  },
  methods: {
    getDashboardData() {
      this.$axios
        .get(this.base_url + "/dashboard", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.dashboardData.statsCard = response.data.result[0].cards
        })
      this.$axios
        .get(this.base_url + "/employers/?recent=true", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.recentCount.employers = response.data.result_count
        })
      this.$axios
        .get(this.base_url + "/jobs?recent=true", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.recentCount.jobs = response.data.result_count
        })
      this.$axios
        .get(this.base_url + "/candidates?recent=true", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.recentCount.candidates = response.data.result_count
        })
    },
  },
}
</script>

<style scoped></style>
