<template>
  <div class="page-maincontent">
        <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">ATS Employers</h4>
          <b-button  @click="showModal" size="sm" class="btn-theme" >
            <span class="d-flex align-items-center">
              <span class="material-icons-outlined"> add </span>
              <span>Create</span>
            </span>
          </b-button>

          <b-modal
            ref="my-modal"
            hide-footer
            title="Connect Employer to ATS"
            size="lg"
          >
            <div class="d-block text-center">
              <CreateEmployerUser
              :CreatedEmployerId="CreatedEmployerId"
              @createShowModelHide="createShowModelHide"
            />
            </div>
          </b-modal>
      </div>
    </div>



    <b-card class="custom-card">
      <Details />
    </b-card>
  </div>
</template>

<script>
import Details from "./Details"
import CreateEmployerUser from "../ATS/AddEmpATS.vue";
export default {
  name: "EmployerUsersDetails",
  components: {
    CreateEmployerUser,
    Details,
  },
  data() {
    return {
      id: this.$route.params.id,
      createshow: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      employersDetails: [],
    }
  },
  mounted() {
    this.employerDetail(this.id)
  },
  methods: {
    showModal() {
      console.log(this.$refs)
      this.$refs["my-modal"].show()
    },
        employerDetail(id) {
      this.$axios
        .get(this.base_url + "/ats/" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.employersDetails = response.data.result
          this.available_ATS = response.data.result.employers.map((data) => ({
            createdAt: moment(
                  data.created_at
                ).format("MMMM D, YYYY")
          }))
        })
    },
    createShowModelHide(arg) {
      this.createshow = false;
      this.getEmployerUsers(1);
    },
  },
}
</script>

<style scoped></style>
