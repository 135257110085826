<template>
  <div class="404-page">
    <b-container>
      <b-row class="justify-content-center align-items-center height-100vh">
        <b-col lg="12" xl="12">
          <b-row>
            <b-col lg="12">
              <div class="maintain-image text-center">
                <img
                  class="img-fluid"
                  v-bind:src="errorImage"
                  alt="404-image"
                />
              </div>
              <div class="errror mt-5">
                <h2 class="text-center text-dark font-weight-bold">
                  {{ errorText }}
                </h2>
              </div>
              <div class="back-action text-center mt-5">
                <b-button to="/" class="btn-theme">
                  <span class="d-flex align-items-center">
                    <span class="material-icons-outlined"> chevron_left </span>
                    <span>Back to Dashboard</span>
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "404Page",
  components: {},
  data() {
    return {
      errorImage: require("../assets/images/404-image.svg"),
      errorText: "Sorry, Page Not Found",
    }
  },
}
</script>

<style scoped>
.height-100vh {
  height: calc(100vh - 20px);
}
@media only screen and (max-width: 600px) {
  .height-100vh {
    height: auto;
  }
}
</style>
