<template>
  <div class="job-summary p-4">
    <div class="jobsummary-deatils mb-5">
      <h5 class="font-weight-bold mb-3">Job Summary:</h5>
      {{ jobsDetail.description }}
    </div>
    <div class="job-qualifications mb-5">
      <h5 class="font-weight-bold mb-3">
        Knowledge, skills and qualifications
      </h5>
      <p class="text-secondary">
        <b>Education:</b>
        Bachelor's Degree in Computer Science, or related technical discipline.
      </p>
      <p class="text-secondary">
        <b>Minimum years of work experience:</b> {{ jobsDetail.experience }}
      </p>
    </div>
    <div class="requiredskills mb-5">
      <h5 class="font-weight-bold mb-3">Required Skills:</h5>
      <ul id="required-skills">
        {{
          jobsDetail.skills
        }}
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobSummary",
  props: ["jobsDetail"],
  components: {},
  data() {
    return {}
  },
  method: {},
}
</script>

<style scoped></style>
