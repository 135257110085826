<template>
  <div class="permission-list">
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1"> </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
        <b-form-group
          label="Search"
          label-for="filter-input"
          label-cols-sm="3"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="ml-auto search-filter"
        >
          <b-input-group size="sm">
           <b-form-input
              id="filter-input"
              v-model="filter"
              type="text"
              placeholder="Type to Search"
              @keyup="getPermissionList(currentPage, $event.target.value)"
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="clearSearch(1), (filter = '')"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
     show-empty
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
      :items-provider="getPermissionList"
    >
      <template #cell(roles)="items">
        <span class="mr-3 text-success font-weight-bold">{{
          items.item.roles
        }}</span>
      </template>
      <template #cell(actions)="items">
        <b-link
          @click="editModelShow(items.item.id)"
          class="mr-2"
          variant="primary"
        >
          <span class="material-icons-outlined"> edit </span>
        </b-link>
      </template>
    </b-table>
    <b-row>
      <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
        <b-form-group
          label-for="per-page-select"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="perpage-select"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
        <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
            class="my-0 ml-auto float-right"
          ></b-pagination>
      </b-col>
    </b-row>
    <b-modal
      id="AddPermission"
      title="Edit Permission"
      v-model="editshow"
      hide-footer
    >
      <EditPermission :id="userTypeId" />
      <template #modal-footer>
        <div class="button-action text-center w-100">
          <b-button class="btn-theme" v-on:click="successAlert">
            Submit
          </b-button>
          <b-button variant="danger" @click="$bvModal.hide('AddPermission')">
            Discard
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>
import moment from "moment";
import EditPermission from "./EditPermission";
export default {
  name: "PermissionList",

  components: { EditPermission },

  data() {
    return {
      createshow: false,
      editshow: false,
      checked: false,
      userTypeId: null,
      switchchecked: true,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      JobFilter: null,
      items: [],
      fields: [
        { key: "id", label: "ID",sortable: true },
        { key: "title", label: "Name",sortable: true },
        { key: "roles", label: "Roles",sortable: true },
        {
          key: "timestamp",
          label: "Crated Date",sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              return moment(item.timestamp).format("DD-MM-YYYY");
            } else {
            }
          },
        },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      filter: "",
      totalItems: null,
    };
  },
  mounted() {
    this.getPermissionList(this.currentPage);
  },
  methods: {
       clearSearch() {
      this.filter = "";
      this.items=[];
      this.currentPage=1;
      this.getPermissionList(this.currentPage, (event = ""));
    },
    editModelShow(id) {
      if(this.editshow = false){
         this.editshow = true;
      }
      this.userTypeId = id;
      this.editshow = true;
    },
    getPermissionList(currentPage) {
      this.$axios
        .get(this.base_url + "/permissions/?page=" +
              this.currentPage +
              "&keyword_search=" +
              this.filter, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          console.log(response)
          if(this.filter==""){
            if(this.currentPage==1){
               this.items=[]; 
              }
            this.items = [...this.items,...response.data.results.map((result) => ({
            id: result.id,
            title: result.title,
            timestamp: result.timestamp,
            roles: result.role.map((roles) => roles.title).join(", "),
          }))];
          }
          else{
           if(this.currentPage==1){
               this.items=[]; 
              }
            this.items = [...this.items,...response.data.results.map((result) => ({
            id: result.id,
            title: result.title,
            timestamp: result.timestamp,
            roles: result.role.map((roles) => roles.title).join(", "),
          }))];
          }
          this.current_page = response.data.current_page;
          this.pages = response.data.total_pages;
          this.totalItems = response.data.result_count;
        });
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getPermissionList(value);
      },
    },
  },
};
</script>

<style scoped></style>
