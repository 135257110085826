<template>
  <div id="app" :class="{ sidebartoggle: toggleClass }" class="vertical-menu">
    <div class="simplebar-wrapper">
      <div id="sidebar-menu">
        <b-navbar-nav vertical class="metismenu list-unstyled" id="side-menu">
          <b-nav-item to="/create-job">
            <b-button block class="btn-theme">
              <span class="d-flex align-items-center justify-content-center">
                <span class="material-icons-outlined"> add </span>
                <span>Create a Job</span>
              </span>
            </b-button>
          </b-nav-item>
        </b-navbar-nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
  props: {
    toggleClass: {
      type: Boolean,
    },
  },
}
</script>

<style scoped></style>
