<template>
  <v-select
    :options="paginated"
    :filterable="false"
    :reduce="(option) => option.value"
    label="label"
    @open="onOpen"
    @close="onClose"
    @search="(query) => (search = query)"
    class="mb-3"
    placeholder="Select Employer"
    id="emp"
    v-on:input="getEmployerId($event)"
    name="employerId"
  >
    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader"></li>
    </template>
  </v-select>
</template>
<script>
export default {
  name: "CreateJobForm",
  components: {},
  data() {
    return {
      input: null,

      employers: [],
      observer: null,
      limit: 20,
      page: 1,
      totalPage: 3,
      search: "",
      countries: ["dsd", "ds"],
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },
  methods: {
    activate(event, keyName) {
      this.form_fields[keyName] = event
    },
    setRequired(event, keyName) {
      this.required_fields[keyName] = event
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    async setPage() {
      this.page = this.page + 1
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 20
        this.page += 1
        await this.getEmployers(this.page)
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },

    getEmployers(page) {
      var employer = this.input

      this.$axios
        .get(this.base_url + "/filter-employers?ats=true", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.employers = response.data.result.map((data) => ({
            value: data.id,
            label: data.name,
          }))
        })
    },

    getEmployerId(event) {
      const _this = this
      var employerId = event
      _this.$emit("employerValue", { employerId: employerId })
    },
  },
  watch: {
    search() {
      this.page = 1
      this.limit = 20
      this.employers = []
      this.getEmployers()
    },
  },
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  created: function () {
    this.getjobs(this.page)
  },
  computed: {
    filtered() {
      return this.employers
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return true
    },
    formDataLength() {
      return this.formData(this.formData, "1")
    },
  },
}
</script>

<style scoped>
.ats-box,
.manual-box {
  background-color: #f5f5f5;
  padding: 16px 40px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.ats-box.active,
.manual-box.active {
  background-color: #007bff;
  border: 1px solid #007bff;
  color: #fff;
}
.error {
  color: red;
}
select[multiple] {
  height: 150px;
  overflow: auto;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.delete-btn {
  margin-bottom: -20px;
}
</style>
