<template>
  <div class="edit-logo">
    <b-card class="">
      <div class="card-title mb-3">
        <h4>Edit Employer Logo</h4>
      </div>
      <!--begin::Image input-->
      <div class="image-input">
        <!-- <b-form-file plain></b-form-file> -->
        <div
          class="image-input image-input-outline"
          :style="{
            'background-image': `url(${require('../../assets/images/profile-image.jpg')})`,
          }"
        >
          <!--begin::Preview existing avatar-->
          <div
            v-if="url == ''"
            class="image-input-wrapper w-125px h-125px"
            :style="{
              'background-image': `url(${require('../../assets/images/profile-image.jpg')})`,
            }"
          ></div>
          <div v-if="url" id="preview" @click="changebaseFormat">
            <img :src="url" />
          </div>
          <!--end::Preview existing avatar-->
          <!--begin::Inputs-->
          <input ref="fileInput" type="file" @input="changebaseFormat" />
          <!--end::Inputs-->

          <b-link class="change-avatar" title="Change avatar">
            <span class="material-icons-outlined"> edit </span>
            <b-form-file plain @change="changebaseFormat"></b-form-file>
          </b-link>
        </div>
      </div>
      <template #footer>
        <div class="button-action">
          <b-button type="submit" class="btn-theme mr-2">Submit</b-button>
          <b-button class="btn-theme-cancel mr-2" @click="employerDetail(id)">Cancel</b-button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Logo",
  components: {},
  data() {
    return {
      url: "",
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      form: {
        logo: "",
        name: "",
      },
      error: {
        logo: "",
      },
      id: this.$route.params.id,
    }
  },
  created() {
    this.employerDetail(this.id)
  },
  methods: {
    removeImage() {
      this.url = null
    },
    employerDetail(id) {
      this.$axios
        .get(this.base_url + "/employers/" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          console.log(response.data)
          if (response.data.logo) {
            this.url = response.data.logo
          }
          this.form.name = response.data.name
          this.form.address = response.data.address
          this.form.phone_number = response.data.phone_number
          this.form.email = response.data.email
        })
    },
    changebaseFormat(e) {
      const file = e.target.files[0]
      this.createBase64FormatImage(file)
    },
    createBase64FormatImage(file) {
      const reader = new FileReader()
      let rawImg
      reader.onload = (e) => {
        this.form.logo = reader.result
      }
      reader.readAsDataURL(file)
      this.url = URL.createObjectURL(file)
    },
    onSubmit(event) {
      event.preventDefault()
      if (this.form.logo) {
        var payload = {
          name: this.form.name,
          logo: this.form.logo,
          address: this.form.address,
          phone_number: this.form.phone_number,
          email: this.form.email,
        }
      } else {
        var payload = {
          name: this.form.name,
          address: this.form.address,
          phone_number: this.form.phone_number,
          email: this.form.email,
        }
      }

      const config = {
        headers: { Authorization: this.token },
      }

      this.$axios
        .put(this.base_url + "/employers/" + this.id + "/", payload, config)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              icon: "success",
              text: "Employer Updated successfully 🙂",
              type: "success",
              timer: 1500,
            })
          }
        })
        .catch((error) => {})
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped>
.image-input {
  position: relative;
  display: inline-block;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input .image-input-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input.image-input-outline .image-input-wrapper {
  border: 3px solid #fff;
  box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%);
  height: 125px !important;
  width: 125px !important;
}
.remove-avatar,
.change-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 5px rgb(0 0 0 / 13%);
  background-color: #fff;
  border-radius: 100px;
  color: #4a4a4a;
  padding: 4px;
  position: absolute;
}
.image-input input {
  position: absolute;
  top: -15px;
  z-index: 1;
  width: 30px;
  height: 30px;
  left: 80%;
  opacity: 0;
  cursor: pointer;
}
.remove-avatar:hover,
.change-avatar:hover {
  text-decoration: none;
}
.remove-avatar .material-icons-outlined,
.change-avatar .material-icons-outlined {
  font-size: 18px;
}
.change-avatar,
.remove-avatar {
  left: 94%;
}
.change-avatar {
  top: 0%;
}
.remove-avatar {
  top: 94%;
}
.change-avatar input {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  opacity: 0;
}
#preview {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  margin-top: 15px;
}
#preview img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
