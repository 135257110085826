<template>
  <div class="add-ip">
    <FormulateForm class="text-left" v-model="formData" @submit="submitHandler">
      <FormulateInput
        type="text"
        class="mb-3"
        name="ip_address"
        validation="required"
        placeholder="00.000.00.000"
        v-model="formData.ip_address"
      />
      <b-form-group>
        <div class="d-flex align-items-center customstye">
          <FormulateInput
            type="checkbox"
            name="status"
            v-model="formData.status"
          />
          <span> Enable IP Wightlist</span>
        </div>
      </b-form-group>
      <b-row class="d-flex justify-content-end px-2">
        <b-button class="btn-theme-clear mr-2" @click="clearFormData"> Clear </b-button>
        <FormulateInput
          type="submit"
          class="btn-theme-custom btn-bold text-uppercase"
          label="Submit"
        />
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: "AddIPAddress",
  components: {},
  props: ["ipWightListId"],
  data() {
    return {
      formData: {
        status: false,
      },
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },
  mounted() {},
  methods: {
    clearFormData() {
      this.formData.status = false
      this.formData.ip_address = ""
    },
    async submitHandler(data) {
      var employer = this.input
      if (this.formData.status == false) {
        var status = "inactive"
      } else {
        var status = "active"
      }
      var payload = {
        ip_address: this.formData.ip_address,
        status: status,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      axios
        .post(this.base_url + "/whitelist-security/", payload, config)
        .then((response) => {
          if (response.data.status == 400) {
            this.error.message = response.data.message
            this.error.email = response.email[0]
          }
          if (response.status == 201) {
            const _this = this
            _this.$emit("addShowModelHide", { addshow: false })
          }
        })
        .catch((error) => {
          this.error.message = error.response.data.message
          this.error.email = error.response.data[0].email[0]
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped>
.customstye .formulate-input {
  margin-bottom: 0;
}
.customstye span {
  display: inline-block;
  margin-left: 10px;
  margin-top: -10px;
}
</style>
