<template>
  <div class="template-preview">
    <b-card class="custom-card">
      <template #header>
        <b-button to="/email-template-builder" size="sm" variant="secondary">
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> chevron_left </span>
            <span>Back</span>
          </span>
        </b-button>
      </template>
      <b-row>
        <b-col md="12">
          <div class="template-compose">
            <div class="t-head">
              <p class="font-weight-bold">Hi,</p>
            </div>
            <div class="t-body py-2">
              <p>
                <strong>Template Key: </strong
                >{{ emailTemplateDetail.template_key }}
              </p>
              <p><strong>Subject: </strong>{{ emailTemplateDetail.subject }}</p>
              <p>
                account and get started: --> <strong>Body: </strong>
                {{ emailTemplateDetail.html_template }}
              </p>

              <p>
                account and get started: -->
                <strong>Plain Text: </strong>
                {{ emailTemplateDetail.plain_text }}
              </p>
            </div>
            <div class="t-foot">
              <p class="m-0 font-weight-bold">Welcome a Hoarde,</p>
              <p class="m-0 font-weight-bold">
                {{ emailTemplateDetail.from_email }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      emailTemplateDetail: [],
      id: this.$route.params.id,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },
  mounted() {
    this.getEmailTemplateDetail()
  },
  methods: {
    getEmailTemplateDetail() {
      this.$axios
        .get(this.base_url + "/email-templates/" + this.id + "/", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.emailTemplateDetail = response.data
          let regex = /(<([^>]+)>)/gi
          this.emailTemplateDetail.html_template =
            this.emailTemplateDetail.html_template.replace(regex, "")
          if (this.emailTemplateDetail.plain_text) {
            this.emailTemplateDetail.plain_text =
              this.emailTemplateDetail.plain_text.replace(regex, "")
          } else {
            this.emailTemplateDetail.plain_text = "N/A"
          }
        })
    },
  },
}
</script>
