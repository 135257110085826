<template>
  <div>
    <div class="questions-title">
      <p class="text-secondary">
        <strong>Question:</strong>

        {{ questionAnswer[index].text
        }}<span
          v-if="questionAnswer[index].required == true"
          class="d-inline-block ml-1 mt-2 text-danger"
          >*</span
        >
      </p>
    </div>
    <FormulateInput
      type="text"
      name="questionId"
      :value="questionAnswer[index].id"
      hidden
    />
    <div class="questions-option mt-4">
      <div
        v-if="
          questionAnswer[index].type == 'radio' ||
          questionAnswer[index].type == 'select' ||
          questionAnswer[index].type == 'select-multiple'
        "
      >
        <div v-if="questionAnswer[index].type == 'radio'">
          <FormulateInput
            :options="{ Yes: 'Yes', No: 'No' }"
            type="radio"
            velidation="required"
            name="body"
          />
        </div>

        <div v-if="questionAnswer[index].type == 'select'">
          <b-row>
            <b-col lg="3" xl="3">
              <FormulateInput
                name="body"
                :options="questionAnswer[index].choices"
                type="select"
                placeholder="Select an option"
                validation="required"
                validation-name="This"
              />
            </b-col>
          </b-row>
        </div>

        <div v-if="questionAnswer[index].type == 'select-multiple'">
          <b-row>
            <b-col lg="3" xl="3">
              <div class="multi-ques">
                <label>Note: Please Select Multiple</label>
                <FormulateInput
                  name="body"
                  :options="questionAnswer[index].choices"
                  type="select"
                  placeholder="Select an option"
                  validation="required"
                  :multiple="true"
                  v-model="choices"
                  validation-name="This"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-else>
        <b-row>
          <b-col lg="3" xl="3">
            <FormulateInput
              type="text"
              name="body"
              validation="required"
              validation-name="This"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionsForm",
  props: ["index", "questionAnswer"],

  data() {
    return {
      choices: [],
    }
  },
}
</script>
<style scoped>
.z-index-8 {
  z-index: 8;
}
</style>
