<template>
  <div class="employer-user-details">
    <b-row>
        <b-col lg="3" class="my-1">
        <b-form-group
            label="Employer"
            label-cols-sm="4"
            label-cols-lg="4"
            label-align-sm="right"
            label-size="sm"
            class="ml-auto emp-filter mb-0"
          >
            <v-select
              :options="paginated"
              :filterable="false"
              :reduce="(option) => option.value"
              label="label"
              size="sm"
              label-size="sm"
              @open="onOpen"
              @close="onClose"
              @search="(query) => (search = query)"
              placeholder="Select Employer"
              id="emp"
              v-model="input"
              @input="getEmployerUsers(currentPage)"
            >
              <template #list-footer>
                <li v-show="hasNextPage" ref="load" class="loader"></li>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col lg="9" class="my-1">
        </b-col>
    </b-row>

    <hr/>
    <b-card class="custom-card">
      <b-row>
        <b-col sm="4" md="4" lg="6" xl="6" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3" lg="3" xl="3" class="my-1">
          
        </b-col>
        <b-col sm="8" md="6" lg="3" xl="3" class="my-1">
          <b-form-group
            label="Search"
            label-for="filter-input"
            label-cols-sm="3"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="ml-auto search-filter"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="text"
                placeholder="Type to Search"
                @keyup="getEmployerUsers(currentPage, $event.target.value)"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="clearSearch(1), (filter = '')"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
       show-empty
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      small
      responsive="lg"
      outlined
      hover
      head-variant="light"
      :items-provider="getEmployerUsers"
      >
      </b-table>

      <b-row>
        <b-col sm="4" md="3" lg="4" xl="3" class="my-1">
          <b-form-group
            label-for="per-page-select"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="perpage-select"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="8" md="6" lg="8" xl="9" class="my-1">
          <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
            class="my-0 ml-auto float-right"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap/dist/css/bootstrap.min.css"></link>
<link
  type="text/css"
  rel="stylesheet"
  href="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.css"></link>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.22/vue.js"></script>
<script src="//unpkg.com/babel-polyfill@latest/dist/polyfill.min.js"></script>
<script src="//unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.js"></script>
<script>
import CreateEmployerUser from "../AllEmployerUsersList/CreateEmployerUser.vue";

export default {
  name: "EmployerUsersList",
  components: {
    CreateEmployerUser,
  },
  data() {
    return {
      input: null,
      createshow: false,
      editshow: false,
      id: this.$route.params.id,
      CreatedEmployerId: null,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      items: [],
      employers: [],
      formData: {},
      observer: null,
      limit: 20,
      page: 1,
      totalPage: null,
      search: "",
      countries: ["dsd", "ds"],
      fields: [
        { key: "id", label: "ID",sortable: true },
        { key: "username", label: "User Name",sortable: true },
        { key: "employer", label: "Employer",sortable: true },
        { key: "email", label: "User Email",sortable: true },
        { key: "phone_number", label: "Phone Number",sortable: true },
        { key: "type", label: "User Type",sortable: true },
        { key: "role", label: "Users Roles",sortable: true },
        { key: "status", label: "Status",sortable: true },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      pages: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20],
      sortBy: "",
      filter: "",
      filterOn: [],
      totalItems: null,
    };
  },

  methods: {
    getUserDetail(id){
      this.$router.push("/users/"+id);
    },
    activate(arg) {
      this.isHidden = arg.isHidden;
    },
    clearFormData() {
      this.formData.userData = "";
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    async setPage() {
      this.page = this.page + 1;
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 20;
        this.page += 1;
        await this.getEmployers(this.page);
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    getEmployers(page) {
      var employer = this.input;
      this.$axios
        .get(
          this.base_url +
            "/employers/?keyword_search=" +
            this.search +
            "&page=" +
            this.page,
          {
            headers: { Authorization: this.token },
          }
        )
        .then((response) => {
          this.totalPage = response.data.total_pages;
          this.current_page = response.data.current_page;
          this.employers = [
            ...this.employers,
            ...response.data.results.map((data) => ({
              value: data.id,
              label: data.name,
            })),
          ];
        });
    },
   
    createUserModal() {
      this.$swal(
        "Added Successfully",
        "You are successfully added this user",
        "OK"
      );
    },
    getEmployerUsers(currentPage, event = "") {
      var employer = this.input;
      if (employer == null) {
        this.$axios
          .get(
            this.base_url +
              "/users?employer=&page=" +
              this.currentPage +
              "&keyword_search=" +
              this.filter,
            {
              headers: { Authorization: this.token },
            }
          )
          .then((response) => {
            this.currentPage = response.data.current_page;
            if(this.filter==""){
              if(this.currentPage==1){
               this.items=[]; 
              }
            this.items = [...this.items,...response.data.results];
            }
            else{
              if(this.currentPage==1){
               this.items=[]; 
              }
              this.items = [...this.items,...response.data.results];
            }
            this.pages = response.data.total_pages;
            this.totalItems = response.data.result_count;
          });
      } else {
        this.$axios
          .get(
            this.base_url +
              "/users?employer=" +
              employer +
              "&page=" +
              this.currentPage +
              "&keyword_search=" +
              this.filter,
            {
              headers: { Authorization: this.token },
            }
          )
          .then((response) => {
            this.currentPage = response.data.current_page;
             if(employer !=null){
              if(this.currentPage==1){
               this.items=[]; 
              }
              this.items = [...this.items,...response.data.results];
            }
           
            this.pages = response.data.total_pages;
            this.totalItems = response.data.result_count;
          });
      }
    },
   
    clearSearch() {
      this.filter = "";
      this.getEmployerUsers(this.currentPage, (event = ""));
    },
   
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.getEmployerUsers(value);
      },
    },
  },
  search() {
    this.page = 1;
    this.limit = 20;
    this.employers = [];
    this.getEmployers();
  },
  mounted() {
    this.CreatedEmployerId = this.id;
    this.getEmployerUsers(this.currentPage, (event = ""));
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  created: function () {
    this.getEmployers(this.page);
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filtered() {
      return this.employers;
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return true;
    },
    formDataLength() {
      return this.formData(this.formData, "1");
    },
  },
};
</script>

<style scoped></style>
