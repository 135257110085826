<template>
  <div class="page-maincontent">
    <CreateEmployerForm />
  </div>
</template>

<script>
import CreateEmployerForm from "./CreateEmployerForm.vue"

export default {
  loader: false,
  name: "CreateEmployeer",
  components: {
    CreateEmployerForm,
  },
}
</script>

<style scoped></style>
