<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          Campaign
          <span class="mr-2 text-secondary fs-8"
            >({{ totalRecords }} Campaign)</span
          >
        </h4>
        <b-button
          size="sm"
          class="btn-theme"
          v-b-modal.uploadCampaign
          @click="createshow == true"
        >
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> upload </span>
            <span>Upload</span>
          </span>
        </b-button>
      </div>
    </div>
    <!-- Upload Campaign Modal -->
    <b-modal
      id="uploadCampaign"
      title="Update Campaign"
      v-model="createshow"
      hide-footer
    >
      <UploadCampaign @createShowModelHide="createShowModelHide" />
    </b-modal>
    <RecentCampaigns @totalRecord="totalRecord" />
  </div>
</template>

<script>
import RecentCampaigns from "../Campaign/RecentCampaigns.vue"
import UploadCampaign from "../Campaign/UploadCampaign.vue"
export default {
  name: "Campaign",
  components: {
    RecentCampaigns,
    UploadCampaign,
  },
  data() {
    return {
      createshow: false,
      totalRecords: null,
    }
  },
  methods: {
    createShowModelHide(arg) {
      this.createshow = false
    },
    successAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to add this Campaign",
        type: "success",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes Add it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal("Success", "You successfully add this Campaign", "success")
        } else {
          this.$swal("Cancelled", "Your Campaign is still intact", "info")
        }
      })
    },
    totalRecord(arg) {
      this.totalRecords = arg.totalRecords
    },
  },
}
</script>

<style scoped></style>
