<template>
  <div class="page-maincontent">
      <JobForm />
  </div>
</template>

<script>
import JobForm from './JobForm.vue';

  export default {
      name: 'CreateJob',
      components:{
        JobForm,
      },
    }
  </script>

<style scoped> 
  