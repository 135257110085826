<template>
   <div class="page-maincontent">
     
    <UsersDetails v-if="role == 1 && employer == ''"/>
    <UsersList/>
    
  </div> 
</template>

<script>
import UsersList from './EmployerUsersList.vue'
import UsersDetails from './EmployerUsersDetails.vue'
export default {
  name: 'EmployerUsers',

  components:{
    UsersList,
    UsersDetails
  },
   data() {
    return {
      role: null,
      employer: "",
    }
   },
     mounted() {
    this.setRoute();
  },
  methods:{
    setRoute() {
      var userDetails = localStorage.getItem("user");
      var parseData = JSON.parse(userDetails);
      var role = null;
      if (parseData.hasOwnProperty("role")) {
        role = parseData.role;
      }
      if (parseData.hasOwnProperty("role_id")) {
        role = parseData.role_id;
      }
      var employer = parseData.employer;
      if (role == 1 && employer == null) {
        this.role = role;
        this.employer = "";
      } else {
        this.role = role;
        this.employer = employer;
      }
    },
  }
}
</script>

<style scoped>
 

</style>
