<template>
    <div class="maintenance-page">
        <b-container>
            <b-row class="justify-content-center align-items-center height-100vh">
                <b-col lg="12" xl="12">
                    <b-row>
                        <b-col lg="12">
                            <div class="maintenance-image text-center">
                                <img class="img-fluid" v-bind:src="errorImage" alt="500-image">
                            </div>
                            <div class="errror text-center mt-5">
                                <h2 class="text-dark font-weight-bold">
                                    {{errorText}}
                                </h2>
                                <p class="text-muted">
                                    {{errorSubText}}
                                </p>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mt-md-4">
                        <b-col lg="4" v-for="m in maintenance" v-bind:key="m.id">
                            <b-card class="custom-card text-center">
                                <div class="maintenance-icon mb-3">
                                    <span class="material-icons-outlined icon-round">
                                        {{m.icon}}
                                    </span>
                                </div>
                                <div class="maintenance-text">
                                    <h4 class="font-weight-bold">
                                        {{m.title}}
                                    </h4>
                                    <p class="fs-8 m-0">
                                        {{m.desc}}
                                    </p>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div> 
</template>

<script>
    export default {
    name: 'Maintenance',
    components:{
        
    },
    data(){
        return{
            errorImage:require('../assets/images/image-maintenance.svg'),
            errorText:'Site is Under Maintenance',
            errorSubText:'Please check back in sometime.',
            maintenance:[
                {id:'1', icon:'wifi', title:'Why is the Site Down?', desc:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.'},
                {id:'2', icon:'schedule', title:'What is the Downtime?', desc:'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical.'},
                {id:'3', icon:'email', title:'Do you need Support?', desc:'If you are going to use a passage of Lorem Ipsum, you need to be sure there is not anything email.. no-reply@domain.com'}
            ]
        }
    }
    }
</script>

<style scoped>
   .height-100vh{
       height: calc(100vh - 20px);
   }
   .icon-round{
       color: #6D68A8;
       width: 80px;
       height: 80px;
       line-height: 80px;
       border-radius: 100px;
       font-size: 40px;
       background-color: #D2D0EB;
   }
   @media only screen and (max-width: 600px) {
        .height-100vh{
            height:auto;
        }
    }
</style>
