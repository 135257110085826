<script>
/* Imports */
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"

export default {
  name: "FunnelChart",
  components: {},
  props: ["type"],
  data() {
    return {
      id: this.$route.params.id,
      typeCompagin: this.type ? this.type : "",
      funnlChart: "",
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },
  mounted() {
    this.getChart()
  },
  methods: {
    getChart() {
      let statistics = []
      if (this.typeCompagin == "Compaign") {
        this.$axios
          .get(this.base_url + "/campaigns/" + this.id, {
            headers: { Authorization: this.token },
          })
          .then((response) => {
            const userDetails = response.data
            const memberString = JSON.stringify(userDetails)

            JSON.parse(memberString, (key, value) => {
              if (key == "click" || key == "impression" || key == "job") {
                statistics.push({ name: key, value: value })
              }

              if (key == "click") {
                statistics.push({ name: "impressions", value: "2%" })
                statistics.push({ landings: "impressions", value: "3%" })
              }
              /* Chart code */

              // Themes begin
              am4core.useTheme(am4themes_animated)
              // Themes end

              let fchart = am4core.create("funnlChart", am4charts.SlicedChart)
              // Create chart instance

              fchart.hiddenState.properties.opacity = 0 // this makes initial fade in effect
              // funnlChart.data = data,
              fchart.data = statistics

              let series = fchart.series.push(new am4charts.FunnelSeries())
              series.colors.step = 2
              series.dataFields.value = "value"
              series.dataFields.category = "name"
              series.alignLabels = true

              series.labelsContainer.paddingLeft = 15
              series.labelsContainer.width = 180

              //series.orientation = "horizontal";
              //series.bottomRatio = 1;

              fchart.legend = new am4charts.Legend()
              fchart.legend.position = "left"
              fchart.legend.valign = "middle"
              fchart.legend.margin(5, 5, 30, 5)

              fchart.responsive.enabled = true

              //Responsive Legend Position Changed
              fchart.responsive.rules.push({
                relevant: function (target) {
                  if (target.pixelWidth <= 767) {
                    return true
                  }
                  return false
                },
                state: function () {
                  fchart.legend.position = "bottom"
                  fchart.legend.valign = "bottom"
                },
              })
            })
            //  this.statistics=statistics;
          })
      } else {
        this.$axios
          .get(this.base_url + "/dashboard", {
            headers: { Authorization: this.token },
          })
          .then((response) => {
            const userDetails = response.data.result[0].statistics
            const memberString = JSON.stringify(userDetails)

            JSON.parse(memberString, (key, value) => {
              statistics.push({ name: key, value: value })

              /* Chart code */

              // Themes begin
              am4core.useTheme(am4themes_animated)
              // Themes end

              let fchart = am4core.create("funnlChart", am4charts.SlicedChart)
              // Create chart instance

              fchart.hiddenState.properties.opacity = 0 // this makes initial fade in effect
              // funnlChart.data = data,
              fchart.data = statistics

              let series = fchart.series.push(new am4charts.FunnelSeries())
              series.colors.step = 2
              series.dataFields.value = "value"
              series.dataFields.category = "name"
              series.alignLabels = true

              series.labelsContainer.paddingLeft = 15
              series.labelsContainer.width = 180

              //series.orientation = "horizontal";
              //series.bottomRatio = 1;

              fchart.legend = new am4charts.Legend()
              fchart.legend.position = "left"
              fchart.legend.valign = "middle"
              fchart.legend.margin(5, 5, 30, 5)

              fchart.responsive.enabled = true

              //Responsive Legend Position Changed
              fchart.responsive.rules.push({
                relevant: function (target) {
                  if (target.pixelWidth <= 767) {
                    return true
                  }
                  return false
                },
                state: function () {
                  fchart.legend.position = "bottom"
                  fchart.legend.valign = "bottom"
                },
              })
            })
          })
      }
    },
  },
}
</script>
