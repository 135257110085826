<template>
  <div class="edit-user">
    <b-form class="form-horizontal">
      <b-row>
        <b-col lg="12" xl="12">
          <b-form-group id="user-name" label="User Name">
            <b-form-input
              id="user-name"
              value="John Doe"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" xl="12">
          <b-form-group id="user-address" label="Email address">
            <b-form-input
              id="user-address"
              value="johndoe@yourdomain.com"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" xl="12">
          <b-form-group id="user-phone" label="Phone Number">
            <PhoneNumber />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" xl="12">
          <b-form-group id="user-role" label="User Role">
            <b-form-select
              v-model="selected"
              :options="roleoptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import PhoneNumber from "../../components/PhoneNumberInput"

export default {
  name: "EditUser",
  components: {
    PhoneNumber,
  },
  data() {
    return {
      selected: "a",
      roleoptions: [
        { value: "a", text: "Administrator" },
        { value: "b", text: "Analyst" },
        { value: "c", text: "Developer" },
        { value: "d", text: "Support" },
        { value: "e", text: "Trial" },
      ],
    }
  },
}
</script>

<style scoped></style>
