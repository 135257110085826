<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Candidates</h4>
                  <b-dropdown
            text="Select Job"
            id="dropdown-buttons" right 
            variant="link"
            size="sm"
            toggle-class="text-decoration-none text-white"
            class="btn-theme"
          >
          <b-dropdown-item to="/jobs/1/form">
            <span>****todo*** Return the list to Jobs and redirect to form</span>
          </b-dropdown-item>
          </b-dropdown>

          <b-modal
            ref="my-modal"
            hide-footer
            title="Create job collection fields"
            size="xl"
          >
            <div class="d-block text-center">
              <CollectionForm :hideModal="hideModal" />
            </div>
          </b-modal>

      </div>
    </div>
    <b-card class="custom-card">
      <Applicants />
    </b-card>
  </div>
</template>

<script>
import Applicants from "../Candidates/Applicants.vue"
export default {
  name: "Candidates",
  components: {
    Applicants,
  },
}
</script>

<style scoped></style>
