<template>
  <div class="candidate-detail">
    <b-card class="custom-card mb-3">
      <SidebarColumn :candidateDetail="candidateDetail" />
      <!-- <b-col xl="9">
          <ContentFluid :candidateDetail="candidateDetail" />
        </b-col> -->
    </b-card>
  </div>
</template>

<script>
import SidebarColumn from "./SidebarColumn.vue"
import ContentFluid from "./ContentFluid.vue"
export default {
  name: "AppliedDetails",
  components: {
    SidebarColumn,
    ContentFluid,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      id: this.$route.params.id,
      candidateDetail: [],
    }
  },
  mounted() {
    this.getCandidateDetail()
  },
  methods: {
    getCandidateDetail() {
      this.$axios
        .get(this.base_url + "/candidates/" + this.id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.candidateDetail = response.data
          // this.pages= this.items.length/10;
        })
    },
    errorAlert() {
      var id = id
      this.$swal({
        title: "Are you sure?",
        text: "You want to delete this Candidate",
        type: "success",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$axios
            .delete(this.base_url + "/candidates/" + this.id, {
              headers: { Authorization: this.token },
            })
            .then((response) => {
              if (response.status == 200) {
                this.$swal("Success", response.data.message, "success")
                this.$router.push("/candidates")
              }
            })
        } else {
          this.$swal("Cancelled", "Your Candidate is still intact", "info")
        }
      })
    },
  },
}
</script>

<style scoped></style>
