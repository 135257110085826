import VueRouter from "vue-router"

// Admin pages
import Login from "./components/Login.vue"
import setupAccount from "./components/setupAccount.vue"
import Register from "./components/Register.vue"
import ForgotPassword from "./components/ForgotPassword.vue"
import MFAAuth from "./components/MFAAuth.vue"
import FourZeroFour from "./components/404.vue"
import FiveHundred from "./components/500.vue"
import Maintenance from "./components/Maintenance.vue"
import EmployerDashboard from "./EmpDashboard/Dashboard/index.vue"
import SuperAdminDashboard from "./EmpDashboard/Dashboard/SuperAdminDashboard.vue"
import CreateEmployer from "./EmpDashboard/CreateEmployer/index.vue"
import Employers from "./EmpDashboard/Employers/index.vue"
import EmployerUsers from "./EmpDashboard/EmployerUsers/index.vue"
import AllEmployerUsersList from "./EmpDashboard/AllEmployerUsersList/index.vue"
import EditEmployer from "./EmpDashboard/EditEmployer/index.vue"
import PartnersList from "./EmpDashboard/Partners/index.vue"
import CreatePartner from "./EmpDashboard/CreatePartner/index.vue"
import PartnerUsers from "./EmpDashboard/PartnerUsers/index.vue"
import EditPartner from "./EmpDashboard/EditPartner/index.vue"
import JobsListing from "./EmpDashboard/Jobs/index.vue"
import CreateJob from "./EmpDashboard/CreateJob/index.vue"
import ImportFromAts from "./EmpDashboard/CreateJob/ImportFromAts.vue"
import ImportATSPopUp from "./EmpDashboard/CreateJob/ImportATSPopUp.vue"
import JobForm from "./EmpDashboard/CreateJob/JobFormIndex.vue"
import EditJob from "./EmpDashboard/EditJob/index.vue"
import JobsDetails from "./EmpDashboard/JobDetail/index.vue"
import Candidates from "./EmpDashboard/Candidates/index.vue"
import CandidateDetail from "./EmpDashboard/CandidatesDetails/index.vue"
import Campaign from "./EmpDashboard/Campaign/index.vue"
import CampaignDetail from "./EmpDashboard/CampaignDetail/index.vue"
import NotifiactionDetail from "./EmpDashboard/Notification/NotificationDetailIndex.vue"
import ATS from "./EmpDashboard/ATS/index.vue"
import ATSDetail from "./EmpDashboard/ATSdetails/index.vue"
import Security from "./EmpDashboard/Security/index.vue"
import AccountDetail from "./EmpDashboard/Security/AccountDetailIndex.vue"
import Localization from "./EmpDashboard/Localisation/index.vue"
import Permission from "./EmpDashboard/Permission/index.vue"
import EmployerReports from "./EmpDashboard/EmployerReports/index.vue"
import PartnerReports from "./EmpDashboard/PartnerReports/index.vue"
import ApplicantReports from "./EmpDashboard/ApplicantReports/index.vue"
import JobReports from "./EmpDashboard/JobReports/index.vue"
import EmailTemplateBuilder from "./EmpDashboard/EmailTemplateBuilder/index.vue"
import CreateEmailTemplate from "./EmpDashboard/CreateEmailTemplate/index.vue"
import EmailBuilderPreview from "./EmpDashboard/EmailBuilderPreview/index.vue"
import EditEmailTemplate from "./EmpDashboard/EditEmailTemplate/index.vue"
import ScreeningQuestions from "./EmpDashboard/ScreeningQuestions/index.vue"
import CreateScreeningQuestion from "./EmpDashboard/CreateScreeningQuestion/index.vue"
import EditScreeningQuestion from "./EmpDashboard/EditScreeningQuestion/index.vue"
import ScreeningQuestionPreview from "./EmpDashboard/ScreeningQuestionPreview/index.vue"
import WelcomeDashboard from "./EmpDashboard/WelcomeDashboard/index.vue"
import Users from "./EmpDashboard/Users/index.vue"
import UsersDetails from "./EmpDashboard/UsersDetails/index.vue"
import ResetPassword from "./EmpDashboard/ResetPassword/ResetPassword.vue"

import TestForm from "./components/TestForm"

// Common Component
import EmployerLayout from "./layout/EmployerLayout.vue"
import EmptyLayout from "./layout/EmptyLayout.vue"

// Routes Starting
const routes = [
  {
    path: "/",
    component: EmployerLayout,
    children: [
      {
        path: "/index",
        component: EmployerDashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "/dashboard",
        component: SuperAdminDashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "/",
        // redirect: '/login',
        meta: { requiresAuth: true },
        // meta:{shouldShowWelcomeSidebar:true},
        component: WelcomeDashboard,
      },

      {
        path: "/create-employer",
        component: CreateEmployer,
        meta: { requiresAuth: true },
      },
      {
        path: "/employers",
        component: Employers,
        meta: { requiresAuth: true },
      },
      {
        path: "/employer-users/:id",
        component: EmployerUsers,
        meta: { requiresAuth: true },
      },

      {
        path: "/employer-users",
        component: AllEmployerUsersList,
        meta: { requiresAuth: true },
      },
      {
        path: "/update-employer/:id",
        component: EditEmployer,
        meta: { requiresAuth: true },
      },
      {
        path: "/partners",
        component: PartnersList,
        meta: { requiresAuth: true },
      },
      {
        path: "/create-partner",
        component: CreatePartner,
        meta: { requiresAuth: true },
      },
      {
        path: "/partner-users/:id",
        component: PartnerUsers,
        meta: { requiresAuth: true },
      },
      {
        path: "/update-partner/:id",
        component: EditPartner,
        meta: { requiresAuth: true },
      },
      {
        path: "/jobs",
        component: JobsListing,
        meta: { requiresAuth: true },
      },
      {
        path: "/create-job",
        component: CreateJob,
        meta: { requiresAuth: true },
      },
      {
        path: "/create-job-type",
        component: ImportATSPopUp,
        meta: { requiresAuth: true },
      },
      {
        path: "/import-from-ats",
        component: ImportFromAts,
        meta: { requiresAuth: true },
      },
      {
        path: "/jobs/:id",
        component: JobsDetails,
        name: "JobsDetails",
        props: true,
        meta: { requiresAuth: true },
      },
      {
        path: "/update-job/:id",
        component: EditJob,
        meta: { requiresAuth: true },
      },

      {
        path: "/candidates",
        component: Candidates,
        meta: { requiresAuth: true },
      },
      {
        path: "/candidates/:id",
        component: CandidateDetail,
        meta: { requiresAuth: true },
      },

      {
        path: "/applicants",
        component: Candidates,
        meta: { requiresAuth: true },
      },
      {
        path: "/campaign",
        component: Campaign,
        meta: { requiresAuth: true },
      },
      {
        path: "/campaign/:id",
        component: CampaignDetail,
        meta: { requiresAuth: true },
      },
      {
        path: "/ats",
        component: ATS,
        meta: { requiresAuth: true },
      },
      {
        path: "/ats/:id",
        component: ATSDetail,
        meta: { requiresAuth: true },
      },
      {
        path: "/security",
        component: Security,
        meta: { requiresAuth: true },
      },
      {
        path: "/account-detail",
        component: AccountDetail,
        meta: { requiresAuth: true },
      },
      {
        path: "/localization",
        component: Localization,
        meta: { requiresAuth: true },
      },
      {
        path: "/permission",
        component: Permission,
        meta: { requiresAuth: true },
      },
      {
        path: "/employer-reports",
        component: EmployerReports,
        meta: { requiresAuth: true },
      },
      {
        path: "/partner-reports",
        component: PartnerReports,
        meta: { requiresAuth: true },
      },
      {
        path: "/applicant-reports",
        component: ApplicantReports,
        meta: { requiresAuth: true },
      },
      {
        path: "/job-reports",
        component: JobReports,
        meta: { requiresAuth: true },
      },
      {
        path: "/users",
        component: Users,
        meta: { requiresAuth: true },
      },
      {
        path: "/users/:id",
        component: UsersDetails,
        meta: { requiresAuth: true },
      },
      {
        path: "/email-template-builder",
        component: EmailTemplateBuilder,
      },
      {
        path: "/create-email-template",
        component: CreateEmailTemplate,
      },
      {
        path: "/email-builder-preview/:id",
        component: EmailBuilderPreview,
      },
      {
        path: "/edit-email-template/:id",
        component: EditEmailTemplate,
      },
      {
        path: "/screening-questions",
        component: ScreeningQuestions,
      },
      {
        path: "/create-screening-question",
        component: CreateScreeningQuestion,
      },
      {
        path: "/edit-screening-question/:id",
        component: EditScreeningQuestion,
      },
      {
        path: "/screening-question/:id",
        component: ScreeningQuestionPreview,
      },
      {
        path: "/screening-question-preview",
        component: ScreeningQuestionPreview,
      },
      {
        path: "/notifiaction-detail/:id",
        component: NotifiactionDetail,
      },
    ],
  },
  {
    path: "/auth",
    component: EmptyLayout,
    children: [
      {
        path: "/login",
        component: Login,
      },
      {
        path: "/setup-account/:email",
        component: setupAccount,
      },
      {
        path: "/register",
        component: Register,
      },
      {
        path: "/forgot-password",
        component: ForgotPassword,
      },
      {
        path: "/404",
        component: FourZeroFour,
      },
      {
        path: "/500",
        component: FiveHundred,
      },
      {
        path: "/maintenance",
        component: Maintenance,
      },
      {
        path: "/jobs/:id/form",
        component: JobForm,
      },
      {
        path: "/reset-password/:id",
        component: ResetPassword,
      },
      {
        path: "/mfa-auth",
        component: MFAAuth,
      },

      {
        path: "/test/form",
        component: TestForm,
      },
    ],
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
