<template>
  <div class="edit-employer">
    <b-card class="">
      <div class="card-title mb-3">
        <h4>Edit Basic information</h4>
      </div>
      <b-form class="form-horizontal" @submit="onSubmit">
        <b-row>
          <b-col lg="4" xl="6">
            <b-form-group>
              <div class="customlabel">
                <label for="">Employer Name</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="emp-name"
                v-model="form.name"
                type="text"
                v-on:keyup="removeValidationMessage('name')"
              ></b-form-input>
              <p class="error" v-if="error.name">
                {{ error.name }}
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="6">
            <b-form-group>
              <div class="customlabel">
                <label for="">Email Address</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="email-address"
                v-model="form.email"
                type="email"
                v-on:keyup="removeValidationMessage('email')"
              ></b-form-input>
              <p class="error" v-if="error.email">
                {{ error.email }}
              </p>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" xl="6">
            <b-form-group>
              <div class="customlabel">
                <label for="">Phone Number</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <VuePhoneNumberInput v-model="phone_number" @update="onUpdate" />
              <p class="error" v-if="error.phone_number">
                {{ error.phone_number }}
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="6">
            <b-form-group id="adds">
              <div class="customlabel">
                <label for="">Address1</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="textarea"
                v-model="form.address1"
                type="text"
                v-on:keyup="removeValidationMessage('address')"
              ></b-form-input>
              <p class="error" v-if="error.address1">
                {{ error.address1 }}
              </p>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" xl="6">
            <b-form-group id="addres">
              <div class="customlabel">
                <label for="">Address2</label>
              </div>
              <b-form-input
                id="address2"
                v-model="form.address2"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="6">
            <b-form-group id="cities">
              <div class="customlabel">
                <label for="">City</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="city"
                v-model="form.city"
                type="text"
                v-on:keyup="removeValidationMessage('city')"
              ></b-form-input>
              <p class="error" v-if="error.city">
                {{ error.city }}
              </p>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" xl="6">
            <b-form-group id="postal_cod">
              <div class="customlabel">
                <label for="">Postal Code</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="zip_code"
                v-model="form.zip_code"
                type="text"
                v-on:keyup="removeValidationMessage('zip_code')"
              ></b-form-input>

              <p class="error" v-if="error.zip_code">
                {{ error.zip_code }}
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="6">
            <b-form-group id="state_or_provinc">
              <div class="customlabel">
                <label for="">State or Province</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="state_or_province"
                v-model="form.state_or_province"
                type="text"
                v-on:keyup="removeValidationMessage('state_or_province')"
              ></b-form-input>
              <p class="error" v-if="error.state_or_province">
                {{ error.state_or_province }}
              </p>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" xl="6">
            <b-form-group id="country">
              <div class="customlabel">
                <label for="">Country or Region</label>
                <span class="material-icons-outlined">*</span>
              </div>
              <b-form-input
                id="country_or_region"
                v-model="form.country"
                type="text"
                v-on:keyup="removeValidationMessage('country')"
              ></b-form-input>
              <p class="error" v-if="error.country">
                {{ error.country }}
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="4" xl="6">
            <b-form-group id="emp-website" label="Employer Website">
              <b-form-input
                id="emp-website"
                value="www.comanyname.com"
                v-model="form.website"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
          <b-button type="submit" class="btn-theme mr-2">Submit</b-button>
          <b-button class="btn-theme-cancel mr-2" @click="employerDetail(id)">Cancel</b-button>
          <b-button class="btn-theme-clear mr-2" @click="clearFormData">Clear</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input"
import "vue-phone-number-input/dist/vue-phone-number-input.css"
export default {
  name: "BasicInfo",
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      phoneIsValid: false,
      phone_number: "",
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      form: {
        name: "",
        email: "",
        website: "",
        address1: "",
        address2: "",
        city: "",
        zip_code: "",
        state_or_province: "",
        country: "",
      },
      error: {
        email: "",
        name: "",
        phone_number: "",
        address1: "",
        city: "",
        zip_code: "",
        state_or_province: "",
        country: "",
      },
      id: this.$route.params.id,
    }
  },
  created() {
    this.employerDetail(this.id)
  },
  methods: {
    onUpdate(payload) {
      this.phoneIsValid = payload.isValid
      if (this.phone_number != "") {
        if (payload.isValid == false) {
          this.error.phone_number = "Phone Number is wrong"
        } else {
          this.error.phone_number = ""
        }
      }
    },
    clearFormData() {
      this.form.name = ""
      this.form.email = ""
      this.form.address1 = ""
      this.form.address2 = ""
      this.form.city = ""
      this.form.zip_code = ""
      this.form.state_or_province = ""
      this.form.country = ""
      this.phone_number = ""
      this.form.website = ""
    },
    removeValidationMessage(arg) {
      if (arg == "name") {
        this.error.name = ""
        this.error.phone_number = ""
      }
      if (arg == "email") {
        this.error.email = ""
        this.error.phone_number = ""
      }
      if (arg == "address") {
        this.error.address1 = ""
        this.error.phone_number = ""
      }
      if (arg == "city") {
        this.error.city = ""
      }
      if (arg == "zip_code") {
        this.error.zip_code = ""
        this.error.phone_number = ""
      }
      if (arg == "state_or_province") {
        this.error.state_or_province = ""
        this.error.phone_number = ""
      }
      if (arg == "country") {
        this.error.country = ""
        this.error.phone_number = ""
      }
    },
    onSubmit(event) {
      event.preventDefault()
      var payload = {
        name: this.form.name,
        email: this.form.email,
        address1: this.form.address1,
        address2: this.form.address2,
        city: this.form.city,
        zip_code: this.form.zip_code,
        state_or_province: this.form.state_or_province,
        country: this.form.country,
        website: this.form.website,
        phone_number: this.phone_number,
      }
      const config = {
        headers: { Authorization: this.token },
      }
      if (
        this.phone_number == "" ||
        this.form.address1 == "" ||
        this.form.city == "" ||
        this.form.zip_code == "" ||
        this.form.state_or_province == "" ||
        this.form.country == "" ||
        this.form.name == "" ||
        this.form.email == ""
      ) {
        if (this.phone_number == "") {
          this.error.phone_number = "This field may not be blank."
        }
        if (this.form.address1 == "") {
          this.error.address1 = "This field may not be blank."
        }
        if (this.form.email == "") {
          this.error.email = "This field may not be blank."
        }
        if (this.form.name == "") {
          this.error.name = "This field may not be blank."
        }
        if (this.form.city == "") {
          this.error.city = "This field may not be blank."
        }
        if (this.form.zip_code == "") {
          this.error.postal_code = "This field may not be blank."
        }
        if (this.form.state_or_province == "") {
          this.error.state_or_province = "This field may not be blank."
        }
        if (this.form.country == "") {
          this.error.country = "This field may not be blank."
        }
      } else {
        if (this.phoneIsValid == true) {
          this.$axios
            .put(this.base_url + "/employers/" + this.id + "/", payload, config)
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  icon: "success",
                  text: "Employer Updated successfully 🙂",
                  type: "success",
                  timer: 1500,
                })
              }
            })
            .catch((error) => {
              if (error.response.data.email) {
                this.error.email = error.response.data.email[0]
              }
              if (error.response.data.name) {
                this.error.name = error.response.data.name[0]
              }
            })
            .finally(() => (this.loading = false))
        }
      }
    },
    employerDetail(id) {
      this.$axios
        .get(this.base_url + "/employers/" + id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.form.name = response.data.name
          this.form.email = response.data.email
          this.form.website = response.data.website
          this.form.address1 = response.data.address1
          this.form.address2 = response.data.address2
          this.form.city = response.data.city
          this.form.zip_code = response.data.postal_code
          this.form.country = response.data.country
          this.form.state_or_province = response.data.state_or_province
          this.phone_number = response.data.phone_number
          if (this.phone_number != "") {
            this.phoneIsValid = true
          }
        })
    },
  },
}
</script>

<style scoped></style>
