<template>
  <div class="page-maincontent">
    <AccountDetail />
  </div>
</template>

<script>
import AccountDetail from "./AccountDetail"
export default {
  name: "AccountDetailIndex",

  components: {
    AccountDetail,
  },
}
</script>

<style scoped></style>
