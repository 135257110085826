<template>
  <div class="page-maincontent">
    <div class="card-title mb-3">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Localization</h4>
      </div>
    </div>
    <!-- Upload Campaign Modal -->
    <b-modal id="addATS" title="Add ATS">
      <AddATS />
      <template #modal-footer>
        <div class="button-action text-center w-100">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button class="btn-theme" v-on:click="successAlert">
            Submit
          </b-button>
          <b-button variant="danger" @click="$bvModal.hide('addATS')">
            Discard
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-card class="custom-card">
      <MyLanguage />
      <TimeFormat />
    </b-card>
  </div>
</template>

<script>
import MyLanguage from "./MyLanguage.vue"
import TimeFormat from "./TimeFormat.vue"
export default {
  name: "Localization",
  components: {
    MyLanguage,
    TimeFormat,
  },
  methods: {
    successAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to add this Campaign",
        type: "success",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes Add it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal("Success", "You successfully add this Campaign", "success")
        } else {
          this.$swal("Cancelled", "Your Campaign is still intact", "info")
        }
      })
    },
  },
}
</script>

<style scoped></style>
