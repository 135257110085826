<template>
  <b-form-checkbox
    v-model="checked"
    name="check-button"
    switch
    @change="activate"
  >
  </b-form-checkbox>
</template>
<script>
export default {
  data() {
    return {
      checked: false,
    }
  },
  props: ["values", "id", "comment"],
  watch: {
    values: {
      handler(val) {
        this.checked = val
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    activate() {
      const _this = this
      this.$swal({
        title: "Are you sure?",
        text: `You want to ${this.checked ? "Active" : "Inactive"} this ${
          _this.comment
        }`,
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Yes ${
          this.checked ? "Activate" : "Inactivate"
        } it!`,
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          _this.$emit("active", { check: _this.checked, id: _this.id })
        } else {
          _this.checked = !_this.checked
        }
      })
    },
  },
}
</script>
