<template>
  <div class="application-summary">
    <b-card class="mb-4" v-if="candidateDetail.cover_letter">
      <template #header>
        <h6 class="font-weight-bold mb-0">Application Summary</h6>
      </template>
      <b-row>
        <b-col>
          <p>Hi,</p>
          <p class="text-secondary" v-for="s in summary" v-bind:key="s.summary">
            {{ s.summary_text }}
          </p>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="mb-4" v-if="candidateDetail.experience.length > 0">
      <template #header>
        <h6 class="font-weight-bold mb-0">Work Experience</h6>
      </template>
      <b-card
        class="mb-3 border-purple"
        v-for="exp in experience"
        v-bind:key="exp.id"
      >
        <div class="experience position-relative">
          <b-row>
            <b-col md="7">
              <div class="experience-list d-flex flex-column">
                <p class="experience-title font-weight-bold mb-0">
                  {{ exp.exp_title }}
                </p>
                <p class="experience-from text-secondary mb-0">
                  {{ exp.exp_tagline }}
                </p>
              </div>
            </b-col>
            <b-col md="5">
              <div class="experience-duration text-md-right">
                <p class="font-weight-noraml text-secondary mb-0">
                  {{ exp.exp_duration }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <p class="text-secondary">
                {{ exp.exp_desc }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card>

    <b-card class="mb-4" v-if="candidateDetail.education.length > 0">
      <template #header>
        <h6 class="font-weight-bold mb-0">Education</h6>
      </template>
      <b-card
        class="mb-3 border-purple"
        v-for="edu in education"
        v-bind:key="edu.id"
      >
        <div class="education position-relative">
          <b-row>
            <b-col>
              <div class="education-list d-flex flex-column">
                <p class="education-title font-weight-bold mb-0">
                  {{ edu.edu_title }}
                </p>
                <p class="education-from text-secondary mb-0">
                  {{ edu.edu_tagline }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <div class="education-duration">
                <p class="font-weight-noraml text-secondary mb-0">
                  {{ edu.edu_duration }}
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card>

    <b-card class="mb-4" v-if="candidateDetail.skill.length > 0">
      <template #header>
        <b-row>
          <b-col md="7">
            <h6 class="font-weight-bold mb-0">Skills & Experience</h6>
          </b-col>
          <b-col md="5">
            <h6 class="mb-0 text-md-right">
              <span class="font-weight-normal text-secondary mr-2"
                >Total Experience</span
              >
              <span class="font-weight-bold text-primary"
                >{{ totalExp }} Years</span
              >
            </h6>
          </b-col>
        </b-row>
      </template>
      <b-card class="mb-3 border-purple">
        <div class="experiance-tags">
          <span
            v-for="exptags in expSkills"
            v-bind:key="exptags.id"
            class="exp-tag d-inline-block mr-2 mb-2 bg-light py-2 px-3 rounded border fs-8 font-weight-500"
          >
            {{ exptags.expskill }} {{ exptags.exp_dur }}
          </span>
        </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ApplicationSummary",
  props: ["candidateDetail"],
  components: {},

  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      id: this.$route.params.id,
      totalExp: null,
      // Summary
      summary: [
        {
          summary_text:
            "A Senior Software Engineer offering over 8 years of success in various leadership roles in the areas of Web design and development and customer support. I love coding and problem solving. Love working on both server and client side code. I like to get to know my clients closely to facilitate better communication.",
        },
      ],

      // Experience
      experience: [],
      // Education
      education: [],
      //Experience & Skills Tags
      expSkills: [],
    }
  },
  mounted() {
    this.getCandidateDetail()
  },
  methods: {
    getCandidateDetail() {
      this.$axios
        .get(this.base_url + "/candidates/" + this.id, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.experience = this.experience = [
            ...this.experience,
            ...response.data.experience.map((data) => ({
              id: data.id,
              exp_title: data.title,
              exp_tagline:
                data.location == null
                  ? data.company + ""
                  : data.company + " - " + data.location,
              exp_duration:
                data.startDate == null && data.endDate == null
                  ? "null"
                  : data.startDate + " To " + data.endDate,
              exp_desc: data.startDate == null ? "null" : data.description,
            })),
          ]
          this.education = this.education = [
            ...this.education,
            ...response.data.education.map((data) => ({
              id: data.id,
              edu_title: data.degree,
              edu_tagline:
                data.location == null
                  ? data.institution + ""
                  : data.institution + " - " + data.location,
              edu_duration:
                data.startDate == null && data.endDate == null
                  ? "null"
                  : data.startDate + " To " + data.endDate,
            })),
          ]
          this.expSkills = this.expSkills = [
            ...this.expSkills,
            ...response.data.skill.map((data) => ({
              id: data.id,
              expskill: data.title,
              exp_dur:
                data.years == null && data.months == null
                  ? "N/A"
                  : data.years == null && data.months != null
                  ? "0 Years and " + data.months + " months"
                  : data.years != null && data.months == null
                  ? "(" + data.years + " Years and 0 months)"
                  : "(" + data.years + " Years and " + data.months + " months)",
            })),
          ]
          var sumOfYears = response.data.skill.reduce((acc, ele) => {
            return acc + parseInt(ele.years)
          }, 0)
          // var sumOfMonths = response.data.skill.reduce((acc, ele) => {
          //   return acc + (ele.months == null)
          //     ? parseInt(0)
          //     : parseInt(ele.months);
          // }, 0);

          this.totalExp = sumOfYears
        })
    },
  },
}
</script>

<style scoped>
.experience,
.education {
  padding-left: 40px;
}
.experience::before,
.education::before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #6c68a7;
  display: inline-block;
  border-radius: 100px;
  position: absolute;
  left: 0;
  top: 5px;
}
</style>
