<template>
  <div class="create-job">
    <b-card class="custom-card">
      <b-form class="form-horizontal" @submit="onSubmit">
        <b-row>
          <b-col lg="3" xl="2">
            <label> Name: </label>
          </b-col>
          <b-col lg="5" xl="4">
            <b-form-group id="name">
              <b-form-input
                id="subject"
                v-model="form.name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3" xl="2">
            <label> Subject: </label>
          </b-col>
          <b-col lg="5" xl="4">
            <b-form-group id="subject">
              <b-form-input
                id="subject"
                v-model="form.subject"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="3" xl="2">
            <label> From:</label>
          </b-col>
          <b-col lg="5" xl="4">
            <b-form-group id="from_email">
              <b-form-input
                id="from_email"
                v-model="form.from_email"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3" xl="2">
            <label> To:</label>
          </b-col>
          <b-col lg="5" xl="4">
            <b-form-group id="to_email">
              <b-form-input
                type="email"
                id="to_email"
                v-model="form.to_email"
                v-on:keyup="removeValidationMessage"
              ></b-form-input>
              <p class="error" v-if="error.to_email">{{ error.to_email }}</p>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="3" xl="2">
            <label> Template Key: </label>
          </b-col>
          <b-col lg="5" xl="4">
            <b-form-group id="template_key">
              <b-form-input
                id="template_key"
                v-model="form.template_key"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12" xl="12">
            <b-form-group id="html_template">
              <ckeditor
                :height="500"
                :editor="editor"
                v-model="form.html_template"
              ></ckeditor>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <b-button
              type="submit"
              class="btn-theme mr-2"
              @click="onSubmit($event, false)"
              >Update</b-button
            >
            <b-button
              type="submit"
              class="btn-theme mr-2"
              @click="onSubmit($event, true)"
              >Test Email</b-button
            >
            <b-button variant="danger" @click="clearFormData">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

export default {
  name: "CreateEmailtemplateForm",
  components: {},
  data() {
    return {
      editor: ClassicEditor,
      emailTemplateDetail: [],
      id: this.$route.params.id,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      form: {
        name: "",
        subject: "",
        from_email: "",
        template_key: "",
        to_email: null,
        html_template: "",
        is_html: true,
        is_text: false,
        plain_text: "",
      },
      error: {
        to_email: "",
      },
    }
  },
  mounted() {
    this.getEmailTemplateDetail()
  },
  methods: {
    clearFormData() {
      this.getEmailTemplateDetail()
    },
    removeValidationMessage() {
      this.error.to_email = ""
    },

    onSubmit(event, test_email = null) {
      if (test_email == true) {
        if (this.form.to_email == "") {
          this.error.to_email = "This Field is required."
        }
      }
      if (test_email == false) {
        this.error.to_email = ""
      }
      event.preventDefault()
      if (this.error.to_email == "") {
        var created_at = new Date().toLocaleString()
        var payload = {
          name: this.form.name,
          subject: this.form.subject,
          from_email: this.form.from_email,
          template_key: this.form.template_key,
          to_email: this.form.to_email,
          html_template: this.form.html_template,
          is_html: this.form.is_html,
          plain_text: this.form.plain_text,
          created_at: created_at,
        }
        const config = {
          headers: { Authorization: this.token },
        }

        this.$axios
          .put(
            this.base_url + "/email-templates/" + this.id + "/",
            payload,
            config
          )
          .then((response) => {
            if (response.status == 200) {
              this.getEmailTemplateDetail()
            }
          })
          .catch((error) => {
            // if (error.response.data.email) {
            //   this.error.email = error.response.data.email[0];
            // }
            // if (error.response.data.name) {
            //   this.error.name = error.response.data.name[0];
            // }
            // if (error.response.data.logo) {
            //   this.error.logo = error.response.data.logo[0];
            // }
          })
          .finally(() => (this.loading = false))
      }
    },
    getEmailTemplateDetail() {
      this.$axios
        .get(this.base_url + "/email-templates/" + this.id + "/", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.emailTemplateDetail = response.data
          this.form.name = this.emailTemplateDetail.name
          this.form.subject = this.emailTemplateDetail.subject
          this.form.template_key = this.emailTemplateDetail.template_key
          this.form.html_template = this.emailTemplateDetail.html_template
          this.form.to_email = this.emailTemplateDetail.to_email
          this.form.from_email = this.emailTemplateDetail.from_email
        })
    },
  },
}
</script>

<style scoped>
.ats-box,
.manual-box {
  background-color: #f5f5f5;
  padding: 16px 40px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.ats-box.active,
.manual-box.active {
  background-color: #007bff;
  border: 1px solid #007bff;
  color: #fff;
}
.error {
  color: red;
}
select[multiple] {
  height: 150px;
  overflow: auto;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.delete-btn {
  margin-bottom: -20px;
}
</style>
