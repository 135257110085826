<template>
  <div class="page-maincontent">
    <JobsDetails  />
    <ChatBot />
  </div>
</template>

<script>
import JobsDetails from "./Details.vue";
import ChatBot from "./ChatBot";
export default {
  name: "JobsDetail",
  components: {
    JobsDetails,
    ChatBot,
  },
  data() {
    return {
     
     
    };
  },
};
</script>

<style scoped></style>
