<template>
  <div class="passwordsettings">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Account Detail Edit</h4>

        <b-button class="btn-theme" @click="createshow = true">
          <span class="d-flex align-items-center">
            <span class="material-icons-outlined"> edit </span>
            <span>Update Password</span>
          </span>
        </b-button>
      </div>
    </div>
    <b-modal
      id="updatePassword"
      v-model="createshow"
      title="Update Password"
      hide-footer
    >
      <UpdatePassword @changePassword="changePass" />
    </b-modal>
    <b-card class="custom-card">
      <p class="error" v-if="error.message">
        {{ error.message }}
      </p>
      <FormulateForm
        class="mb-3 mb-lg-5 text-left"
        @submit="submitHandler"
        v-model="form"
      >
        <b-row>
          <b-col>
            <!--begin::Image input-->
            <div class="image-input mb-3">
              <!-- <b-form-file plain></b-form-file> -->
              <div
                class="image-input image-input-outline"
                :style="{
                  'background-image': `url(${require('../../assets/images/profile-image.jpg')})`,
                }"
              >
                <!--begin::Preview existing avatar-->
                <div
                  v-if="url == null"
                  class="image-input-wrapper w-125px h-125px"
                  :style="{
                    'background-image': `url(${require('../../assets/images/profile-image.jpg')})`,
                  }"
                ></div>
                <div v-if="url" id="preview" @click="changebaseFormat">
                  <img v-if="url" :src="url" />
                </div>
                <!--end::Preview existing avatar-->
                <!--begin::Inputs-->
                <input ref="fileInput" type="file" @input="changebaseFormat" />
                <!--end::Inputs-->

                <b-link class="change-avatar" title="Change avatar">
                  <span class="material-icons-outlined"> edit </span>
                  <b-form-file plain @change="changebaseFormat"></b-form-file>
                </b-link>

                <!--begin::Remove-->
                <b-link
                  class="remove-avatar"
                  title="Remove avatar"
                  @click="removeImage"
                >
                  <span class="material-icons-outlined"> close </span>
                </b-link>
                <!--end::Remove-->
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <FormulateInput
              type="text"
              class="mb-3"
              name="username"
              validation="required"
              placeholder="Username"
              label="Username"
              validation-name="User Name"
            />
          </b-col>
          <b-col lg="6">
            <FormulateInput
              type="text"
              class="mb-3"
              name="first_name"
              placeholder="First Name"
              label="First Name"
              validation-name="First Name"
            />
          </b-col>
          <b-col lg="6">
            <FormulateInput
              type="text"
              class="mb-3"
              name="last_name"
              placeholder="Last Name"
              label="Last Name"
              validation-name="Last Name"
            />
          </b-col>
          <b-col lg="6">
            <FormulateInput
              type="text"
              class="mb-3"
              name="email"
              placeholder="Email"
              label="Email"
              validation="required"
              validation-name="Email"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <b-form-group>
              <label>Phone Number</label>
              <VuePhoneNumberInput
                v-model="phoneNumber"
                name="phoneNumber"
                @update="onUpdate"
              />
              <p class="error" v-if="error.phoneNumber">
                {{ error.phoneNumber }}
              </p>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button type="submit" class="btn-theme btn-bold text-uppercase"
          >Update</b-button
        >
      </FormulateForm>
    </b-card>
  </div>
</template>

<script>
import UpdatePassword from "./UpdatePassword.vue"
import UpdateRole from "./UpdateRole.vue"
import VuePhoneNumberInput from "vue-phone-number-input"
import "vue-phone-number-input/dist/vue-phone-number-input.css"
export default {
  name: "PasswordSettings",
  components: {
    UpdatePassword,
    UpdateRole,
    VuePhoneNumberInput,
  },
  data() {
    return {
      url: "",
      loader: false,
      phoneNumber: null,
      editAccountShow: false,
      createshow: false,
      phoneIsValid: false,
      form: {
        email: "",
        logo: "",
        username: "",
        first_name: "",
        last_name: "",
      },
      error: {
        message: "",
        phoneNumber: "",
        logo: "",
      },
      accountDetail: [],
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
    }
  },
  mounted() {
    this.getLogedInUserDetail()
  },
  methods: {
    removeImage() {
      this.url = null
    },
    onUpdate(payload) {
      this.phoneIsValid = payload.isValid

      if (this.phoneNumber != null) {
        if (payload.isValid == false) {
          this.error.phoneNumber = "Phone Number is wrong"
        } else {
          this.error.phoneNumber = ""
        }
      }
    },
    changePass() {
      this.createshow = false
    },
    async submitHandler(data) {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      var id = parseData.user
      if (this.form.avatar == "") {
        var payload = {
          email: data.email,
          username: data.username,
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: this.phoneNumber,
        }
      } else {
        var payload = {
          email: data.email,
          username: data.username,
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: this.phoneNumber,
          avatar: this.form.avatar,
        }
      }
      const config = {
        headers: { Authorization: this.token },
      }
      if (this.phoneIsValid == true) {
        this.$axios
          .put(this.base_url + "/users/" + id + "/", payload, config)
          .then((response) => {
            if (response.data.status == false) {
              this.error.email = response.data.message
            }
            if (response.status == 200) {
              this.$swal({
                icon: "success",
                text: "User is now activated successfully 🙂",
                type: "success",
              }).then((result) => {
                if (result.value) {
                  this.editAccountShow = false
                }
              })
            }
            if (response.data.status_code == 400) {
              this.error.message = response.data.message
            }
          })
          .catch((error) => {
            this.error.message = error.response.message
          })
          .finally(() => (this.loading = false))
      }
    },
    successAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to change the password",
        type: "success",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes change it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal(
            "Success",
            "You successfully change this password",
            "success"
          )
        } else {
          this.$swal("Cancelled", "Your password is still intact", "info")
        }
      })
    },
    successAlert2() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to change the role",
        type: "success",
        showCancelButton: true,
        confirmButtonText: "Yes change it!",
        cancelButtonText: "No, Cancel it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal("Success", "You successfully change this role", "success")
        } else {
          this.$swal("Cancelled", "Your role is still intact", "info")
        }
      })
    },
    getLogedInUserDetail() {
      var userDetails = localStorage.getItem("user")
      var parseData = JSON.parse(userDetails)
      // this.accountDetail = parseData;
      var id = parseData.user
      this.$axios
        .get(this.base_url + "/users/" + parseData.user, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.accountDetail = response.data
          this.phoneNumber = response.data.phone_number
          this.form.email = response.data.email
          this.form.first_name = response.data.first_name
          this.form.last_name = response.data.last_name
          this.form.username = response.data.username
          this.url = response.data.avatar
          if (this.phoneNumber != "") {
            this.phoneIsValid = true
          }
        })
    },
    changebaseFormat(e) {
      const file = e.target.files[0]
      this.createBase64FormatImage(file)
    },
    createBase64FormatImage(file) {
      const reader = new FileReader()
      let rawImg
      reader.onload = (e) => {
        this.form.avatar = reader.result
      }

      reader.readAsDataURL(file)
      this.url = URL.createObjectURL(file)
    },
  },
}
</script>

<style scoped>
.image-input {
  position: relative;
  display: inline-block;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input .image-input-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input.image-input-outline .image-input-wrapper {
  border: 3px solid #fff;
  box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%);
  height: 125px !important;
  width: 125px !important;
}
.remove-avatar,
.change-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 5px rgb(0 0 0 / 13%);
  background-color: #fff;
  border-radius: 100px;
  color: #4a4a4a;
  padding: 4px;
  position: absolute;
}
.image-input input {
  position: absolute;
  top: -15px;
  z-index: 1;
  width: 30px;
  height: 30px;
  left: 80%;
  opacity: 0;
  cursor: pointer;
}
.remove-avatar:hover,
.change-avatar:hover {
  text-decoration: none;
}
.remove-avatar .material-icons-outlined,
.change-avatar .material-icons-outlined {
  font-size: 18px;
}
.change-avatar,
.remove-avatar {
  left: 94%;
}
.change-avatar {
  top: 0%;
}
.remove-avatar {
  top: 94%;
}
.w-125px {
  width: 124px;
}
.h-125px {
  height: 124px;
}
.change-avatar input {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  opacity: 0;
}
#preview {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
}
#preview img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
