<template>
  <div class="create-job">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div class="card-title mb-3">
      <div class="d-sm-flex align-items-center justify-content-sm-between">
        <h4 class="mb-sm-0">Import Job from ATS</h4>
      </div>
    </div>
    <b-card class="custom-card">
      <b-form class="form-horizontal" @submit="onSubmit">
        <b-row>
          <b-col lg="6" xl="6">
            <b-form-group id="company" label="Employer">
              <!-- <EmployerVSelect @employerValue="employerValue" /> -->
              <b-form-select
                v-model="company"
                :options="employers"
                @change="getJobs"
                class="mt-3"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" xl="6">
            <b-form-group id="jobs" label="Job">
              <!-- <v-select
                :options="paginated"
                :filterable="false"
                :reduce="(option) => option.value"
                label="label"
                @open="onOpen"
                @close="onClose"
                @search="(query) => (search = query)"
                class="mb-3"
                placeholder="Select Job"
                id="job"
                name="jobId"
                v-model="jobId"
                v-on:input="getJobsDescription"
              >
                <template #list-footer>
                  <li v-show="hasNextPage" ref="load" class="loader"></li>
                </template>
              </v-select> -->
              <b-form-select
                v-model="jobId"
                :options="jobs"
                @change="getJobsDescription"
                class="mt-3"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" xl="12">
            <b-form-group id="job_description" label="Job Description">
              <ckeditor
                :height="1000"
                :editor="editor"
                v-model="form.description"
              ></ckeditor>
            </b-form-group>
          </b-col>
        </b-row>
        <b-card no-body class="mt-3">
          <template #header>
            <h4 class="">Creation of Collection Field Form</h4>
          </template>
          <b-card-body class="creation-from">
            <b-row>
              <b-col lg="12" xl="6">
                <b-row class="mb-3">
                  <b-col lg="6" xl="6">
                    <h6>Basic Information Section</h6>
                  </b-col>
                  <b-col lg="6" xl="6">
                    <h6>Required</h6>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.first_name"
                      class="mb-2"
                    >
                      First Name
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.first_name"
                      class="mb-2 custom-radio"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.last_name"
                      class="mb-2"
                    >
                      Last Name
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.last_name"
                      class="mb-2 custom-radio"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox v-model="form_fields.email" class="mb-2">
                      Email
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.email"
                      class="mb-2 custom-radio"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.contact_number"
                      class="mb-2"
                      @change="activate($event, 'contact_number')"
                    >
                      Contact Number
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <!-- <b-form-radio
                      v-model="required_fields.contact_number"
                      class="mb-2"
                        name="contact_number"
                         :values="required_fields.contact_number === ''"
                      @change="activate($event,'contact_number')"
                    ></b-form-radio> -->
                    <b-form-checkbox
                      v-model="required_fields.contact_number"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'contact_number')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.country"
                      class="mb-2"
                      @change="activate($event, 'country')"
                    >
                      Country
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.country"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'country')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.region"
                      class="mb-2"
                      @change="activate($event, 'region')"
                    >
                      Region
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.region"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'region')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.city"
                      class="mb-2"
                      @change="activate($event, 'city')"
                    >
                      City
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.city"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'city')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.street"
                      class="mb-2"
                      @change="activate($event, 'street')"
                    >
                      Street
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.street"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'street')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.skype"
                      class="mb-2"
                      @change="activate($event, 'skype')"
                    >
                      Skype
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.skype"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'skype')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.linke_din"
                      class="mb-2"
                      @change="activate($event, 'linke_din')"
                    >
                      LinkedIn
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.linke_din"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'linke_din')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.facebook"
                      class="mb-2"
                      @change="activate($event, 'facebook')"
                    >
                      Facebook
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.facebook"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'facebook')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.twitter"
                      class="mb-2"
                      @change="activate($event, 'twitter')"
                    >
                      Twitter
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.twitter"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'twitter')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.website"
                      class="mb-2"
                      @change="activate($event, 'website')"
                    >
                      Website
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.website"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'website')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="12" xl="6">
                <b-row class="mb-3">
                  <b-col lg="6" xl="6">
                    <h6>Education and Work Experience Section</h6>
                  </b-col>
                  <b-col lg="6" xl="6">
                    <h6>Required</h6>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox v-model="form_fields.resume" class="mb-2">
                      Resume
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.resume"
                      class="mb-2 custom-radio"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.cover_letter"
                      class="mb-2"
                      @change="activate($event, 'cover_letter')"
                    >
                      Cover Letter
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.cover_letter"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'cover_letter')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.edu_Institution"
                      class="mb-2"
                      @change="activate($event, 'edu_Institution')"
                    >
                      Education Institution
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.edu_Institution"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'edu_Institution')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.edu_degree"
                      class="mb-2"
                      @change="activate($event, 'edu_degree')"
                    >
                      Education Degree
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.edu_degree"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'edu_degree')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.edu_major"
                      class="mb-2"
                      @change="activate($event, 'edu_major')"
                    >
                      Education Major
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.edu_major"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'edu_major')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.edu_location"
                      class="mb-2"
                      @change="activate($event, 'edu_location')"
                    >
                      Education Location
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.edu_location"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'edu_location')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.edu_startDate"
                      class="mb-2"
                      @change="activate($event, 'edu_startDate')"
                    >
                      Education startDate
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.edu_startDate"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'edu_startDate')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.edu_endDate"
                      class="mb-2"
                      @change="activate($event, 'edu_endDate')"
                    >
                      Education endDate
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.edu_endDate"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'edu_endDate')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.edu_description"
                      class="mb-2"
                      @change="activate($event, 'edu_description')"
                    >
                      Education Description
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.edu_description"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'edu_description')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.work_exp_title"
                      class="mb-2"
                      @change="activate($event, 'work_exp_title')"
                    >
                      Work Experience Title
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.work_exp_title"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'work_exp_title')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.work_exp_company"
                      class="mb-2"
                      @change="activate($event, 'work_exp_company')"
                    >
                      Work Experience Company
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.work_exp_company"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'work_exp_company')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.work_exp_current"
                      unchecked-value="resume"
                      class="mb-2"
                      @change="activate($event, 'work_exp_current')"
                    >
                      Work Experience Current
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.work_exp_current"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'work_exp_current')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.work_exp_startDate"
                      class="mb-2"
                      @change="activate($event, 'work_exp_startDate')"
                    >
                      Work Experience startDate
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.work_exp_startDate"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'work_exp_startDate')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="form_fields.work_exp_endDate"
                      class="mb-2"
                      @change="activate($event, 'work_exp_endDate')"
                    >
                      Work Experience endDate
                    </b-form-checkbox>
                  </b-col>
                  <b-col lg="12" xl="6">
                    <b-form-checkbox
                      v-model="required_fields.work_exp_endDate"
                      class="mb-2 custom-radio"
                      @change="setRequired($event, 'work_exp_endDate')"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-row class="mt-4">
          <b-col>
            <b-button type="submit" class="btn-theme mr-2">Submit</b-button>
            <b-button class="btn-theme-cancel mr-2" onclick="history.back()"> Cancel </b-button>
            <b-button class="btn-theme-clear mr-2" @click="clearFormData"> Clear </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { checkChange } from "@amcharts/amcharts4/.internal/core/utils/Time"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import EmployerVSelect from "../../components/EmployerVSelect.vue"
export default {
  name: "CreateJobForm",
  components: {
    EmployerVSelect,
  },
  data() {
    return {
      loader: false,
      input: null,
      company: null,
      jobId: null,
      editor: ClassicEditor,
      jobs: [],
      observer: null,
      limit: 20,
      page: 1,
      employers: [],
      totalPage: 3,
      search: "",
      countries: ["dsd", "ds"],
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      form: {
        job: null,
        ats: null,
        company: null,
        name: "",
        employement_type: "",
        department: "",
        industry: "",
        experience_level: "",
        description: "",
        experience: "",
        gtm_job: "",
        skills: [],
      },
      form_fields: {
        first_name: true,
        last_name: true,
        email: true,
        contact_number: false,
        country: false,
        region: false,
        city: false,
        street: false,
        skype: false,
        linke_din: false,
        facebook: false,
        twitter: false,
        website: false,
        resume: false,
        cover_letter: false,
        edu_Institution: false,
        edu_degree: false,
        edu_major: false,
        edu_location: false,
        edu_startDate: false,
        edu_endDate: false,
        edu_description: false,
        work_exp_title: false,
        work_exp_company: false,
        work_exp_current: false,
        work_exp_startDate: false,
        work_exp_endDate: false,
      },
      required_fields: {
        first_name: true,
        last_name: true,
        email: true,
        contact_number: false,
        country: false,
        region: false,
        city: false,
        street: false,
        skype: false,
        linke_din: false,
        facebook: false,
        twitter: false,
        website: false,
        resume: false,
        cover_letter: false,
        edu_Institution: false,
        edu_degree: false,
        edu_major: false,
        edu_location: false,
        edu_startDate: false,
        edu_endDate: false,
        edu_description: false,
        work_exp_title: false,
        work_exp_company: false,
        work_exp_current: false,
        work_exp_startDate: false,
        work_exp_endDate: false,
      },
      jobdDesc: "",
    }
  },
  methods: {
    activate(event, keyName) {
      this.form_fields[keyName] = event
    },
    setRequired(event, keyName) {
      this.required_fields[keyName] = event
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    async setPage() {
      this.page = this.page + 1
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 20
        this.page += 1
        await this.getJobs(this.page)
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    getEmployers(page) {
      var employer = this.input

      this.$axios
        .get(this.base_url + "/filter-employers?ats=true", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.employers = response.data.result.map((data) => ({
            value: data.id,
            text: data.name,
          }))
        })

      //  this.$axios
      // .get(this.base_url+"/employers/" + this.id, {
      //   headers: { Authorization: this.token },
      // }).then((response)=>{
      //   this.input.label=response.data.name;
      //   this.input.value=response.data.id;
      // })
    },

    getJobs(page, event = null) {
      this.loader = true
      this.form.description
      if (this.company == null) {
        // this.$axios
        //   .get(this.base_url + "/filter-jobs?employer_id=", {
        //     headers: { Authorization: this.token },
        //   })
        //   .then((response) => {
        //     this.loader = false;
        //     this.jobs = [
        //       ...this.jobs,
        //       ...response.data.result.map((data) => ({
        //         value: data.job,
        //         label: data.title,
        //       })),
        //     ];
        //   });
        this.loader = false
      } else {
        this.$axios
          .get(this.base_url + "/filter-jobs?employer_id=" + this.company, {
            headers: { Authorization: this.token },
          })
          .then((response) => {
            this.loader = false
            this.jobs = response.data.result.map((data) => ({
              value: data.job_id,
              text: data.title,
            }))
          })
          .catch((error) => {
            this.loader = false
            this.$swal("Oops!", error.response.data.message, "error")
            this.jobs = []
          })
      }
    },
    getJobsDescription() {
      if (this.jobId) {
        this.$axios
          .get(
            this.base_url +
              "/ats-job/" +
              this.jobId +
              "/?employer_id=" +
              this.company,
            {
              headers: { Authorization: this.token },
            }
          )
          .then((response) => {
            this.form.description =
              "<p><strong>Department: </strong>" +
              response.data.result.description +
              "</p><p><strong>Employment Type: </strong>" +
              response.data.result.employement_type +
              "</p><p><strong>Experience Level: </strong>" +
              response.data.result.experienceLevel +
              "</p><p><strong>Industry: </strong>" +
              response.data.result.industry +
              "</p><p><strong>Title: </strong>" +
              response.data.result.title +
              "</p><p><strong>Description: </strong>" +
              response.data.result.description
            this.form.department = response.data.result.department
            this.form.employement_type = response.data.result.employement_type
            this.form.experience_level = response.data.result.experienceLevel
            this.form.name = response.data.result.title
            this.form.industry = response.data.result.industry
            this.jobdDesc = response.data.result.description
            this.form.experience = "Null"
            this.form.gtm_job = "Null"
          })
          .catch((error) => {
            this.loader = false
            this.$swal("Oops!", error.response.data.message, "error")
          })
      }
    },
    clearFormData() {
      this.form.ats = null
      this.form.company = null
      this.form.name = ""
      this.form.department = ""
      this.form.industry = ""
      this.form.experience_level = ""
      this.form.employment_type = ""
      this.form.description = ""
      this.form.experience = ""
      this.form.skills = []
      this.form.gtm_job = ""
    },
    onSubmit(event) {
      event.preventDefault()
      if (this.checked == true) {
        var status = "active"
      } else {
        var status = "inactive"
      }
      const arr = this.form.skills
      var name = arr.map(({ name }) => name)
      var skills = name.join()

      var now = new Date()
      //  var formatted_date = new Date().toJSON().slice(0,10).replace(/-/g,'/');
      var form_fields = this.form_fields
      var form_fields_result = []
      Object.keys(form_fields).forEach((key) => {
        const item = form_fields[key]
        if (item === true) {
          form_fields_result.push(key)
        }
      })
      var required_fields = this.required_fields
      var required_fields_result = []
      Object.keys(required_fields).forEach((key) => {
        const item = required_fields[key]
        if (item === true) {
          required_fields_result.push(key)
        }
      })
      var payload = {
        company: this.company,
        name: this.form.name,
        employment_type: this.form.employement_type,
        department: this.form.department,
        industry: this.form.industry,
        experience_level: this.form.experience_level,
        ats: this.form.ats,
        description: this.jobdDesc,
        experience: this.form.experience,
        skill: skills,
        gtm_job: this.form.gtm_job,
        status: status,
        created_at: now,
        form_fields: form_fields_result,
        required_fields: required_fields_result,
      }
      const config = {
        headers: { Authorization: this.token },
      }

      this.$axios
        .post(this.base_url + "/jobs", payload, config)
        .then((response) => {
          if (response.status == 200) {
            this.$router.push("/jobs")
          }
        })
        .catch((error) => {
          // if (error.response.data.email) {
          //   this.error.email = error.response.data.email[0];
          // }
          // if (error.response.data.name) {
          //   this.error.name = error.response.data.name[0];
          // }
          // if (error.response.data.logo) {
          //   this.error.logo = error.response.data.logo[0];
          // }
        })
        .finally(() => (this.loading = false))
      this.getJobs(this.page)
    },
    employerValue(arg) {
      this.form.company = arg.employerId
      this.getJobs(1)
    },
  },
  watch: {
    search() {
      this.page = 1
      this.limit = 20
      this.employers = []
      this.getJobs()
    },
  },
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  created: function () {
    // this.getJobs(this.page);
    // this.getJobsDescription();
    this.getEmployers()
  },
  computed: {
    filtered() {
      return this.jobs
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return true
    },
    formDataLength() {
      return this.formData(this.formData, "1")
    },
  },
}
</script>

<style scoped>
.ats-box,
.manual-box {
  background-color: #f5f5f5;
  padding: 16px 40px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}
.ats-box.active,
.manual-box.active {
  background-color: #007bff;
  border: 1px solid #007bff;
  color: #fff;
}
.error {
  color: red;
}
select[multiple] {
  height: 150px;
  overflow: auto;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.delete-btn {
  margin-bottom: -20px;
}
.spinn-loader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
</style>
