<template>
  <div>
    <FormulateInput
      type="email"
      name="email"
      validation="required|email"
      label="Email"
    />
    <FormulateInput
      :options="modifiedRoleoptions"
      v-model="initialRole"
      type="select"
      label="User Role"
      name="role"
      validation="required"
    />
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: "CreateMultipleUser",
  components: {},
  props: ["isHidden", "formData"],
  data() {
    return {
      initialRole: 4,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      roleoptions: [],
      newRoleOptions: [],
    }
  },
  methods: {
    getRole(event, index) {
      const _this = this
      axios
        .get(this.base_url + "/user-types/" + 2 + "/roles", {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.roleoptions = response.data.results
        })
    },
  },
  mounted() {},
  created: function () {
    this.getRole()
  },
  computed: {
    modifiedRoleoptions() {
      let newRoleOptions = this.roleoptions.map(({ id, title }) => ({
        value: id,
        label: title,
      }))
      return newRoleOptions
    },
  },
}
</script>
<style scoped>
.z-index-8 {
  z-index: 8;
}
</style>
