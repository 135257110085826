<template>
  <div class="create-user">
    <div class="spinn-loader" v-if="loader == true">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <p class="error" v-if="error.message">
      {{ error.message }}
    </p>
    <p class="error" v-if="error.email">
      {{ error.email }}
    </p>
    <FormulateForm class="text-left" @submit="submitHandler" v-model="formData">
      <div>
        <FormulateInput
          type="group"
          name="userData"
          :repeatable="true"
          add-label="+ Add More"
          validation="required"
        >
          <CreateMultipleUser
            :isHidden="isHidden"
            :formData="formData"
            @active="activate"
          />

          <div v-if="isHidden == false" class="formulate-input mt-3">
            <div class="d-block custom-lbel">
              <label>Employer</label>
            </div>
            <v-select
              :options="paginated"
              :filterable="false"
              :reduce="(option) => option.value"
              label="label"
              @open="onOpen"
              @close="onClose"
              @search="(query) => (search = query)"
              class="mb-3"
              placeholder="Select Employer"
              id="emp"
              v-model="input"
            >
              <template #list-footer>
                <li v-show="hasNextPage" ref="load" class="loader"></li>
              </template>
            </v-select>
          </div>
        </FormulateInput>
      </div>
      <b-row class="d-flex justify-content-end px-2">
        <b-button class="btn-theme-clear mr-2" @click="clearFormData"> Clear </b-button>
        <FormulateInput
          type="submit"
          class="btn-theme-custom btn-bold text-uppercase"
          label="Submit"
        />
      </b-row>
    </FormulateForm>
  </div>
</template>

<script>
import axios from "axios"
import CreateMultipleUser from "../AllEmployerUsersList/CreateMultipleUser"

export default {
  name: "CreateUser",
  components: {
    CreateMultipleUser,
  },
  props: ["CreatedEmployerId"],
  data() {
    return {
      loader: false,
      items: [1],
      index: 0,
      id: this.$route.params.id,
      data: [],
      input: {},
      components: [],
      isHidden: false,
      base_url: process.env.VUE_APP_BASE_URL,
      token: "Bearer " + localStorage.getItem("jwt"),
      usertypeoptions: [],
      newUserTypes: [],
      roleoptions: [],
      newRoleOptions: [],
      employers: [],
      formData: {},
      error: {
        message: "",
        email: "",
      },
      observer: null,
      limit: 20,
      page: 1,
      totalPage: 3,
      search: "",
      countries: ["dsd", "ds"],
    }
  },

  methods: {
    activate(arg) {
      this.isHidden = arg.isHidden
    },
    clearFormData() {
      this.formData.userData = ""
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    async setPage() {
      this.page = this.page + 1
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 20
        this.page += 1
        await this.getEmployers(this.page)
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },

    getEmployers(page) {
      var employer = this.input

      this.$axios
        .get(
          this.base_url +
            "/employers/?keyword_search=" +
            this.search +
            "&page=" +
            this.page,
          {
            headers: { Authorization: this.token },
          }
        )
        .then((response) => {
          this.employers = [
            ...this.employers,
            ...response.data.results.map((data) => ({
              value: data.id,
              label: data.name,
            })),
          ]
        })
    },
    async submitHandler(data) {
      this.loader = true
      var employer = this.input
      if (this.input != "") {
        if (this.formData.userData[0].type != "1") {
          var employer = this.input
        } else {
          var employer = 1
        }
      } else {
        var employer = 1
      }

      var payload = this.formData.userData.map((data) => ({
        email: data.email,
        role: data.role,
        type: 2,
        employer: employer,
        partner: null,
      }))

      const config = {
        headers: { Authorization: this.token },
      }
      axios
        .post(this.base_url + "/bulk-invite-user/", payload, config)
        .then((response) => {
          if (response.data.status == 400) {
            this.error.message = response.data.message
            this.error.email = response.email[0]
          }

          this.loader = false
          if (response.status == 201) {
            this.$router.push("/employer-users")
            const _this = this
            _this.$emit("createShowModelHide", { createshow: false })
          }
        })
        .catch((error) => {
          this.loader = false
          this.error.message = error.response.data.message
          this.error.email = error.response.data[0].email
        })
        .finally(() => (this.loading = false))
    },
  },
  watch: {
    search() {
      this.page = 1
      this.limit = 20
      this.employers = []
      this.getEmployers()
    },
  },
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  created: function () {
    this.getEmployers(this.page)
  },
  computed: {
    filtered() {
      return this.employers
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return true
    },
    formDataLength() {
      return this.formData(this.formData, "1")
    },
  },
}
</script>

<style scoped>
.error {
  color: red;
}
select[multiple] {
  height: 150px;
  overflow: auto;
}
.material-icons-outlined.md-20 {
  font-size: 20px;
}
.delete-btn {
  margin-bottom: -20px;
}
.spinn-loader {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
</style>
